import { useState } from "react";
import HoverSidebar from "../components/Hover_Side_Bar";
import { Outlet } from "react-router";
function SidebarLayout() {
    return (
        <>
            <HoverSidebar />
            <Outlet />
        </>

    );
}


export default SidebarLayout