import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);

const DowntimeBarChart = ({ data }) => {
  const chartData = {
    labels: data?.labels || [],
    datasets: [
      {
        label: 'Total Downtime (hours)',
        data: data?.values || [],
        backgroundColor: [
          '#FFEBB2', // light yellow
          '#E59BE9', // light purple
          '#D862BC', // pink
          '#8644A2', // purple
          '#FFEBB2', // light yellow
          '#E59BE9', // light purple
          '#D862BC', // pink
        ],
        borderColor: '#34495e',
        borderWidth: 1,
        borderRadius: { topLeft: 50, topRight: 50, bottomLeft: 0, bottomRight: 0 },
        barThickness: 60,
        borderSkipped: false,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleFont: {
          size: 14,
          weight: 'bold',
          family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        },
        bodyFont: {
          size: 12,
          family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        },
        bodySpacing: 10,
        padding: 10,
        boxPadding: 5,
        cornerRadius: 4,
      },
      datalabels: {
        anchor: 'end',
        align: 'start',
        color: '#34495e',
        font: {
          size: 14,
          weight: 'bold',
          family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#bdc3c7',
          font: {
            size: 14,
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },
    animation: {
      duration: 1500,
      easing: 'easeInOutCubic',
    },
    elements: {
      bar: {
        borderWidth: 2,
        borderRadius: { topLeft: 50, topRight: 50, bottomLeft: 0, bottomRight: 0 },
        barThickness: 60,
      },
    },
    layout: {
      padding: {
        top: 10,
        left: 10,
        right: 10,
      },
    },
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 transform hover:scale-105 transition duration-300 relative">
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-left text-blue-900">Overall Insights</h2>
        <p className="text-xs font-semibold text-left ml-1 text-blue-300">Overview of the key metrics</p>
      </div>
      <div style={{ height: '400px' }}>
        <Bar data={chartData} options={options} />
      </div>
      <div className="absolute bottom-4 left-0 right-0 flex justify-around">
        {chartData.labels.map((label, index) => (
          <div key={index} className="text-center" style={{ width: `${100 / chartData.labels.length}%` }}>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DowntimeBarChart;
