import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const IotBarChartComponent = ({ data }) => {
  const [selectedDataType, setSelectedDataType] = useState('cpu_usage');

  const dataTypes = [
    { value: 'cpu_usage', label: 'CPU Usage' },
    { value: 'server_health', label: 'Server Health' },
    { value: 'alarms', label: 'Alerts' },
    { value: 'upload_speed', label: 'Upload Speed' },
    { value: 'download_speed', label: 'Download Speed' },
  ];

  const handleChange = (event) => {
    setSelectedDataType(event.target.value);
  };

  // Group data by cluster and calculate total value for the selected data type
  const groupedData = data.reduce((acc, item) => {
    const cluster = acc.find((i) => i.name === item.cluster_name);
    if (cluster) {
      cluster[selectedDataType] += item[selectedDataType];
    } else {
      acc.push({
        name: item.cluster_name,
        [selectedDataType]: item[selectedDataType],
      });
    }
    return acc;
  }, []);

  const chartData = groupedData.map((item) => ({
    name: item.name,
    value: item[selectedDataType],
  }));

  return (
    <div className="bg-gray-900 p-8 rounded-lg shadow-2xl">
      <div className="flex justify-between items-center mb-6">
        <h2 className="font-extrabold text-sm text-white">Cluster {dataTypes.find((type) => type.value === selectedDataType)?.label}</h2>
        <select
          value={selectedDataType}
          onChange={handleChange}
          className="bg-gray-700 text-white p-2 rounded-lg border border-gray-600 hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-200"
        >
          {dataTypes.map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={chartData} barCategoryGap="20%" margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#444" />
          <XAxis dataKey="name" stroke="#ffffff" tick={{ fontSize: 14, fill: '#cccccc' }} />
          <YAxis stroke="#ffffff" tick={{ fontSize: 14, fill: '#cccccc' }} />
          <Tooltip 
            contentStyle={{ backgroundColor: '#1f2937', borderColor: '#4b5563', borderRadius: '10px', padding: '10px', color: '#ffffff' }}
            itemStyle={{ color: '#d1d5db' }}
          />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.9} />
              <stop offset="95%" stopColor="#2563eb" stopOpacity={0.9} />
            </linearGradient>
          </defs>
          <Bar dataKey="value" fill="url(#colorUv)" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default IotBarChartComponent;
