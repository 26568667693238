import React, { useState, useEffect } from 'react';

const DigitalClock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-US', { hour12: true });
  };

  return (
 
        <span id="digital-clock" className="text-3xl font-mono">{formatTime(time)}</span>
  
  );
};

export default DigitalClock;
