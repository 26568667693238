import React from 'react';
import { FaNetworkWired } from 'react-icons/fa';

const IotStatusCard = () => {
  const clusters = [
    { name: 'BLDG.1', equipmentCount: 150, switchCount: 50, routerCount: 30, sensorCount: 70 },
    { name: 'BLDG.2', equipmentCount: 120, switchCount: 40, routerCount: 20, sensorCount: 60 },
    { name: 'BLDG.3', equipmentCount: 200, switchCount: 80, routerCount: 50, sensorCount: 70 },
    { name: 'BLDG.4', equipmentCount: 75, switchCount: 25, routerCount: 15, sensorCount: 35 },
  ];

  return (
    <div className="w-full max-w-4xl mx-auto bg-gray-900 rounded-lg shadow-lg p-4">
      <div className="flex items-center mb-2">
        <FaNetworkWired className="text-blue-500 text-xl mr-2" />
        <h2 className="text-lg font-bold text-gray-300">IoT Status Overview</h2>
      </div>
      <div className="overflow-x-auto" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4a5568 #2d3748' }}>
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-gray-800">
            <tr>
              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Cluster Name
              </th>
              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Equipment Count
              </th>
              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Switch Count
              </th>
              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Router Count
              </th>
              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Sensor Count
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-800 divide-y divide-gray-700">
            {clusters.map((cluster, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'}>
                <td className="px-4 py-2 whitespace-nowrap text-xs font-medium text-gray-300">{cluster.name}</td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-400">{cluster.equipmentCount}</td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-400">{cluster.switchCount}</td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-400">{cluster.routerCount}</td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-400">{cluster.sensorCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IotStatusCard;
