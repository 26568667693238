import React from 'react';
import dayjs from 'dayjs';

const RoundsTable = ({ data }) => {
  // Filter data to include only items with a non-null RONDE value
  const roundsData = data.filter(item => item.RONDE !== null);

  return (
    <div className="bg-gray-900  p-6 rounded-lg shadow-lg  transform hover:scale-105 transition-transform duration-300">
      <h3 className="text-md font-bold text-white mb-4">Rounds Details</h3>
      <div className="overflow-x-auto h-96 overflow-y-auto">
        <table className="min-w-full bg-gray-800 rounded-lg">
          <thead>
            <tr>
              <th className="px-2 py-1 border border-gray-600 text-sm text-white">Bailleur</th>
              <th className="px-2 py-1 border border-gray-600 text-sm text-white">Residence</th>
              <th className="px-2 py-1 border border-gray-600 text-sm text-white">Localisation</th>
              <th className="px-2 py-1 border border-gray-600 text-sm text-white">Date</th>
              <th className="px-2 py-1 border border-gray-600 text-sm text-white">Heure Deb</th>
              <th className="px-2 py-1 border border-gray-600 text-sm text-white">Nature</th>
            </tr>
          </thead>
          <tbody>
            {roundsData.map((item, index) => (
              <tr key={index} className="text-center">
                <td className="px-2 py-1 border border-gray-600 text-sm text-white">{item.BAILLEURS ? item.BAILLEURS : 'N/A'}</td>
                <td className="px-2 py-1 border border-gray-600 text-sm text-white">{item.RESIDENCE ? item.RESIDENCE : 'N/A'}</td>
                <td className="px-2 py-1 border border-gray-600 text-sm text-white">{item.LOCALISATION ? item.LOCALISATION : 'N/A'}</td>
                <td className="px-2 py-1 border border-gray-600 text-sm text-white">{item.DATE ? dayjs(item.DATE).format('MM/DD/YYYY') : 'N/A'}</td>
                <td className="px-2 py-1 border border-gray-600 text-sm text-white">{item['Heures\nDEB'] ? item['Heures\nDEB'] : 'N/A'}</td>
                <td className="px-2 py-1 border border-gray-600 text-sm text-white">{item['Unnamed: 20'] ? item['Unnamed: 20'] : 'N/A'}</td>
                </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoundsTable;
