import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faGlobe } from '@fortawesome/free-solid-svg-icons';

const ResidenceAndLocalisationChart = ({ data }) => {
  const [selectedResidence, setSelectedResidence] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Data processing for bar and pie charts
  const residenceData = data.reduce((acc, item) => {
    const residence = item.RESIDENCE;
    const rounds = item.RONDE;
    if (residence && rounds !== null) {
      acc[residence] = acc[residence] || { name: residence, value: 0 };
      acc[residence].value += rounds ? 1 : 0;
    }
    return acc;
  }, {});

  const residenceChartData = Object.values(residenceData);

  const localisationData = data.filter(item => item.RESIDENCE === selectedResidence && item.RONDE !== null)
    .reduce((acc, item) => {
      const localisation = item.LOCALISATION;
      acc[localisation] = acc[localisation] || { name: localisation, value: 0 };
      acc[localisation].value += 1;
      return acc;
    }, {});

  const localisationChartData = Object.values(localisationData);

  const COLORS = ['#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#ffc658'];

  const getOuterRadius = () => {
    if (windowWidth < 400) {
      return 60; // Smaller radius for very small devices
    } else if (windowWidth < 768) {
      return 80; // Medium radius for small devices
    }
    return 100; // Default radius for larger devices
  };

  return (
    <div className="bg-gray-900 p-5 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out">
      <div className="flex flex-wrap justify-around items-center">
        <div className="w-full md:w-1/2 p-2">
          <h2 className="text-center text-xl font-bold text-white mb-3">
            <FontAwesomeIcon icon={faBuilding} className="mr-2" />
            Residence Count of Rounds
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={residenceChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                      onClick={(data) => setSelectedResidence(data.activeLabel)}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" stroke="#ffffff" />
              <YAxis stroke="#ffffff" />
              <Tooltip cursor={{ fill: 'transparent' }} contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }} />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" animationDuration={500}>
                {residenceChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="w-full md:w-1/2 p-2">
          <h2 className="text-center text-xl font-bold text-white mb-3">
            <FontAwesomeIcon icon={faGlobe} className="mr-2" />
            Localisation Count by Selected Residence
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Tooltip contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }} />
              <Pie
                data={localisationChartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={getOuterRadius()} // Dynamically adjust based on screen size
                fill="#82ca9d"
                labelLine={false}
              >
                {localisationChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend layout="vertical" align="right" verticalAlign="middle" wrapperStyle={{ color: '#fff' }} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      {selectedResidence && (
        <div className="text-center text-lg font-semibold mt-4 text-white">
          Selected Residence: {selectedResidence}
        </div>
      )}
    </div>
  );
};

export default ResidenceAndLocalisationChart;
