import React from 'react';
import Chart from 'react-apexcharts';

class TenderTypePieChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [2000, 3000, 1500, 500],
      options: {
        chart: {
          type: 'donut',
          background: 'transparent',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800
          }
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  color: '#fff',
                  fontSize: '16px',
                  fontWeight: 'bold'
                },
                value: {
                  show: true,
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: 'normal',
                  formatter: function(val) { return parseInt(val) }
                },
                total: {
                  show: true,
                  label: 'Total',
                  color: '#fff',
                  formatter: () => 7000,
                  fontSize: '20px',
                  fontWeight: 'bold'
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            gradientToColors: ['#ffa500', '#ff8c00', '#ff7300', '#ff5a00'], // Orange colors
            stops: [0, 100]
          }
        },
        labels: ['Cash', 'Credit Card', 'Debit Card', 'Mobile Payment'],
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          labels: {
            colors: '#fff',
            useSeriesColors: false
          },
          markers: {
            width: 12,
            height: 12,
            radius: 12,
            offsetX: 0,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 10,
            vertical: 5
          },
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          },
          floating: false,
          background: 'transparent',
          borderRadius: 5
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: '100%',
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'center',
              labels: {
                colors: '#fff',
                useSeriesColors: false
              },
              markers: {
                width: 12,
                height: 12,
                radius: 12,
                offsetX: 0,
                offsetY: 0
              },
              itemMargin: {
                horizontal: 10,
                vertical: 0
              },
              onItemClick: {
                toggleDataSeries: true
              },
              onItemHover: {
                highlightDataSeries: true
              },
              floating: false,
              background: 'transparent',
              borderRadius: 5
            }
          }
        }]
      }
    };
  }

  render() {
    return (
      <div className="apex-chart" style={{ width: '100%', height: '100%', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width="100%"
          height="100%"
        />
      </div>
    );
  }
}

export default TenderTypePieChart;
