import React from 'react';
import Chart from 'react-apexcharts';

const TotalSalesLineChart = ({ data, title }) => {
  const options = {
    chart: {
      id: 'order-count-line-chart',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      background: 'transparent'
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'MMM yyyy',
        style: {
          colors: '#00BFFF', // Cool blue color
          fontSize: '12px',
          fontFamily: 'Orbitron, sans-serif'
        }
      },
      axisBorder: {
        show: true,
        color: '#00BFFF',
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: 0
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#00BFFF',
        height: 6,
        offsetX: 0,
        offsetY: 0
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#00BFFF',
          fontSize: '12px',
          fontFamily: 'Orbitron, sans-serif'
        }
      }
    },
    stroke: {
      curve: 'smooth',
      width: 3,
      colors: ['#1E90FF'] // Cool blue color
    },
    fill: {
      type: 'solid',
      colors: ['#1A1A1A']
    },
    markers: {
      size: 5,
      colors: ['#1E90FF'],
      strokeColors: '#00BFFF',
      strokeWidth: 2,
      hover: {
        size: 7
      }
    },
    title: {
      text: title,
      align: 'left',
      style: {
        fontSize: '18px',
        top:2,
        color: '#00BFFF',
        fontFamily: 'Orbitron, sans-serif'
      }
    },
    grid: {
      borderColor: '#444',
      strokeDashArray: 3
    },
    tooltip: {
      theme: 'dark',
      style: {
        fontFamily: 'Orbitron, sans-serif'
      },
      x: {
        format: 'MMM yyyy'
      }
    },
    theme: {
      mode: 'dark'
    }
  };

  const series = [{
    name: 'Orders',
    data: data
  }];

  return (
    <div className="-6 rounded-3xl shadow-2xl bg-gray-900 transform transition-transform hover:scale-105" style={{ boxShadow: '0 0 10px #00BFFF' }}>
      <Chart options={options} series={series} type="line" height="350" />
    </div>
  );
};

export default TotalSalesLineChart;
