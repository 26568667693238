import React, { useState,useEffect} from 'react';
import { motion } from 'framer-motion';
import '../../LoginPage.css'; 
import avatar2 from '../../assets/img/Magnetoo-logo.jpeg';
import { msalInstance } from './authConfig';
import { useNavigate } from 'react-router-dom';

import vidBack from '../../assets/img/loginbackground.mp4'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const loginResponse = await msalInstance.loginPopup({
        scopes: ["openid", "profile", "email"],
      });
  
    
      navigate('/landingpage'); 
    } catch (error) {
      console.error("Login failed:", error);
      setError(error.message);
    }
  };
  

  return (
    <div className="login-page relative bg-gray-950 min-h-screen flex items-center justify-center w-full">
       <video

          src={vidBack}
          playing
          autoPlay
          
          loop
          muted
          className="react-player absolute top-0 left-0 right-0"
          id="hassene"
          style={{ objectFit: 'fill', width:"100%", height:'100vh' }}
        />
      

      <motion.div 
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="login-container bg-gray-800 p-8 rounded-lg shadow-xl relative z-10 mt-[-50px] max-w-md w-full"
      >
        <div className="logo mb-6 text-center">
          <motion.img
            initial={{ opacity: 0, translateX: 200 }}
            animate={{ opacity: 1, translateX: 140 }}
            transition={{ duration: 0.5, ease: [0, 0.71, 0.2, 1.01], delay: 0.2 }}
            src={avatar2} className='w-16 rounded-full ml-2'
            alt="Avatar"
          />
        </div>

        <form onSubmit={handleLogin} className="text-white">
          <div className="mb-4">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full bg-gray-700 p-3 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full bg-gray-700 p-3 rounded"
              required
            />
          </div>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          <button
            type="submit"
            className="w-full bg-blue-600 p-3 rounded hover:bg-blue-700 transition-colors duration-300"
          >
            Login
          </button>
      
        </form>
      </motion.div>
 
    </div>
  );
};

export default Login;
