import ReactApexChart from "react-apexcharts";
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class ColumnChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [
            {
              x: 'BLDG.1',
              y: [0, 4500]
            },
            {
              x: 'BLDG.2',
              y: [0, 4100]
            },
            {
              x: 'BLDG.3',
              y: [0, 7800]
            },
            {
              x: 'BLDG.4',
              y: [0, 4600]
            },
          ]
        }
      ],
      options: {
        backgroundColor: 'transparent',
        chart: {
          height: 350,
          type: 'rangeBar',
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            isDumbbell: true,
            columnWidth: 30,
            dumbbellColors: [['#008FFB', '#00E396','#775DD0', '#FF4560']]
          }
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          position: 'top',
          horizontalAlign: 'left',
          customLegendItems: ['BLDG.1', 'BLDG.2', 'BLDG.3', 'BLDG.4'],
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: ['#008FFB', '#00E396', '#775DD0', '#FF4560'], // Example colors for each legend item
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            type: 'vertical',
            gradientToColors: ['#00E396'],
            inverseColors: true,
            stops: [0, 100]
          }
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          tickPlacement: 'on',
          labels: {
            style: {
              colors: '#fff' // Setting x-axis labels to white
            }
          }
        }
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="rangeBar" height={330} width="100%" />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default ColumnChart;
