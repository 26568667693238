import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import axios from 'axios';
import TotalInterventionsCard from '../../charts/Vacation_charts/Total_intervention';
import TotalRoundsCard from '../../charts/Vacation_charts/Total_round';
import MostActiveLocationCard from '../../charts/Vacation_charts/Mostactive';
import { useNavigate } from 'react-router';
import Navbar from '../../components/Navbar';
import FilterBar from '../../charts/Vacation_charts/FilterBar';
import RoundsLineChart from '../../charts/Vacation_charts/Rounded_bars';
import NanRoundsTable from '../../charts/Vacation_charts/NanTable';
import ResidenceAndLocalisationPieChart from '../../charts/Vacation_charts/Rlpiechart';
import PDFDownloadButton from './PdfGenerator';
import AverageRoundsCard from './AvgResponseTime';
import RoundsTable from '../../charts/Vacation_charts/Rounds_Table';

const isValidValue = (value) => value !== null && value !== undefined && value !== '';

const isBrowser = () => typeof window !== 'undefined';

const WordCloud = isBrowser() ? lazy(() => import('../../charts/Vacation_charts/wordcloud')) : () => null;

const VacationDashboard = () => {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    bailleurs: '',
    residence: '',
    startDate: '',
    endDate: '',
    localisation: ''
  });
  const navigate = useNavigate();
  const [selectedResidenceData, setSelectedResidenceData] = useState([]);
  const [localisationOptions, setLocalisationOptions] = useState([]);

  const chartRefs = {
    roundsBarChart: useRef(null),
    eventTypePieChart: useRef(null),
    interventionsLineChart: useRef(null),
    callsPerHourChart: useRef(null),
    countOfBailleursBarChart: useRef(null),
    residenceBarChart: useRef(null),
  };

  useEffect(() => {
    axios.get('https://magnetoo.fr/synthese')
      .then(response => {
        console.log('Fetched data:', response.data); // Log the data to inspect it
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleFilterChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value
    });

    if (name === 'residence') {
      updateLocalisationOptions(value);
    }
  };

  const updateLocalisationOptions = (residence) => {
    const locations = data
      .filter(item => item.RESIDENCE && item.RESIDENCE.toLowerCase() === residence.toLowerCase())
      .map(item => item.LOCALISATION)
      .filter(isValidValue);

    setLocalisationOptions(Array.from(new Set(locations)));
  };

  const filteredData = data.filter(item => {
    const { bailleurs, residence, startDate, endDate, localisation } = filters;
    const matchesBailleurs = bailleurs ? item.BAILLEURS && item.BAILLEURS.toLowerCase().includes(bailleurs.toLowerCase()) : true;
    const matchesResidence = residence ? item.RESIDENCE && item.RESIDENCE.toLowerCase().includes(residence.toLowerCase()) : true;
    const matchesStartDate = startDate ? new Date(item.DATE) >= new Date(startDate) : true;
    const matchesEndDate = endDate ? new Date(item.DATE) <= new Date(endDate) : true;
    const matchesLocation = localisation ? item.LOCALISATION && item.LOCALISATION.toLowerCase().includes(localisation.toLowerCase()) : true;

    return matchesBailleurs && matchesResidence && matchesStartDate && matchesEndDate && matchesLocation;
  });

  const handleSelectedResidenceChange = (data) => {
    setSelectedResidenceData(data);
  };

  const getBailleursOptions = () => {
    const bailleursSet = new Set(data.map(item => item.BAILLEURS).filter(isValidValue));
    return Array.from(bailleursSet);
  };

  const getResidenceOptions = () => {
    const residenceSet = new Set(data.map(item => item.RESIDENCE).filter(isValidValue));
    return Array.from(residenceSet);
  };

  return (
    <div className="min-h-screen bg-black p-4">
      <Navbar navigate={navigate} />
      <br />
      <FilterBar
        filters={filters}
        onFilterChange={handleFilterChange}
        bailleursOptions={getBailleursOptions()}
        residenceOptions={getResidenceOptions()}
        localisationOptions={localisationOptions}
      />
      <div id="dashboard-content" className="flex flex-col lg:flex-row gap-4">
        <div className="flex flex-col gap-4 w-full lg:w-1/4 ml-9">
          <TotalInterventionsCard data={filteredData} />
          <MostActiveLocationCard data={filteredData} />
          <TotalRoundsCard data={filteredData} />
          <AverageRoundsCard data={filteredData} />
        </div>
        <div className="flex-1 grid grid-cols-1 lg:grid-cols-1 gap-4">
          <div ref={chartRefs.roundsBarChart}><RoundsLineChart data={filteredData} /></div>
        </div>
      </div>
      <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
        <div ref={chartRefs.interventionsLineChart}><RoundsTable data={filteredData} /></div>
        {isBrowser() && (
          <Suspense fallback={<div>Loading...</div>}>
            <div ref={chartRefs.wordCloud}><WordCloud data={filteredData} /></div>
          </Suspense>
        )}
      </div>
      <div className="flex-1 mt-4">
        <div ref={chartRefs.wordCloud} className="w-full"><ResidenceAndLocalisationPieChart data={filteredData} /></div>
      </div>
      <div className="mt-4">
        <PDFDownloadButton filteredData={filteredData} />
      </div>
    </div>
  );
};

export default VacationDashboard;
