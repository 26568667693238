// Alerts.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import MapComponent from "../components/Dubai_map";
import BarCameraChart from "../charts/Cameracountbarchart";
import TestingChart from "../charts/Testingchart";
import Navbar from "../components/Navbar";
import ChatBot from "../components/Chatbot";

const Map = () => {
  const navigate = useNavigate();
  const [camera_data, setcamera_dataData] = useState([]);
  const [geoData, setGeoData] = useState([]);
  useEffect(() => {
    const fetchData = () => {
      fetch("https://magnetoo.fr/camera-api-data")
        .then((response) => response.json())
        .then((camera_data) => setcamera_dataData(camera_data))
        .catch((error) => console.error("Error fetching camera_data:", error));
    };
    fetchData();
    const intervalId = setInterval(fetchData, 1000);

    return () => clearInterval(intervalId);
  }, []);
  console.log(camera_data);

  return (
    <div className="bg-gray-950 min-h-screen flex flex-col lg:flex-row">
      
      <ChatBot />
      {/* Right container - same as Cameras.js */}
      <div className="flex-grow p-1">
        <div>
          <Navbar navigate={navigate} />
        </div>
        <br></br>
        <div className="grid feid-cols-1 lg:grid-cols-2 gap-4 grid-auto-rows">
          <div className="space-y-4">
            <div className="bg-gray-900 rounded-md p-2">
              <h1 className="text-white text-2xl font-light text-left">
                <p>United Arab Emirates</p>
                <p className="text-white text-xs font-light text-left">
                  Dubai region
                </p>
              </h1>
            </div>

            <div className="bg-gray-900 rounded-md p-2 ml-2 flex gap-4 flex mb-6 h-96 w-full">
              <MapComponent />
            </div>
          </div>

          <div className="space-y-4">
            <div className="bg-gray-900 rounded-md p-2">
              <h1 className="text-white text-2xl font-light text-left">
                <p>CCTV - Cameras</p>
                <p className="text-white text-xs font-light text-left">
                  Dubai region
                </p>
              </h1>
            </div>

            <div className="bg-gray-900 rounded-md no-scroll overflow-y-scroll p-2 ml-2 flex gap-4 flex mb-6 h-96 w-full">
              <table className="table-fixed w-full text-white text-right">
                <motion.thead
                  initial={{ opacity: 0, translateY: -100 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  transition={{
                    duration: 0.5,
                    ease: [0, 0.71, 0.2, 1.01],
                    delay: 0.6,
                  }}
                >
                  <tr className="border-b border-white border-opacity-5 bg-slate-800 ">
                    <th className="font-medium p-3 text-center">Orientation</th>
                    <th className="font-medium p-3 text-center">Location</th>
                    <th className="font-medium p-3 text-center">
                      Camera_brand
                    </th>
                    <th className="font-medium p-3 text-center">Shape</th>
                  </tr>
                </motion.thead>
                <tbody>
                  {camera_data.map((line) => {
                    return (
                      <motion.tr
                        key={line.id}
                        className="hover:bg-blue-600 duration-300"
                      >
                        <td
                          className={`p-3 ${
                            line.Status === "Off"
                              ? "bg-rose-400"
                              : "text-teal-500"
                          } font-extralight`}
                        >
                          {line.orientation}
                        </td>
                        <td className="font-extralight p-3 text-center">
                          {line.buildingGroup}
                          {line.Apartment_Number}
                        </td>
                        <td className="font-extralight p-3 text-center">
                          {line.name}
                        </td>
                        <td className="font-extralight p-3 text-center">
                          {line.shape}
                        </td>
                      </motion.tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="space-y-4 flex flex-col">
            <div className="bg-gray-900 rounded-md p-2">
              <h1 className="text-white text-2xl font-light text-left">
                <p>United Arab Emirates</p>
                <p className="text-white text-xs font-light text-left">
                  Dubai region
                </p>
              </h1>
            </div>

            <div className="bg-gray-900 rounded-md h-full">
              <TestingChart />
            </div>
          </div>

          <div className="space-y-4">
            <div className="bg-gray-900 rounded-md p-2">
              <h1 className="text-white text-2xl font-light text-left">
                <p>CCTV - Cameras</p>
                <p className="text-white text-xs font-light text-left">
                  Dubai region
                </p>
              </h1>
            </div>

            <div className="bg-gray-900 rounded-md h-200">
              <BarCameraChart data={geoData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Map;

/*
<div className="flex gap-4">
          <div className="bg-gray-900 rounded-md p-2  w-full">
            <h1 className="text-white text-2xl font-light text-left">
              <p>United Arab Emirates</p>
              <p className="text-white text-xs font-light text-left">
                Dubai region
              </p>
            </h1>
          </div>
          <div className="bg-gray-900 rounded-md p-2 w-full">
            <h1 className="text-white text-2xl font-light text-left">
              <p>CCTV - Cameras</p>
              <p className="text-white text-xs font-light text-left">
                Dubai region
              </p>
            </h1>
          </div>
        </div>
        <div className=" bg-gray-950 rounded-md p-2 ml-2 h-96 flex gap-4 flex  mb-6 w-full">
          <div className=" bg-gray-900 rounded-md p-2 ml-2  flex gap-4 flex  mb-6 h-full w-full">
            <MapComponent />
          </div>
          <div className=" bg-gray-900 rounded-md no-scroll overflow-y-scroll p-2 ml-2  flex gap-4 flex  mb-6 h-full w-full">
            <table className="table-fixed w-full  text-white text-right">
              <motion.thead
                initial={{ opacity: 0, translateY: -100 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  duration: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
                  delay: 0.6,
                }}
              >
                <tr className="border-b border-white border-opacity-5 bg-slate-800 ">
                  <th className="font-medium p-3 text-center">Orientation</th>
                  <th className="font-medium p-3 text-center">Location</th>
                  <th className="font-medium p-3 text-center">
                    {" "}
                    Camera_brand{" "}
                  </th>
                  <th className="font-medium p-3 text-center">Shape </th>
                </tr>
              </motion.thead>
              <tbody>
                {camera_data.map((line) => {
                  return (
                    <motion.tr
                      key={line.id}
                      className="hover:bg-blue-600 duration-300"
                    >
                      <td
                        className={`p-3 ${
                          line.Status === "Off"
                            ? "bg-rose-400"
                            : "text-teal-500"
                        } font-extralight`}
                      >
                        {line.orientation}
                      </td>
                      <td className="font-extralight p-3 text-center">
                        {line.buildingGroup}
                        {line.Apartment_Number}
                      </td>
                      <td className="font-extralight p-3 text-center">
                        {line.name}
                      </td>
                      <td className="font-extralight p-3 text-center">
                        {line.shape}
                      </td>
                    </motion.tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="bg-gray-900 rounded-md p-2  w-full">
            <h1 className="text-white text-2xl font-light text-left">
              <p>United Arab Emirates</p>
              <p className="text-white text-xs font-light text-left">
                Dubai region
              </p>
            </h1>
          </div>

          <div className="bg-gray-900 rounded-md p-2 w-full">
            <h1 className="text-white text-2xl font-light text-left">
              <p>CCTV - Cameras</p>
              <p className="text-white text-xs font-light text-left">
                Dubai region
              </p>
            </h1>
          </div>
        </div>

        <div className="w-full h-600 rounded-xl bg-gray-950 p-5 flex flex-col gap-4">
          <div className="flex gap-4">
            <div className="bg-gray-900 rounded-md  h-200 w-full">
              <TestingChart />
            </div>

            <div className="bg-gray-900 rounded-md h-200 w-full">
              <BarCameraChart data={geoData} />
            </div>
          </div>
        </div>
*/
