import React from 'react';
import ReactApexChart from 'react-apexcharts';

class NetRevenueChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Average Customers',
          type: 'column',
          data: [120, 90, 70, 50, 40, 30, 20, 10],
        },
        {
          name: 'Revenue',
          type: 'line',
          data: [1200, 900, 700, 500, 400, 300, 200, 100],
        },
      ],
      options: {
        chart: {
          type: 'line',
          height: '100%',
          toolbar: {
            show: false,
          },
          events: {
            dataPointSelection: function (event, chartContext, config) {
              console.log(config.dataPointIndex);
            },
          },
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          text: 'Guest Distribution by Category',
          align: 'left',
          style: {
            color: '#fff',
            fontSize: '14px',
            fontWeight: '700',
            fontFamily: 'sans-serif',
            cssClass: 'title-class' // if you want to add a CSS class to the title
          },
          offsetX: 0
        },
        

        
        xaxis: {
          categories: [
            'Bar',
            'Cafe',
            'Restaurant',
            'Pool Bar',
            'Room Service',
            'Lounge',
            'Dining Hall',
            'Terrace',
          ],
          labels: {
            style: {
              fontSize: '9px',
              colors: '#fff',
            },
          },
          axisBorder: {
            show: true,
            color: '#fff',
          },
          axisTicks: {
            show: true,
            color: '#fff',
          },
        },
        yaxis: [
          {
            title: {
              text: 'Average Customers',
              style: {
                color: '#fff',
                fontSize: '12px',
                fontWeight: '500',
                fontFamily: 'sans-serif',
                cssClass: 'title-class' // if you want to add a CSS class to the title
              },
              offsetX: 0,
              textColor: '#fff' // this will override the color property in the style object
            },
            labels: {
              style: {
                colors: ['#fff'],
              },
            },
            axisBorder: {
              show: true,
              color: '#fff',
            },
            axisTicks: {
              show: true,
              color: '#fff',
            },
          },
          {
            opposite: true,
            title: {
              text: 'Revenue',
              style: {
                color: '#fff',
              },
            },
            labels: {
              style: {
                colors: ['#fff'],
              },
            },
            axisBorder: {
              show: true,
              color: '#fff',
            },
            axisTicks: {
              show: true,
              color: '#fff',
            },
          },
        ],
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'center',
          fontSize: '14px',
          fontWeight: 'bold',
          markers: {
            width: 10,
            height: 10,
            radius: 5,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 5,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        colors: ['#3F51B5', '#FFC107'],
        grid: {
          borderColor: '#444',
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
       
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
          
          },
        },
      
        markers: {
          size: 5,
          colors: ['#FFC107'],
          strokeColors: '#fff',
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        responsive: [
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                height: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: '100%',
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    };
  }

  render() {
    return (
      <div className="apex-chart-container" style={{ width: '100%', height: '100%' }}>
        <ReactApexChart options={this.state.options} series={this.state.series} type="line" height="100%" />
      </div>
    );
  }
}

export default NetRevenueChart;
