import React from 'react';
import Chart from 'react-apexcharts';

const RgbDonutChart = ({ data }) => {
  const chartOptions = {
    series: data.series,
    chart: {
      type: 'donut',
      width: '100%',
      height: '100%',
    },
    labels: data.labels,
    colors: ['#F7C137', '#01D26D', '#F13464', '#9158E5'],
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px',
      offsetX: 0,
      offsetY: 0,
      itemMargin: {
        horizontal: 5,
        vertical: 5,
      },
      formatter: function (seriesName, opts) {
        return [seriesName];
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(1) + '%'; // Format to one decimal place
      },
      style: {
        fontSize: '12px', // Smaller font size
        colors: ['#fff'],
      },
      dropShadow: {
        enabled: false,
      },
      background: {
        enabled: true,
        foreColor: '#000',
        padding: 5,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#fff',
        opacity: 0.9,
      },
      offsetY: 0,
      offsetX: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '80%', // Increase the size of the donut chart
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              fontSize: '16px',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0).toFixed(1) + '%';
              },
            },
          },
        },
      },
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 300,
        },
        legend: {
          position: 'bottom',
          itemMargin: {
            horizontal: 5,
            vertical: 5,
          },
        },
      },
    }],
  };

  return (
    <div className="flex justify-center">
      <Chart options={chartOptions} series={chartOptions.series} type="donut" width="400" height="400" />
    </div>
  );
};

export default RgbDonutChart;
