import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faExclamationTriangle,
  faShieldAlt,
  faCheckCircle,
  faChartLine,
 
} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import RgbDonutChart from '../../charts/Alain_charts/DonutRgbAlarmsChart';
import AlarmTrendsChart from '../../charts/Alain_charts/AlarmsLineChart';
import AlarmCardSlider from '../../charts/Alain_charts/AlarmcardSlider';

const monthlyalerts = [
  { month: 'Jan', activeAlarms: 40, resolvedAlarms: 24, pendingAlarms: 20, acknowledgedAlarms: 18 },
  { month: 'Feb', activeAlarms: 35, resolvedAlarms: 28, pendingAlarms: 18, acknowledgedAlarms: 20 },
  { month: 'Mar', activeAlarms: 50, resolvedAlarms: 32, pendingAlarms: 25, acknowledgedAlarms: 22 },
  { month: 'Apr', activeAlarms: 55, resolvedAlarms: 38, pendingAlarms: 30, acknowledgedAlarms: 24 },
  { month: 'May', activeAlarms: 45, resolvedAlarms: 40, pendingAlarms: 28, acknowledgedAlarms: 26 },
  { month: 'Jun', activeAlarms: 60, resolvedAlarms: 45, pendingAlarms: 35, acknowledgedAlarms: 30 },
  { month: 'Jul', activeAlarms: 65, resolvedAlarms: 50, pendingAlarms: 40, acknowledgedAlarms: 32 },
  { month: 'Aug', activeAlarms: 70, resolvedAlarms: 55, pendingAlarms: 45, acknowledgedAlarms: 34 },
  { month: 'Sep', activeAlarms: 75, resolvedAlarms: 60, pendingAlarms: 50, acknowledgedAlarms: 36 },
  { month: 'Oct', activeAlarms: 80, resolvedAlarms: 65, pendingAlarms: 55, acknowledgedAlarms: 38 },
  { month: 'Nov', activeAlarms: 85, resolvedAlarms: 70, pendingAlarms: 60, acknowledgedAlarms: 40 },
  { month: 'Dec', activeAlarms: 90, resolvedAlarms: 75, pendingAlarms: 65, acknowledgedAlarms: 42 }
];

const alarmData = {
  series: [450, 120, 70, 200], // Example percentages for each category
  labels: ['Active Alarms', 'Resolved Alarms', 'Pending Alarms', 'Acknowledged Alarms'],
};
const alarms = [
  { type: 'Unauthorized Access', severity: 'High', comment: 'Unauthorized Access detected at front gate', camera: 'Camera 1', site: 'Main Entrance', date: '2024-07-17', time: '15:30' },
  { type: 'Fire Alarm', severity: 'Critical', comment: 'Fire Alarm: Smoke detected in server room', camera: 'Camera 2', site: 'Server Room', date: '2024-07-17', time: '14:00' },
  { type: 'System Error', severity: 'Medium', comment: 'System Error: Unexpected shutdown', camera: 'Camera 3', site: 'Data Center', date: '2024-07-17', time: '13:45' },
  { type: 'Power Failure', severity: 'High', comment: 'Power Failure: Power outage in building', camera: 'Camera 4', site: 'Main Building', date: '2024-07-17', time: '12:30' },
  { type: 'Unauthorized Access', severity: 'High', comment: 'Unauthorized Access: Attempted entry through window', camera: 'Camera 5', site: 'Side Entrance', date: '2024-07-17', time: '11:20' },
  { type: 'Fire Alarm', severity: 'Critical', comment: 'Fire Alarm: Heat detected in kitchen', camera: 'Camera 6', site: 'Cafeteria', date: '2024-07-17', time: '10:15' },
  { type: 'System Error', severity: 'Medium', comment: 'System Error: Camera malfunction', camera: 'Camera 7', site: 'Parking Lot', date: '2024-07-17', time: '09:00' },
  { type: 'Power Failure', severity: 'High', comment: 'Power Failure: Generator activation', camera: 'Camera 8', site: 'Backup Generator Room', date: '2024-07-17', time: '08:30' },
  { type: 'Unauthorized Access', severity: 'Low', comment: 'Unauthorized Access: Unsuccessful login attempt', camera: 'Camera 9', site: 'Office Building', date: '2024-07-17', time: '07:15' },
  { type: 'Fire Alarm', severity: 'Critical', comment: 'Fire Alarm: Smoke detected in lobby', camera: 'Camera 10', site: 'Lobby', date: '2024-07-17', time: '06:45' },
  { type: 'System Error', severity: 'Low', comment: 'System Error: Minor glitch in monitoring system', camera: 'Camera 11', site: 'Security Room', date: '2024-07-17', time: '05:30' },
  { type: 'Power Failure', severity: 'Medium', comment: 'Power Failure: Short circuit in main panel', camera: 'Camera 12', site: 'Control Room', date: '2024-07-17', time: '04:00' },
];

const Applications = () => {
  const [selectedAlarm, setSelectedAlarm] = useState(null);



 
  return (
    <div className="min-h-screen p-8 bg-gray-50 ml-64 mr-4">
      <h2 className='text-left mb-2'>Alarms</h2>
      {/* Summary Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mb-8">
  {/* Active Alarms */}
  <div className="rounded-lg shadow-md flex items-center justify-between p-3 transform hover:scale-105 transition duration-300 hover:shadow-lg bg-white h-24">
    <div className="text-red-600 text-2xl p-2 bg-red-300 rounded-full">
      <FontAwesomeIcon icon={faBell} />
    </div>
    <div className="flex-1 ml-4">
      <h2 className="text-sm font-semibold">Active Alarms</h2>
      <p className="text-lg font-bold">450</p>
    </div>
  </div>

  {/* Resolved Alarms */}
  <div className="rounded-lg shadow-md flex items-center justify-between p-3 transform hover:scale-105 transition duration-300 hover:shadow-lg bg-white h-24">
    <div className="text-green-600 text-2xl p-2 bg-green-300 rounded-full">
      <FontAwesomeIcon icon={faCheckCircle} />
    </div>
    <div className="flex-1 ml-4">
      <h2 className="text-sm font-semibold">Resolved Alarms</h2>
      <p className="text-lg font-bold">120</p>
    </div>
  </div>

  {/* Pending Alarms */}
  <div className="rounded-lg shadow-md flex items-center justify-between p-3 transform hover:scale-105 transition duration-300 hover:shadow-lg bg-white h-24">
    <div className="text-orange-600 text-2xl p-2 bg-orange-300 rounded-full">
      <FontAwesomeIcon icon={faExclamationTriangle} />
    </div>
    <div className="flex-1 ml-4">
      <h2 className="text-sm font-semibold">Pending Alarms</h2>
      <p className="text-lg font-bold">70</p>
    </div>
  </div>

  {/* Acknowledged Alarms */}
  <div className="rounded-lg shadow-md flex items-center justify-between p-3 transform hover:scale-105 transition duration-300 hover:shadow-lg bg-white h-24">
    <div className="text-blue-600 text-2xl p-2 bg-blue-300 rounded-full">
      <FontAwesomeIcon icon={faShieldAlt} />
    </div>
    <div className="flex-1 ml-4">
      <h2 className="text-sm font-semibold">Acknowledged Alarms</h2>
      <p className="text-lg font-bold">200</p>
    </div>
  </div>

  {/* Total Alarms */}
  <div className="rounded-lg shadow-md flex items-center justify-between p-3 transform hover:scale-105 transition duration-300 hover:shadow-lg bg-white h-24">
    <div className="text-purple-600 text-2xl p-2 bg-purple-300 rounded-full">
      <FontAwesomeIcon icon={faChartLine} />
    </div>
    <div className="flex-1 ml-4">
      <h2 className="text-sm font-semibold">Total Alarms</h2>
      <p className="text-lg font-bold">840</p>
    </div>
  </div>
</div>


      {/* Status Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8">
        {/* Alarm Status */}
        <div className="rounded-lg shadow-md p-4 transform hover:scale-105 transition duration-300 hover:shadow-lg bg-white lg:col-span-1">
          <div className="flex flex-row items-center mb-2">
            <FontAwesomeIcon icon={faBell} className="text-red-600 text-2xl mr-2" />
            <h2 className="text-lg font-semibold">Alarm Status</h2>
          </div>
          <RgbDonutChart data={alarmData} />
        </div>

        {/* Recent Alarms */}
        <div className="bg-white rounded-lg shadow-md p-4 transform hover:scale-105 transition duration-300 hover:shadow-lg lg:col-span-2">
          <AlarmTrendsChart data={monthlyalerts} />
        </div>
      </div>

      {/* Additional Insights */}
      <h2 className='text-left'>Recent Alarms</h2>
      <AlarmCardSlider alarms={alarms} />
    </div>
  );
};

export default Applications;
