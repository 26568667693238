import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip bg-white p-2 border border-gray-200 rounded shadow-lg">
        <p className="label font-semibold">{`${label}`}</p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const AlarmTrendsChart = ({ data }) => {
  return (
    <div className="chart-container" style={{ width: '100%', height: '400px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#e0e0e0" />
          <XAxis dataKey="month" tick={{ fill: '#666', fontSize: 12 }} />
          <YAxis tick={{ fill: '#666', fontSize: 12 }} axisLine={false} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <Legend 
            verticalAlign="top" 
            align="right" 
            height={36} 
            iconType="circle" 
            iconSize={8} 
            wrapperStyle={{ fontSize: '12px' }} 
          />
          <Bar dataKey="activeAlarms" fill="#8884d8" radius={[10, 10, 0, 0]} />
          <Bar dataKey="resolvedAlarms" fill="#82ca9d" radius={[10, 10, 0, 0]} />
          <Bar dataKey="pendingAlarms" fill="#ffc658" radius={[10, 10, 0, 0]} />
          <Bar dataKey="acknowledgedAlarms" fill="#ff7300" radius={[10, 10, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AlarmTrendsChart;
