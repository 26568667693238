import React from 'react';

const MostActiveLocationCard = ({ data }) => {
  const locationCounts = data.reduce((acc, item) => {
    const location = item.LOCALISATION;
    if (location) {
      acc[location] = (acc[location] || 0) + 1;
    }
    return acc;
  }, {});

  const mostActiveLocation = Object.keys(locationCounts).reduce((a, b) => locationCounts[a] > locationCounts[b] ? a : b, '');
  const count = locationCounts[mostActiveLocation] || 'N/A';

  return (
    <div className="bg-gray-900 p-4 rounded-lg shadow-lg flex items-center justify-between transform hover:scale-105 transition-transform duration-300 h-28 w-85">
      <div className="text-white animate-bounce">
        <i className="fas fa-map-marker-alt text-3xl"></i>
      </div>
      <div className="text-center flex-1">
        <h3 className="text-lg font-bold text-white mb-1 text-center">Most Active Location</h3>
        <p className="text-3xl font-extrabold text-yellow-300 text-center">{mostActiveLocation || 'N/A'}</p>
        <p className="text-sm font-bold text-white">Count: {count}</p>
      </div>
    </div>
  );
};

export default MostActiveLocationCard;
