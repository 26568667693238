import React from 'react';
import Chart from 'react-apexcharts';

const TotalSalesDonutChart = ({ data, title }) => {
  const options = {
    chart: {
      id: 'order-count-donut-chart',
      type: 'donut',
      background: 'transparent',
      foreColor: '#FFFFFF',
    },
    labels: data.map(item => item.name),
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#FFFFFF'],
        fontSize: '12px',
        fontFamily: 'Orbitron, sans-serif',
        fontWeight: 'normal',
      },
      formatter: function (val, opts) {
        return `${val.toFixed(1)}%`;
      }
    },
    legend: {
      show: true,
      labels: {
        colors: ['#FFFFFF']
      },
      fontSize: '12px',
      fontFamily: 'Orbitron, sans-serif',
      position: 'bottom'
    },
    stroke: {
      width: 2,
      colors: ['#1A1A1A']
    },
    colors: ['#1E90FF', '#00CED1', '#4682B4', '#87CEEB', '#5F9EA0', '#6495ED', '#00BFFF'],
    title: {
      text: title,
      align: 'left',
      style: {
        fontSize: '24px',
        color: '#00BFFF',
        fontFamily: 'Orbitron, sans-serif',
      }
    },
    theme: {
      mode: 'dark'
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          background: 'transparent'
        }
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 280
          },
          title: {
            style: {
              fontSize: '18px'
            }
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };

  const series = data.map(item => item.value);

  return (
    <div className="p-6 rounded-3xl shadow-2xl bg-gray-900 transform transition-transform hover:scale-105" style={{ boxShadow: '0 0 10px #00BFFF' }}>
      <Chart options={options} series={series} type="donut" height="350" />
    </div>
  );
};

export default TotalSalesDonutChart;
