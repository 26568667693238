import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const CctvInfoPieChart = () => {
  const [cameraStatus, setCameraStatus] = useState([
    { name: 'Online', value: 15 },
    { name: 'Offline', value: 3 },
    { name: 'Maintenance', value: 2 },
  ]);

  const options = {
    chart: {
      type: 'donut',
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800
      },
      width: '100%',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    colors: ['#2E93fA', '#66DA26', '#00bcd4'],
    labels: cameraStatus.map(item => item.name),
    plotOptions: {
      pie: {
        donut: {
          size: '80%', // Set the size of the hole in the center
          labels: {
            show: false,
          }
        }
      }
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return val + " cameras"
        }
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          position: 'bottom',
        }
      }
    }]
  };

  return (
    <div className="cctv-info" style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="chart-wrap" style={{ width: '80%' }}>
        <ReactApexChart options={options} series={cameraStatus.map(item => item.value)} type="donut" />
      </div>
    </div>
  );
};

export default CctvInfoPieChart;
