import React from 'react';
import { FaTools, FaPlug, FaServer } from 'react-icons/fa';

const ServerDetails = () => {
  const servers = [
    {
      name: 'BLDG.1',
      lastMaintenance: '2024-07-15',
      lastDisconnection: '2024-07-20',
    },
    {
      name: 'BLDG.2',
      lastMaintenance: '2024-07-10',
      lastDisconnection: '2024-07-18',
    },
    {
      name: 'BLDG.3',
      lastMaintenance: '2024-07-12',
      lastDisconnection: '2024-07-19',
    },
    {
      name: 'BLDG.4',
      lastMaintenance: '2024-07-08',
      lastDisconnection: '2024-07-17',
    },
  ];

  return (
    <div className="w-full max-w-4xl mx-auto bg-gray-900 rounded-lg shadow-lg p-4">
      <div className="flex items-center mb-2">
        <FaServer className="text-blue-500 text-xl mr-2" />
        <h2 className="text-lg font-bold text-gray-300">Server Details</h2>
      </div>
      <div className="overflow-x-auto" style={{ scrollbarWidth: 'thin', scrollbarColor: '#4a5568 #2d3748' }}>
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-gray-800">
            <tr>
              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Server Name
              </th>
              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Last Disconnection
              </th>
              <th scope="col" className="px-4 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Last Maintenance
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-800 divide-y divide-gray-700">
            {servers.map((server, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'}>
                <td className="px-4 py-2 whitespace-nowrap text-xs font-medium text-gray-300">{server.name}</td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-400">{server.lastDisconnection}</td>
                <td className="px-4 py-2 whitespace-nowrap text-xs text-gray-400">{server.lastMaintenance}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServerDetails;
