import React from 'react';

const FilterBar = ({ filters, onFilterChange, bailleursOptions, residenceOptions, localisationOptions }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onFilterChange(name, value);
  };

  return (
    <div className="p-4 mb-6 grid grid-cols-1 md:grid-cols-4 gap-4">
      <div className="w-full relative bg-gray-900 p-3 rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-gray-700 p-1 rounded-full shadow-md">
          <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m-6-3v1m0-5h.01"></path>
          </svg>
        </div>
        <label className="block text-white font-semibold mb-1 text-sm">Bailleurs:</label>
        <div className="relative">
          <select
            name="bailleurs"
            value={filters.bailleurs}
            onChange={handleInputChange}
            className="appearance-none w-full p-2 rounded-lg bg-gray-700 text-white text-sm border-2 border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="">All</option>
            {bailleursOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
            <svg className="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7 10l5 5 5-5H7z"/></svg>
          </div>
        </div>
      </div>

      <div className="w-full relative bg-gray-900 p-3 rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-gray-700 p-1 rounded-full shadow-md">
          <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 9l3-3 4 4m0 0l3-3m-7 7v4m0 0H6m6 0h6"></path>
          </svg>
        </div>
        <label className="block text-white font-semibold mb-1 text-sm">Residence:</label>
        <div className="relative">
          <select
            name="residence"
            value={filters.residence}
            onChange={handleInputChange}
            className="appearance-none w-full p-2 rounded-lg bg-gray-700 text-white text-sm border-2 border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="">All</option>
            {residenceOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
            <svg className="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7 10l5 5 5-5H7z"/></svg>
          </div>
        </div>
      </div>

      <div className="w-full relative bg-gray-900 p-3 rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-gray-700 p-1 rounded-full shadow-md">
          <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8h2a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V10a2 2 0 012-2h2M12 1v4m-4 4h8"></path>
          </svg>
        </div>
        <label className="block text-white font-semibold mb-1 text-sm">Date Range:</label>
        <div className="flex space-x-2">
          <input
            type="date"
            name="startDate"
            value={filters.startDate}
            onChange={handleInputChange}
            className="w-1/2 p-2 rounded-lg bg-gray-700 text-white text-sm border-2 border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <input
            type="date"
            name="endDate"
            value={filters.endDate}
            onChange={handleInputChange}
            className="w-1/2 p-2 rounded-lg bg-gray-700 text-white text-sm border-2 border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>

      <div className="w-full relative bg-gray-900 p-3 rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-gray-700 p-1 rounded-full shadow-md">
          <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M3 14h18"></path>
          </svg>
        </div>
        <label className="block text-white font-semibold mb-1 text-sm">Location:</label>
        <div className="relative">
          <select
            name="localisation"
            value={filters.localisation}
            onChange={handleInputChange}
            className="appearance-none w-full p-2 rounded-lg bg-gray-700 text-white text-sm border-2 border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="">All</option>
            {localisationOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
            <svg className="fill-current h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7 10l5 5 5-5H7z"/></svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
