import React from 'react';
import Chart from 'react-apexcharts';

class Havacchart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: 'basic-bar',
                    toolbar: {
                        show: false // Hides the chart's toolbar
                    }
                },
                xaxis: {
                    type: 'datetime',
                    categories: this.props.data.map(item => item.date),
                },
                legend: {
                    show: false // Hide the legend
                },
                responsive: [
                    {
                        breakpoint: 768,
                        options: {
                            chart: {
                                width: '100%', // Makes the chart 100% width at this breakpoint
                            },
                        },
                    },
                ],
                height: 450, // Set the height of the chart
            },
            series: [
                {
                    name: 'Temperature (°C)',
                    data: this.props.data.map(item => item.temperature),
                },
                {
                    name: 'Humidity (%)',
                    data: this.props.data.map(item => item.humidity),
                },
                {
                    name: 'Energy Consumption (kWh)',
                    data: this.props.data.map(item => item.energyConsumption),
                }
            ],
        };
    }

    render() {
        return (
            <div id="chart">
                <Chart options={this.state.options} series={this.state.series} type="line" height={'100%'} />
            </div>
        );
    }
}

export default Havacchart;
