import React, { useState, useEffect } from 'react';
import { FaChartLine, FaMicrochip, FaServer, FaUpload, FaDownload, FaDesktop, FaLink, FaExclamationTriangle, FaHdd } from 'react-icons/fa'; // Assuming you are using react-icons
import PerformanceCard from '../charts/Iot_charts/Performance';
import SummaryCard from '../charts/Iot_charts/Summary';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router';
import IotBarChartComponent from '../charts/Iot_charts/IotBarchart';
import IoTStatusCard from '../charts/Iot_charts/IotStatusCards';
import ServerDetails from '../charts/Iot_charts/ServerDetails';
import { DateRangePicker } from 'react-date-range'; // Import DateRangePicker
import 'react-date-range/dist/styles.css'; // Import the styles
import 'react-date-range/dist/theme/default.css';
import Draggable from 'react-draggable'; // Import Draggable for movable component

const MainV2 = () => {
  const [data, setData] = useState([]);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [isRangeSelected, setIsRangeSelected] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false); // State to toggle DatePicker visibility
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    if (!isRangeSelected) {
      const interval = setInterval(fetchData, 5000);
      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [isRangeSelected, range]);

  const fetchData = () => {
    const startDate = range[0].startDate.toISOString();
    const endDate = range[0].endDate.toISOString();
    fetch(`https://magnetoo.fr/data?start=${startDate}&end=${endDate}`)
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched data:', data);
        setData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  const handleSelect = (ranges) => {
    setRange([ranges.selection]);
    setIsRangeSelected(true); // Stop refreshing when range is selected
  };

  const handleRealTimeToggle = () => {
    setIsRangeSelected(false);
    fetchData(); // Fetch data immediately when toggling back to real-time
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker); // Toggle the visibility of DatePicker
  };

  return (
    <div className="bg-gray-800 w-full h-full">
      <div className="w-full max-w-9xl p-4">
        <Navbar navigate={navigate} />
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Summary Card */}
          <div className="bg-gray-600 p-4 rounded-lg shadow flex flex-col h-full">
            <SummaryCard data={data} />
          </div>

          {/* Performance Card */}
          <div className="bg-gray-600 p-4 rounded-lg shadow flex flex-col h-full md:row-span-2">
            <PerformanceCard data={data} />
            <div className="mt-4 bg-gray-700 p-4 rounded-lg shadow-sm relative">
              <div className="flex space-x-2">
                <button
                  onClick={toggleDatePicker}
                  className="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-indigo-600 hover:to-blue-500 text-white font-semibold text-xs py-1 px-2 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1"
                >
                  {showDatePicker ? "Hide Date Picker" : "Show Date Picker"}
                </button>
                <button
                  onClick={handleRealTimeToggle}
                  className="bg-gradient-to-r from-green-500 to-teal-600 hover:from-teal-600 hover:to-green-500 text-white font-semibold text-xs py-1 px-2 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1"
                >
                  Real-Time Data
                </button>
              </div>
              {showDatePicker && (
                <Draggable>
                  <div className="absolute z-50 top-0 left-0 mt-2 bg-gray-800 p-2 rounded-lg shadow-lg">
                    <div className="flex justify-end">
                      <button
                        onClick={toggleDatePicker}
                        className="text-white text-xl font-bold"
                      >
                        &times;
                      </button>
                    </div>
                    <DateRangePicker
                      ranges={range}
                      onChange={handleSelect}
                      className="text-xs"
                      rangeColors={["#4CAF50"]}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      direction="horizontal"
                      color="#1f2937"
                      styles={{
                        dateRangePickerWrapper: {
                          backgroundColor: '#1f2937',
                          color: '#fff',
                          borderRadius: '0.5rem',
                          maxWidth: '100%',
                        },
                      }}
                    />
                  </div>
                </Draggable>
              )}
            </div>
          </div>

          {/* System Monitoring Card */}
          <div className="bg-gray-600 p-4 rounded-lg shadow h-full">
            <div className="bg-gray-800 p-4 rounded-lg shadow-lg mt-2">
              <div className="flex items-center mb-3">
                <FaDesktop className="text-blue-400 text-2xl mr-4" />
                <h2 className="font-bold text-lg text-white">System Monitoring</h2>
              </div>
              <div className="text-gray-300 text-sm mb-3">
                Monitor CPU usage, server health, and network performance in real-time.
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="bg-gray-700 p-3 rounded-lg shadow-sm text-center">
                  <div className="flex items-center mb-2">
                    <FaMicrochip className="text-blue-400 text-xl mr-2" />
                    <p className="text-blue-400 font-bold text-sm">CPU Usage</p>
                  </div>
                  <h3 className={`text-3xl ${data[0]?.cpu_usage > 50 ? "text-green-400" : "text-red-400"}`}>
                    {!data.length ? "N/A" : `${data[0].cpu_usage}%`}
                  </h3>
                  <p className="text-gray-400 text-xs">Last 5 Seconds</p>
                </div>
                <div className="bg-gray-700 p-3 rounded-lg shadow-sm text-center">
                  <div className="flex items-center mb-2">
                    <FaServer className="text-blue-400 text-xl mr-2" />
                    <p className="text-blue-400 font-bold text-sm">Server Health</p>
                  </div>
                  <h3 className={`text-3xl ${!data.length ? "text-white" : data[0]?.server_health > 50 ? "text-green-400" : "text-red-400"}`}>
                    {!data.length ? "N/A" : `${data[0].server_health}%`}
                  </h3>
                  <p className="text-gray-400 text-xs">Last 5 Seconds</p>
                </div>
                <div className="bg-gray-700 p-3 rounded-lg shadow-sm text-center">
                  <div className="flex items-center mb-2">
                    <FaUpload className="text-blue-400 text-xl mr-2" />
                    <p className="text-blue-400 font-bold text-sm">Upload Speed</p>
                  </div>
                  <h3 className={`text-2xl ${!data.length ? "text-white" : data[0]?.upload_speed > 80 ? "text-rose-300" : data[0]?.upload_speed > 50 ? "text-amber-400" : "text-green-400"}`}>
                    {!data.length ? "N/A" : `${data[0].upload_speed} Mbps`}
                  </h3>
                  <p className="text-gray-400 text-xs">Last 5 Seconds</p>
                </div>
                <div className="bg-gray-700 p-3 rounded-lg shadow-sm text-center">
                  <div className="flex items-center mb-2">
                    <FaDownload className="text-blue-400 text-xl mr-2" />
                    <p className="text-blue-400 font-bold text-sm">Download Speed</p>
                  </div>
                  <h3 className={`text-2xl ${!data.length ? "text-white" : data[0]?.download_speed > 80 ? "text-rose-300" : data[0]?.download_speed > 50 ? "text-amber-400" : "text-green-400"}`}>
                    {!data.length ? "N/A" : `${data[0].download_speed} Mbps`}
                  </h3>
                  <p className="text-gray-400 text-xs">Last 5 Seconds</p>
                </div>
              </div>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg shadow-lg mt-4">
              <IotBarChartComponent data={data} />
            </div>
          </div>

          {/* Test Card */}
          <div className="bg-gray-600 p-4 rounded-lg shadow h-full">
            <ServerDetails />
          </div>

          {/* Journey Metrics Card */}
          <div className="bg-gray-600 p-4 rounded-lg shadow flex flex-col">
            <IoTStatusCard />
          </div>

          {/* Leaderboard Card */}
        </div>
      </div>
    </div>
  );
};

export default MainV2;
