import React, { useState, useCallback, useMemo } from 'react';
import ApexCharts from 'react-apexcharts';

const TimelineAreaChart = ({ data }) => {
    const [selectedDataKey, setSelectedDataKey] = useState('wait_time');

    const handleDataKeyChange = useCallback((key) => {
        setSelectedDataKey(key);
    }, []);

    const dataKeys = [
        { value: 'wait_time', label: 'Wait Time' },
        { value: 'handling_time', label: 'Handling Time' },
        { value: 'intervention_time', label: 'Intervention Time' }
    ];

    const last24HoursData = useMemo(() => {
        const now = new Date();
        const last24Hours = new Date(now.getTime() - (24 * 60 * 60 * 1000)); // 24 hours ago
        return data.filter(call => new Date(call.date) >= last24Hours);
    }, [data]);

    const groupedByHourData = useMemo(() => {
        const grouped = {};
        last24HoursData.forEach(call => {
            const hour = new Date(call.date).getHours();
            if (!grouped[hour]) {
                grouped[hour] = [];
            }
            grouped[hour].push(call[selectedDataKey]);
        });

        return Object.keys(grouped).map(hour => {
            const values = grouped[hour];
            const average = values.reduce((acc, val) => acc + val, 0) / values.length;
            return {
                x: `${hour}:00`,
                y: parseFloat(average.toFixed(2)) // Format to two decimal places
            };
        });
    }, [last24HoursData, selectedDataKey]);

    const series = [{
        name: dataKeys.find(key => key.value === selectedDataKey).label,
        data: groupedByHourData
    }];

    const options = {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false,
                autoScaleYaxis: true
            },
            toolbar: {
                show: false
            },
            foreColor: '#E0E0E0',
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            borderColor: '#2E2E2E'
        },
        xaxis: {
            type: 'category',
            labels: {
                style: {
                    colors: '#E0E0E0'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#E0E0E0'
                },
                formatter: function (val) {
                    return val.toFixed(2); // Format to two decimal places
                }
            }
        },
        tooltip: {
            x: {
                format: 'HH:mm'
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
            }
        },
        colors: ['#FF4560']
    };

    return (
        <div className="bg-gray-800 p-6 rounded-lg shadow-md mt-12">
            <div className="mb-4 flex justify-center">
                {dataKeys.map(key => (
                    <button
                        key={key.value}
                        onClick={() => handleDataKeyChange(key.value)}
                        className={`p-2 rounded-md mx-2 ${selectedDataKey === key.value ? 'bg-blue-600' : 'bg-gray-600'} text-gray-300 border-gray-500 focus:ring-indigo-500 focus:ring-opacity-50`}
                    >
                        {key.label}
                    </button>
                ))}
            </div>
            <ApexCharts options={options} series={series} type="area" height={280} />
        </div>
    );
};

export default TimelineAreaChart;
