import React, { useEffect, useState, useRef } from 'react';
import * as echarts from 'echarts';

const TestingChart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const chartRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://magnetoo.fr/camera-api-data");
      const rawData = await response.json();
      const filteredData = rawData.filter(entry => entry.buildingGroup != null);
      const groupedData = filteredData.reduce((acc, curr) => {
        const { buildingGroup } = curr;
        acc[buildingGroup] = (acc[buildingGroup] || 0) + 1;
        return acc;
      }, {});
      const chartData = Object.entries(groupedData).map(([name, value]) => ({ name, value }));
      setData(chartData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const renderChart = (data) => {
      if (!chartRef.current || !data.length) return;

      const myChart = echarts.init(chartRef.current);

      const getShadeOfColor = (baseColor, index, total) => {
        let lightness = 30 + (index * 50) / total;
        return `hsl(${baseColor}, 100%, ${lightness}%)`;
      };

      const baseHue = 2000; // Update base hue for cyberpunk look
      data.forEach((item, index) => {
        if (item.name.startsWith("Khalifa") || item.name.startsWith('kha')) {
          item.itemStyle = { color: getShadeOfColor(baseHue, index, data.length) };
        } else if (item.name.startsWith('AlSadd')) {
          item.itemStyle = { color: '#91CC75' }; 
        }
        else if (item.name.startsWith('/MESA')|| item.name.startsWith('MESA')) {
          item.itemStyle = { color: '#8b5cf6' }; 
        }
        else if (item.name.startsWith('/QP')) {
          item.itemStyle = { color: '#EE6666'}; 
        }
        else {
          item.itemStyle = { color: '#FC8452'};
        }
      });

      const option = {
        responsive: true,

        color: ['#00ff00', '#ff00ff', '#91CC75', '#BA55D3'], // Neon green, magenta, and other cyberpunk colors
      
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)', // Show legend item name and percentage
          textStyle: {
            fontFamily: 'Arial, sans-serif',
            color: '#fafaf9'
          },
          backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent black background
          borderColor: '#66FF33', 
          borderWidth: 3, // Border width
          padding:1, // Padding
        },
        legend: {
          orient: 'horizontal', // Set the orientation to horizontal
          bottom: 40, // Position the legend at the bottom with some spacing
          itemGap: 5, // Add gap between legend items
          textStyle: {
            color: '#fafaf9', // Neon green text color
            fontSize: 9, // Reduce font size
            fontFamily: 'Arial, sans-serif', // Use a futuristic font for legend text
          },
          data: data.map(item => item.name),
           // Use item names as legend data
        },
        series: [
          {
            name: 'Location',
            type: 'pie',
            radius: ['40%', '75%'],
            center: ['50%', '41%'], 
            avoidLabelOverlap: true,
            label: {
              show: false, // Hide labels
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '50',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: data,
          }
        ]
      };

      myChart.setOption(option);

      const resizeListener = () => myChart.resize();
      window.addEventListener('resize', resizeListener);

      return () => {
        window.removeEventListener('resize', resizeListener);
        myChart.dispose();
      };
    };

    if (!loading) {
      renderChart(data);
    }
  }, [data, loading]);

  return (
    <div className='flex-grow' ref={chartRef} style={{ height: '500px' }}>
    
    </div>
  );
};

export default TestingChart;
