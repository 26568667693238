import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faClock, faCalendarAlt, faTools, faDatabase } from '@fortawesome/free-solid-svg-icons';

const ComparisonCard = ({ sites }) => {
  const [site1, setSite1] = useState(sites[0]);
  const [site2, setSite2] = useState(sites[1]);

  const handleSite1Change = (event) => {
    const selectedSite = sites.find(site => site.id === parseInt(event.target.value));
    setSite1(selectedSite);
  };

  const handleSite2Change = (event) => {
    const selectedSite = sites.find(site => site.id === parseInt(event.target.value));
    setSite2(selectedSite);
  };

  return (
    <div className="comparison-card bg-white text-gray-800 rounded-lg shadow-lg p-6 transition-transform duration-300">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Compare Sites</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-medium text-gray-600 mb-2">Select Site 1</label>
          <select
            value={site1.id}
            onChange={handleSite1Change}
            className="block w-full py-2 px-3 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {sites.map(site => (
              <option key={site.id} value={site.id}>{site.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-600 mb-2">Select Site 2</label>
          <select
            value={site2.id}
            onChange={handleSite2Change}
            className="block w-full py-2 px-3 border border-gray-300 bg-gray-50 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {sites.map(site => (
              <option key={site.id} value={site.id}>{site.name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <ComparisonMetric
          icon={faExclamationTriangle}
          metricName="Total Alarms"
          value1={site1.details.totalAlarms}
          value2={site2.details.totalAlarms}
        />
        <ComparisonMetric
          icon={faClock}
          metricName="Severe Alarms"
          value1={site1.details.severeAlarms}
          value2={site2.details.severeAlarms}
        />
        <ComparisonMetric
          icon={faClock}
          metricName="Total Downtime"
          value1={site1.details.totalDowntime}
          value2={site2.details.totalDowntime}
        />
        <ComparisonMetric
          icon={faCalendarAlt}
          metricName="Last Maintenance"
          value1={site1.details.lastMaintenance}
          value2={site2.details.lastMaintenance}
        />
        <ComparisonMetric
          icon={faTools}
          metricName="Resolution Timeline"
          value1={site1.details.resolutionTimeline}
          value2={site2.details.resolutionTimeline}
        />
        <ComparisonMetric
          icon={faDatabase}
          metricName="Data Usage"
          value1={site1.details.dataUsage}
          value2={site2.details.dataUsage}
        />
      </div>
    </div>
  );
};

const ComparisonMetric = ({ icon, metricName, value1, value2 }) => {
  const total = value1 + value2;
  const percentage1 = (value1 / total) * 100;
  const percentage2 = (value2 / total) * 100;

  return (
    <div className="flex items-center mb-3 p-3 bg-gray-50 rounded-md">
      <FontAwesomeIcon icon={icon} className="text-indigo-600 text-lg mr-3" />
      <div className="flex-grow">
        <div className="flex justify-between mb-1">
          <span className="text-md font-medium text-gray-800">{metricName}</span>
          <span className="text-md font-medium text-gray-800">{calculateDifference(value1, value2)}%</span>
        </div>
        <div className="relative w-full h-3 bg-gray-300 rounded-full overflow-hidden">
          <div className="absolute top-0 left-0 h-full bg-purple-500" style={{ width: `${percentage1}%` }}></div>
          <div className="absolute top-0 right-0 h-full bg-blue-200" style={{ width: `${percentage2}%` }}></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-gray-800 text-sm font-semibold">{value1} / {value2}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const calculateDifference = (metric1, metric2) => {
  return ((metric1 - metric2) / ((metric1 + metric2) / 2) * 100).toFixed(2);
};

export default ComparisonCard;
