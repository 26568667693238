import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
import sampleVideo from "../../assets/img/video.mp4";
import {
  VideoCameraIcon,
  VideoCameraSlashIcon,
} from "@heroicons/react/24/outline";
import BarChartWithDrilldown from "../charts/BarChart";
import Navbar from "../components/Navbar";
import ChatBot from "../components/Chatbot";
import html2canvas from "html2canvas";
import moment from "moment";
import jsPDF from "jspdf";

const Cameras = () => {
  const [playbackTime, setPlaybackTime] = useState(0);
  const [newlyAddedRows, setNewlyAddedRows] = useState(new Set());
  const [data, setData] = useState([]);
  const [areYouAllAlright, setAreYouAllAlright] = useState(0);
  const [areYouAllAlleft, setAreYouAllAlleft] = useState(0);
  const [areYouAllup, setAreYouAllAllup] = useState(0);
  const [areYouAlldown, setAreYouAllAlldown] = useState(0);
  const generatePDF = async () => {
    const input = document.getElementById('Camera');
  
    if (input) {
      html2canvas(input, { backgroundColor: null })
        .then((canvas) => {
          const ctx = canvas.getContext('2d');
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = '#000'; // Set the background color to black
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.restore();
  
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
  
          const currentDate = moment().format('YYYY-MM-DD_HH-mm-ss');
          const pageTitle = 'CameraPage'; // Replace this with the actual title of your main page
          const fileName = `${pageTitle}_${currentDate}.pdf`;
  
          pdf.addImage(imgData, 'PNG', 0, 0, width, height);
          pdf.save(fileName);
        });
    } else {
      console.error("Dashboard element not found.");
    }
  };
  

  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchData = () => {
      fetch("https://magnetoo.fr/detection_data")
        .then((response) => response.json())
        .then((newData) => {
          const newRows = new Set();
          newData.forEach((newRow) => {
            if (!data.some((existingRow) => existingRow.id === newRow.id)) {
              newRows.add(newRow.id);
            }
          });

          if (newRows.size > 0) {
            setNewlyAddedRows(newRows);

            setTimeout(() => {
              setNewlyAddedRows(new Set());
            }, 5000);
          }

          setAreYouAllAlright(
            newData.filter(
              (el) => el.wire === 1 && Number(el.frame_time) <= playbackTime - 3
            ).length
          );
          setAreYouAllAlleft(
            newData.filter(
              (el) => el.wire === 3 && Number(el.frame_time) <= playbackTime - 3
            ).length
          );
          setAreYouAllAllup(
            newData.filter(
              (el) =>
                el.direction === "up" && Number(el.frame_time) <= playbackTime
            ).length
          );
          setAreYouAllAlldown(
            newData.filter(
              (el) =>
                el.direction === "down" && Number(el.frame_time) <= playbackTime
            ).length
          );

          setData(newData);
        })
        .catch((error) => console.error("Error fetching data:", error));
    };

    fetchData();
    const intervalId = setInterval(fetchData, 6000);

    return () => clearInterval(intervalId);
  }, [data, playbackTime]);

  return (
    <div className="bg-gray-950 min-h-screen flex flex-col lg:flex-row">
      <ChatBot />

      <div id="Camera" className ="flex-grow p-4">
        <div>
          <Navbar navigate={navigate} />
        </div>
        <br></br>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
          {/* Responsive Cards */}
          <motion.div
            initial={{ opacity: 0, translateX: 300 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{
              duration: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
              delay: 0.2,
            }}
            className="h-40 rounded-lg bg-gray-900 flex flex-row justify-center items-center p-5 hover:bg-blue-600 duration-200 hover:shadow-2xl hover:shadow-blue-800"
            id="card"
          >
            {/* Adjusted for alignment, consider if the icon should be inside or outside the centered content */}
            <div className="flex flex-col items-center justify-center w-full">
              <h1 className="text-4xl sm:text-6xl md:text-8xl text-white">
                {areYouAllAlleft}
              </h1>
              <h1 className="text-white text-sm font-light">
                Total People left
              </h1>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, translateX: 300 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{
              duration: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
              delay: 0.2,
            }}
            className="h-40 rounded-lg bg-gray-900 flex items-center justify-center p-5 hover:bg-blue-600 duration-200 hover:shadow-2xl hover:shadow-blue-800"
          >
            <div className="flex flex-col items-center justify-center w-full">
              <h1 className="text-4xl sm:text-6xl md:text-8xl text-white">
                {areYouAllAlright}
              </h1>
              <h1 className="text-white text-sm font-light">
                Total People Right
              </h1>
            </div>
          </motion.div>

          {/* Additional cards similar to the above one */}
          
          <motion.div
            initial={{ opacity: 0, translateX: 300 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{
              duration: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
              delay: 0.2,
            }}
            className=" h-40 rounded-lg bg-lime-500 flex flex-row items-center p-5 hover:bg-blue-600 duration-200 hover:shadow-2xl hover:shadow-blue-800"
            id="card"
          >
            <VideoCameraIcon className="text-blue-700 w-12 " />
            <div className="flex flex-col text-right mr-5 ">
              <h1 className="text-2xl text-white">78</h1>
              <h1 className="text-white text-sm font-light"> Cameras up</h1>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, translateX: 300 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{
              duration: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
              delay: 0.2,
            }}
            className=" h-40 rounded-lg bg-red-400 flex flex-row items-center p-5 hover:bg-blue-600 duration-200 hover:shadow-2xl hover:shadow-blue-800"
            id="card"
          >
            <VideoCameraSlashIcon className="text-blue-700 w-12 " />
            <div className="flex flex-col text-right mr-5 ">
              <h1 className="text-2xl text-white">7</h1>
              <h1 className="text-white text-sm font-light">
                {" "}
                Cameras offline
              </h1>
            </div>
          </motion.div>
        </div>

        <div
          className="bg-gray-950 rounded-md p-2 flex flex-col md:flex-row gap-4 mb-6"
          style={{ maxHeight: "500px" }}
        >
          {/* Video Player */}
          <div className="bg-gray-900 rounded-md p-2 flex-1">
            <ReactPlayer
              url={sampleVideo}
              width="100%"
              height="100%"
              controls={true}
              onProgress={({ playedSeconds }) =>
                setPlaybackTime(Math.floor(playedSeconds))
              }
            />
          </div>

          {/* Data Table */}
          <div
            className="bg-gray-900 rounded-md  p-2 flex-1"
            style={{ maxHeight: "500px" }}
          >
            {/* Table goes here */}
            <div className="max-h-[500px]">
        
              <div className="max-h-[460px] overflow-y-auto">
                <table className="w-full text-white text-sm bg-gray-900 rounded-md">
                <tr className="bg-blue-900">
                    <th className="font-medium p-3">Date</th>
                    <th className="font-medium p-3">Count</th>
                    <th className="font-medium p-3">Class</th>
                    <th className="font-medium p-3">Direction</th>
                    <th className="font-medium p-3">Wire</th>
                  </tr>
                  <tbody>
                    {data
                      .filter((line) => Number(line.frame_time) <= playbackTime)
                      .map((line) => (
                        <motion.tr
                          key={line.id}
                          className={`${
                            newlyAddedRows.has(line.id) ? "bg-green-200" : ""
                          } transition duration-1000`}
                        >
                          <td className="px-6 py-4">{line.system_date}</td>
                          <td
                            className={`px-6 py-4 ${
                              line.direction === "Disconnected"
                                ? "text-rose-400"
                                : "text-teal-500"
                            }`}
                          >
                            {line.extracted_count}
                          </td>
                          <td className="px-6 py-4">{line.entity_class}</td>
                          <td className="px-6 py-4">{line.direction}</td>
                          <td className="px-6 py-4">{line.wire}</td>
                        </motion.tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-950 rounded-md p-2 flex flex-col md:flex-row gap-4 mb-6">
          {/* Bar Charts */}
          <div className="bg-gray-900 rounded-md p-2 flex-1">
            <BarChartWithDrilldown
              data={{ up: areYouAllup, down: areYouAlldown }}
            />
          </div>
          <div className="bg-gray-900 rounded-md p-2 flex-1">
            <BarChartWithDrilldown
              data={{ left: areYouAllAlleft, right: areYouAllAlright }}
            />
          </div>
        </div>
        <button
  className="bg-blue-500 text-white px-4 py-2 rounded mt-5"
  onClick={generatePDF}
>
  save as PDF
</button>
      </div>
     
    </div>
  );
};

export default Cameras;
