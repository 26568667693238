import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const cctvOnIcon = new L.Icon({
  iconUrl: require('../../assets/img/cctv_on.png'),
  iconSize: [32, 32], // Consider making the icon a bit smaller for a more subtle appearance
  iconAnchor: [16, 32],
  popupAnchor: [1, -34],
});

const cctvOffIcon = new L.Icon({
  iconUrl: require('../../assets/img/khalifa_camera.png'),
  iconSize: [32, 32], // Consider making the icon a bit smaller for a more subtle appearance
  iconAnchor: [16, 32],
  popupAnchor: [1, -34],
});

const MapComponent = () => {
  const [geoData, setGeoData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://magnetoo.fr/camera-api-data");
        const data = await response.json();
        // Filter out items where 'buildingGroup' is null
        const filteredData = data.filter(item => item.buildingGroup !== null);
        setGeoData(filteredData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchData();
    const intervalId = setInterval(fetchData, 10000); // Fetch data every 10 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <MapContainer center={[25.263711008108373, 51.446896188253994]} zoom={13} className="h-full w-full" scrollWheelZoom={true} maxZoom={18} minZoom={3}>
      <TileLayer
  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
  attribution='' // Set attribution to an empty string
/>

      {geoData.map((camera) => (
        camera.location && (
          <Marker
            key={camera.camera_id}
            position={[parseFloat(camera.location.latitude), parseFloat(camera.location.longitude)]}
            icon={camera.Status === "On" ? cctvOnIcon : cctvOffIcon}
          >
            <Tooltip direction="top" offset={[0, -32]} opacity={1}>
              <div>
               
                <strong>{camera.buildingGroup}</strong><br />
                
               

              </div>
            </Tooltip>
          </Marker>
        )
      ))}
    </MapContainer>
  );
};

export default MapComponent;
