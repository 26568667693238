import React from 'react';

const TotalInterventionsCard = ({ data }) => {
  const totalInterventions = data.length

  return (
    <div className="bg-gray-900 p-4 rounded-lg shadow-lg  flex items-center justify-between transform hover:scale-105 transition-transform duration-300 h-28 w-85">
      <div className="text-white animate-bounce">
        <i className="fas fa-chart-line text-3xl"></i>
      </div>
      <div className="text-center flex-1">
        <h3 className="text-lg font-bold text-white mb-1 text-center">Total Alerts</h3>
        <p className="text-3xl font-extrabold text-yellow-300 text-center">{totalInterventions}</p>
      </div>
    </div>
  );
};

export default TotalInterventionsCard;
