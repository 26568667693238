  import React, { useEffect } from 'react';
  import { MsalProvider, useIsAuthenticated } from '@azure/msal-react';
  import { msalInstance, getStoredAuthState } from './assets/pages/authConfig';
  import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
  import Cameras from './assets/pages/Cameras';
  import Alerts from './assets/pages/Alerts';
  import Login from './assets/pages/Login';
  import Map from './assets/pages/Map';
  import Main from './assets/pages/Main';
  import MainV2 from './assets/pages/MainV2';
  import Financial_KPIs from './assets/pages/Mall_Retail_Pages/Financial_KPIs';
  import GuestsManagment from './assets/pages/Mall_Retail_Pages/GuestsManagment';
import ElvSystems from './assets/pages/Mall_Retail_Pages/Elv_system';
import  Main_retail from './assets/pages/Retail/Main_Retail';
import ParkingDashboard from './assets/pages/Parking/Parking_dashboard';
import Store_sales from './assets/pages/Retail/Store_sales';
import DrillingDashboard from './assets/pages/armaco/Drilling_main_Dashboard';
import CallCenter from './assets/pages/Call_Center/Call_Center';
import Vacation_dashboard from './assets/pages/Vacation/Vacation._dashboard';
import Applications from './assets/pages/Alain/Applications';
import Departments from './assets/pages/Alain/Departments';
import Overview from './assets/pages/Alain/OverView';
import Transactions from './assets/pages/Alain/Transactions';
import Users from './assets/pages/Alain/Users';
import SidebarLayout from './assets/layouts/SidebarLayout';
import LandingPage from './assets/pages/LandingPage';
import Hotel_Financial_KPIs from './assets/pages/Hotel_Dashboard/Hotel_financial_KPI';
import VisitorPage from './assets/pages/Hotel_Dashboard/Visitortab';
import TenderTypePieChart from './assets/charts/Retail_charts/RetailPiechart.js';
import RetailSalesCostProfitChart from './assets/charts/Retail_charts/RetailSalesCostPofitChart.js'

  const ProtectedRoute = ({ element: Element, ...rest }) => {
    const isAuthenticated = getStoredAuthState();
    return isAuthenticated ? <Element {...rest} /> : <Navigate to="/login" replace />;
  };

  function App() {
    useEffect(() => {
      const cachedAuthState = getStoredAuthState();
      console.log("cachedState", cachedAuthState);
      if (cachedAuthState) {
        msalInstance.setActiveAccount(cachedAuthState);
      }
    }, []);

    return (
      <MsalProvider instance={msalInstance}>
        <div className="App">
          <Router>
            <Routes>
              <Route path="/" element={<Navigate replace to="/login" />} />
              <Route path="/main" element={<ProtectedRoute element={Main} />} />
              <Route path="/mainv2" element={<ProtectedRoute element={MainV2} />} />
              <Route path="/landingPage" element={<ProtectedRoute element={LandingPage} />} />

              <Route path="/cameras" element={<ProtectedRoute element={Cameras} />} />
              <Route path="/alerts" element={<ProtectedRoute element={Alerts} />} />
              <Route path="/map" element={<ProtectedRoute element={Map} />} />
              <Route path="/financial_KPIs" element={<ProtectedRoute element={Financial_KPIs} />} />
              <Route path="/GuestsManagment" element={<ProtectedRoute element={GuestsManagment} />} />
              <Route path="/Elv_systems" element={<ProtectedRoute element={ElvSystems} />} />
              <Route path="/Main_retail" element={<ProtectedRoute element={Main_retail} />} />
              <Route path="/Store_sales" element={<ProtectedRoute element={Store_sales} />} />
              <Route path="/Parking_Dashboard" element={<ProtectedRoute element={ParkingDashboard} />} />
              <Route path="/DrillingDashboard" element={<ProtectedRoute element={DrillingDashboard} />} />
              <Route path="/CallCenter" element={<ProtectedRoute element={CallCenter} />} />
              <Route path="/Vacation_dashboard" element={<ProtectedRoute element={Vacation_dashboard} />} />
              <Route path="/hotel_Financial_KPIs" element={<ProtectedRoute element={Hotel_Financial_KPIs} />} />
              <Route path="/tenderTypePieChart" element={<TenderTypePieChart/>} />
            <Route path="/retailSalesCostProfitChart" element={< RetailSalesCostProfitChart/>} />
            <Route path="/visitorPage" element={<ProtectedRoute element={VisitorPage} />} />
              <Route path ="/"element={<SidebarLayout />}>
              <Route path="overview" element={<Overview />} />
              <Route path="applications" element={<Applications />} />
              <Route path="users" element={<Users />} />
              <Route path="departments" element={<Departments />} />
              <Route path="transactions" element={<Transactions />} />
            </Route>

              
              <Route path="/login" element={<Login />} />
            </Routes>
          </Router>
        </div>
      </MsalProvider>
    );
  }

  export default App;
