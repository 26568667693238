import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';

const MudLineChartComponent = () => {
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState('mud_viscosity'); // Default filter to match a database field

  const fetchData = async (filter) => {
    try {
      const response = await axios.get('https://magnetoo.fr/drilling-data', { params: { filter } });
      const data = response.data;

      // Log the response data
      console.log('API response data:', data);

      if (data.length > 0) {
        // Adjust this part according to your database schema
        const timestamps = data.map(entry => new Date(entry.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
        const values = data.map(entry => entry[filter]);

        // Log the parsed data
        console.log('Parsed timestamps:', timestamps);
        console.log('Parsed values:', values);

        // Take only the latest 5 values
        const recentTimestamps = timestamps.slice(-5);
        const recentValues = values.slice(-5);

        setCategories(recentTimestamps);
        setSeries([
          {
            name: filter,
            data: recentValues
          }
        ]);
      } else {
        console.warn('No data received from API');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(filter);

    const intervalId = setInterval(() => {
      fetchData(filter);
    }, 5000); // Fetch data every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [filter]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const options = {
    chart: {
      type: 'line',
      background: '#1f2937', // Dark background color
      foreColor: '#e5e7eb', // Light foreground color for text
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    grid: {
      borderColor: '#374151', // Grid color
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    markers: {
      size: 6,
      colors: ['#10b981'], // Green markers
      strokeColors: '#1f2937',
      strokeWidth: 2,
      hover: {
        size: 8
      }
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: true,
        color: '#374151'
      },
      axisTicks: {
        show: true,
        color: '#374151'
      },
      labels: {
        rotate: -45,
        style: {
          fontSize: '12px',
          colors: ['#e5e7eb']
        }
      }
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      labels: {
        formatter: (val) => val.toFixed(0),
        style: {
          colors: ['#e5e7eb']
        }
      }
    },
    stroke: {
      curve: 'smooth',
      width: 3,
      colors: ['#3b82f6'] // Blue line color
    },
    tooltip: {
      theme: 'dark'
    },
    title: {
      text: filter.replace(/_/g, ' '), // Replace underscores with spaces for better readability
      align: 'left',
      style: {
        fontSize: '20px',
        color: '#3b82f6'
      }
    },
    subtitle: {
      text: 'Real-time Curve',
      align: 'right',
      style: {
        fontSize: '14px',
        color: '#10b981'
      }
    },
  };

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="filter" style={{ color: '#fff' ,alignItems:'left'}}></label>
        <select id="filter" value={filter} onChange={handleFilterChange} style={{ padding: '5px', borderRadius: '5px', background: '#374151', color: '#e5e7eb' }}>
          <option value="mud_viscosity">Mud Viscosity</option>
          <option value="mud_density">Mud Density</option>
          <option value="mud_pit_percentage">Mud Pit Percentage</option>
        </select>
      </div>
      <div id="chart" style={{ background: '#1f2937', padding: '20px', borderRadius: '10px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.5)' }}>
        <Chart options={options} series={series} type="line" height="350" />
      </div>
      <div id="table" style={{ background: '#1f2937', padding: '20px', borderRadius: '10px', boxShadow: '0 0 15px rgba(0, 0, 0, 0.5)', marginTop: '20px' }}>
        <table style={{ width: '100%', color: '#e5e7eb', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ borderBottom: '1px solid #374151', padding: '10px', background: 'linear-gradient(90deg, #3b82f6, #10b981)', color: '#fff' }}>Time</th>
              <th style={{ borderBottom: '1px solid #374151', padding: '10px', background: 'linear-gradient(90deg, #3b82f6, #10b981)', color: '#fff' }}>{filter.replace(/_/g, ' ')}</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((timestamp, index) => (
              <tr key={index}>
                <td style={{ borderBottom: '1px solid #374151', padding: '10px', background: index % 2 === 0 ? '#1f2937' : '#374151' }}>{timestamp}</td>
                <td style={{ borderBottom: '1px solid #374151', padding: '10px', background: index % 2 === 0 ? '#1f2937' : '#374151' }}>{series[0]?.data[index]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MudLineChartComponent;
