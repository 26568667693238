import avatar3 from "../../assets/img/customcolor_logo_transparent_background.png";
import React, { useEffect, useState, useRef } from "react";
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ColumnChart from '../charts/ColumnChart';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';
import {
  Battery50Icon,
  ServerIcon,
  CpuChipIcon,
} from "@heroicons/react/24/outline";
import MyChartComponent from "../charts/MyChartComponent";
import GaugeChart from "../charts/Gauge";
import Navbar from "../components/Navbar";
import ChatBot from "../components/Chatbot";
import PopupAlert from "../components/PopupAlert";
import { ChakraProvider } from "@chakra-ui/react";

const Main = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCluster, setSelectedCluster] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const fetchInterval = useRef(null);

  const handleAlert = (alertInfo) => {
    console.log('Alert received:', alertInfo);
  };

  const generatePDF = async () => {
    const input = document.getElementById('dashboard');
    if (input) {
      html2canvas(input, { backgroundColor: null })
        .then((canvas) => {
          const ctx = canvas.getContext('2d');
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = '#000';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.restore();
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
          const currentDate = moment().format('YYYY-MM-DD_HH-mm-ss');
          const pageTitle = 'MainPage';
          const fileName = `${pageTitle}_${currentDate}.pdf`;
          pdf.addImage(imgData, 'PNG', 0, 0, width, height);
          pdf.save(fileName);
        });
    } else {
      console.error("Dashboard element not found.");
    }
  };

  const fetchData = () => {
    fetch("https://magnetoo.fr/data")
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched data:', data);
        setData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    if (isFetching) {
      fetchData();
      fetchInterval.current = setInterval(fetchData, 5000);
    } else {
      clearInterval(fetchInterval.current);
    }
    return () => clearInterval(fetchInterval.current);
  }, [isFetching]);

  useEffect(() => {
    let filtered = data;
    if (startDate && endDate) {
      filtered = filtered.filter(item => {
        const itemDate = moment(item.record_date, 'YYYY-MM-DD HH:mm:ss');
        const isInRange = itemDate.isBetween(
          moment(startDate, 'YYYY-MM-DD'),
          moment(endDate, 'YYYY-MM-DD'),
          'days',
          '[]'
        );
        console.log(`Item date: ${itemDate.format('YYYY-MM-DD HH:mm:ss')}, In range: ${isInRange}`);
        return isInRange;
      });
    }
    if (selectedCluster) {
      filtered = filtered.filter(item => item.cluster_name === selectedCluster);
    }
    console.log('Filtered data:', filtered);
    setFilteredData(filtered);
  }, [data, startDate, endDate, selectedCluster]);

  const resetFilter = () => {
    setStartDate('');
    setEndDate('');
    setSelectedCluster('');
    setFilteredData(data);
  };

  const toggleFetching = () => {
    setIsFetching(prev => !prev);
  };

  const calculateAverages = (data) => {
    if (!data.length) return { avgCpuUsage: 0, avgServerHealth: 0 };
    const avgCpuUsage = data.reduce((acc, item) => acc + item.cpu_usage, 0) / data.length;
    const avgServerHealth = data.reduce((acc, item) => acc + item.server_health, 0) / data.length;
    return { avgCpuUsage, avgServerHealth };
  };

  const { avgCpuUsage, avgServerHealth } = calculateAverages(filteredData);

  const uniqueClusters = [...new Set(data.map(item => item.cluster_name))];

  return (
    <div className="bg-gray-950 min-h-screen">
      <ChatBot />
      <div id="dashboard" className="p-5">
        <PopupAlert />
        <Navbar navigate={navigate} />
        <ChakraProvider>
          <PopupAlert onAlert={handleAlert} />
        </ChakraProvider>
        <div className="flex items-center justify-between flex-wrap">
          <div className="flex items-center space-x-4 mb-4 sm:mb-0">
            <div>
              <label className="block text-white mb-1" htmlFor="startDate">Start Date</label>
              <input
                type="date"
                id="startDate"
                className="px-3 py-2 rounded bg-gray-800 text-white"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-white mb-1" htmlFor="endDate">End Date</label>
              <input
                type="date"
                id="endDate"
                className="px-3 py-2 rounded bg-gray-800 text-white"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-white mb-1" htmlFor="clusterName">Cluster Name</label>
              <select
                id="clusterName"
                className="px-3 py-2 rounded bg-gray-800 text-white"
                value={selectedCluster}
                onChange={(e) => setSelectedCluster(e.target.value)}
              >
                <option value="">All Clusters</option>
                {uniqueClusters.map(cluster => (
                  <option key={cluster} value={cluster}>{cluster}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <button
              className="bg-red-500 text-white px-4 py-2 rounded"
              onClick={resetFilter}
            >
              Reset Filter
            </button>
            <button
              className={`px-4 py-2 rounded ${isFetching ? 'bg-red-500' : 'bg-green-500'} text-white`}
              onClick={toggleFetching}
            >
              {isFetching ? 'Stop Real-Time Data' : 'Start Real-Time Data'}
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-5">
          <motion.div
            initial={{ opacity: 0, translateX: 300 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.5, ease: [0, 0.71, 0.2, 1.01], delay: 0.2 }}
            className="rounded-lg bg-gray-900 flex items-center p-5 hover:bg-blue-600 duration-200 hover:shadow-2xl hover:shadow-blue-800"
          >
            <ServerIcon className="text-blue-700 w-12" />
            <div className="flex flex-col justify-center items-center text-center mx-auto text-white">
              <h1 className="text-6xl">{avgServerHealth.toFixed(2)}%</h1>
              <p className="text-sm font-light">Average Server Health</p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, translateX: 300 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.5, ease: [0, 0.71, 0.2, 1.01], delay: 0.2 }}
            className="rounded-lg bg-gray-900 flex items-center p-5 hover:bg-blue-600 duration-200 hover:shadow-2xl hover:shadow-blue-800"
          >
            <Battery50Icon className="text-blue-700 w-12 " />
            <div className="flex flex-col justify-center items-center text-center mx-auto">
              <h1 className={`text-6xl ${avgCpuUsage > 50 ? "text-green-500" : "text-red-500"}`}>
                {avgCpuUsage.toFixed(2)}%
              </h1>
              <h1 className="text-white text-sm font-light">Average CPU Usage</h1>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, translateX: 300 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.5, ease: [0, 0.71, 0.2, 1.01], delay: 0.4 }}
            className="rounded-lg bg-gray-900 flex items-center p-5 hover:bg-blue-600 duration-200 hover:shadow-2xl hover:shadow-blue-800"
          >
            <Battery50Icon className="text-blue-700 w-12 " />
            <div className="flex flex-col justify-center items-center text-center mx-auto">
              <h1 className={`text-6xl ${!data.length ? "text-white" : data[0].server_health > 50 ? "text-green-500" : "text-red-500"}`}>
                {!data.length ? "N/A" : data[0].server_health}%
              </h1>
              <h1 className="text-white text-sm font-light">Server Health</h1>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, translateX: 300 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.5, ease: [0, 0.71, 0.2, 1.01], delay: 0.6 }}
            className="rounded-lg bg-gray-900 flex flex-row items-center p-5 hover:bg-blue-600 duration-200 hover:shadow-2xl hover:shadow-blue-800"
            id="card"
          >
            <CpuChipIcon className="text-blue-700 w-12 " />
            <div className="flex flex-col justify-center items-center text-center mx-auto">
              <h1 className={`text-6xl ${!data.length ? "text-white" : data[0].cpu_usage > 80 ? "text-rose-400" : data[0].cpu_usage > 50 ? "text-amber-500" : "text-green-500"}`}>
                {!data.length ? "N/A" : `${data[0].cpu_usage}%`}
              </h1>
              <h1 className="text-white text-sm font-light">Total CPU Usage</h1>
            </div>
          </motion.div>
        </div>
        <div className="flex flex-col lg:flex-row gap-4 mt-5">
          <div className="bg-gray-900 rounded-md p-2 h-80 flex-grow items-center">
            <GaugeChart title={'Total CPU Usage'} data={avgCpuUsage} />
          </div>
          <div className="bg-gray-900 rounded-md p-4 h-80 lg:w-1/3 flex items-center justify-center">
            <motion.img
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2, ease: "easeInOut", repeat: Infinity, repeatType: "reverse", repeatDelay: 1 }}
              src={avatar3}
              className="w-full h-full"
              alt="Avatar"
            />
          </div>
          <div className="bg-gray-900 rounded-md p-2 h-80 flex-grow items-center">
            <GaugeChart title={'Current Server Health'} data={avgServerHealth} />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
          <div className="bg-gray-900 rounded-md p-4">
            <h1 className="text-white text-2xl font-light text-center">Storage Used / BLDG</h1>
            <ColumnChart data={filteredData} />
          </div>
          <div className="bg-gray-900 rounded-md p-4">
            <h1 className="text-white text-2xl font-light text-center">CPU Used / BLDG</h1>
            <MyChartComponent data={filteredData} />
          </div>
        </div>
        <div className="overflow-auto mt-5">
          <table className="w-full text-white">
            <thead>
              <tr className="border-b border-white border-opacity-5 bg-slate-800 text-center">
                <th className="p-3">Server Health</th>
                <th className="p-3">Date</th>
                <th className="p-3">CPU Usage</th>
                <th className="p-3">Switch Status</th>
                <th className="p-3">Cluster Name</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.slice(0, 5).map((item, index) => (
                <tr key={index} className="hover:bg-blue-600 duration-300">
                  <td className="p-3">{item.server_health}%</td>
                  <td className="p-3">{moment(item.record_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                  <td className="p-3">{item.cpu_usage}%</td>
                  <td className={`p-3 ${item.switch_status === "Disconnected" ? "text-rose-400" : "text-teal-500"}`}>
                    {item.switch_status}
                  </td>
                  <td className="p-3">{item.cluster_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {filteredData.length === 0 && (
            <div className="text-white text-center mt-5">
              No data available for the selected date range and cluster.
            </div>
          )}
        </div>
      </div>
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded mt-5"
        onClick={generatePDF}
      >
        Save as PDF
      </button>
    </div>
  );
};


export default Main;
