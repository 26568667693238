import React from 'react';
import { FaReceipt } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import Navbar from '../../components/Navbar';
import { IoCashOutline, IoTicketOutline } from "react-icons/io5";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { GiProfit } from "react-icons/gi";
import { LiaStoreSolid } from "react-icons/lia";
import MonthlySalesChart from '../../charts/Retail_charts/MonthlySalesBarChart';
import RetailSalesCostProfitChart from '../../charts/Retail_charts/RetailSalesCostPofitChart';
import TopStoresChart from '../../charts/Retail_charts/TopStoreChart';
import SalesPieChart from '../../charts/Retail_charts/RetailPiechart';
import TopItemsChart from '../../charts/Retail_charts/TopItemsChart';

const MainRetail = () => {
    const sampleData = [0, 2, 12, 18];
    const navigate = useNavigate();

    return (
        <div className="bg-black min-h-screen p-4 font-orbitron text-neon-pink">
            <Navbar navigate={navigate} />
            <br />
            <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                <div className="col-span-1 md:col-span-1 row-span-2 bg-gradient-to-r from-pink-500 to-purple-700 rounded-xl shadow-lg p-4 h-32 flex flex-col justify-center items-center relative">
                    <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-pink-500 to-purple-700 opacity-20 animate-pulse"></div>
                    <div className="flex items-center justify-center rounded-lg shadow-md bg-rose-400 p-2 z-10">
                        <FaReceipt style={{ color: 'white' }} size={30} />
                    </div>
                    <h2 className="text-lg text-white mt-2 z-10">321</h2>
                    <h2 className="text-sm font-serif text-white mt-2 z-10">Receipt</h2>
                </div>
                <div className="col-span-1 md:col-span-1 row-span-2 bg-gradient-to-r from-green-500 to-blue-700 rounded-xl shadow-lg p-4 h-32 flex flex-col justify-center items-center">
                    <div className="flex items-center justify-center rounded-lg shadow-md bg-green-500 p-2">
                        <IoCashOutline style={{ color: 'white' }} size={30} />
                    </div>
                    <h2 className="text-lg text-white mt-2">321</h2>
                    <h2 className="text-sm font-serif text-white mt-2">Sales</h2>
                </div>
                <div className="col-span-1 md:col-span-1 row-span-2 bg-gradient-to-r from-violet-500 to-purple-700 rounded-xl shadow-lg p-4 h-32 flex flex-col justify-center items-center">
                    <div className="flex items-center justify-center rounded-lg shadow-md bg-purple-500 p-2">
                        <MdOutlineProductionQuantityLimits style={{ color: 'white' }} size={30} />
                    </div>
                    <h2 className="text-lg text-white mt-2">321</h2>
                    <h2 className="text-sm font-serif text-white mt-2">Quantity</h2>
                </div>
                <div className="col-span-1 md:col-span-1 row-span-2 bg-gradient-to-r from-yellow-500 to-orange-700 rounded-xl shadow-lg p-4 h-32 flex flex-col justify-center items-center">
                    <div className="flex items-center justify-center rounded-lg shadow-md bg-yellow-500 p-2">
                        <IoTicketOutline style={{ color: 'white' }} size={30} />
                    </div>
                    <h2 className="text-lg text-white mt-2">321</h2>
                    <h2 className="text-sm font-serif text-white mt-2">Cost</h2>
                </div>
                <div className="col-span-1 md:col-span-1 row-span-2 bg-gradient-to-r from-cyan-500 to-blue-700 rounded-xl shadow-lg p-4 h-32 flex flex-col justify-center items-center">
                    <div className="flex items-center justify-center rounded-lg shadow-md bg-cyan-500 p-2">
                        <GiProfit style={{ color: 'white' }} size={30} />
                    </div>
                    <h2 className="text-lg text-white mt-2">321</h2>
                    <h2 className="text-sm font-serif text-white mt-2">Profit</h2>
                </div>
                <div className="col-span-1 md:col-span-1 row-span-2 bg-gradient-to-r from-green-500 to-teal-700 rounded-xl shadow-lg p-4 h-32 flex flex-col justify-center items-center">
                    <div className="flex items-center justify-center rounded-lg shadow-md bg-green-500 p-2">
                        <LiaStoreSolid style={{ color: 'white' }} size={30} />
                    </div>
                    <h2 className="text-lg text-white mt-2">4</h2>
                    <h2 className="text-sm font-serif text-white mt-2">Stores</h2>
                </div>
                <div className="col-span-1 md:col-span-3 md:row-span-4 bg-gray-900 h-96 rounded-xl shadow-lg p-4">
                    <p className='text-slate-400 text-left mt-2 ml-2'>Monthly Sales Trend</p>
                    <MonthlySalesChart />
                </div>
                <div className="col-span-1 md:col-span-3 md:row-span-4 bg-gray-900 h-96 rounded-xl shadow-lg p-4">
                    <p className='text-slate-400 text-left mt-2 ml-2'>Monthly Performance</p>
                    <RetailSalesCostProfitChart />
                </div>
                <div className="col-span-1 md:col-span-2 md:row-span-4 bg-gray-900 h-96 rounded-xl shadow-lg p-4">
                    <p className='text-slate-400 text-left mt-2 ml-2'>Top 5 stores by sales</p>
                    <TopStoresChart />
                </div>
                <div className="col-span-1 md:col-span-2 md:row-span-4 bg-gray-900 h-96 rounded-xl shadow-lg p-4">
                    <p className='text-slate-400 text-left mt-2 ml-2'>Sales by Tender Type</p>
                    <SalesPieChart />
                </div>
                <div className="col-span-1 md:col-span-2 md:row-span-4 bg-gray-900 h-96 rounded-xl shadow-lg p-4">
                    <p className='text-slate-400 text-left mt-2 ml-2'>Top 5 items by Sales</p>
                    <TopItemsChart />
                </div>
            </div>
        </div>
    );
}

export default MainRetail;
