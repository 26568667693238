import React from 'react';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';

const RoundsLineChart = ({ data }) => {
  const dates = [...new Set(data.map(item => item.DATE))];
  const formattedDates = dates.map(date => dayjs(date).format('MM/DD'));
  const rounds = dates.map(date => data.filter(item => item.DATE === date && item.RONDE !== null).length);

  const chartData = {
    labels: formattedDates,
    datasets: [
      {
        label: 'Rounds Conducted',
        data: rounds,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',  // Lighter background
        borderColor: 'rgba(255, 255, 255, 1)',        // White border color for visibility
        borderWidth: 2,
        pointBackgroundColor: 'rgba(255, 255, 255, 1)',
        pointBorderColor: '#000',                    // Dark point border for contrast
        pointHoverBackgroundColor: '#000',
        pointHoverBorderColor: 'rgba(255, 255, 255, 1)',
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: '#fff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
      y: {
        ticks: {
          color: '#fff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#fff',
        },
      },
    },
  };

  return (
    <div className="bg-gray-900 p-6 rounded-lg shadow-lg  transform hover:scale-105 transition-transform duration-300">
      <h3 className="text-lg font-bold text-white mb-4">Rounds Conducted Per Date</h3>
      <div className="h-96">
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default RoundsLineChart;
