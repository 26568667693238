import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHotel, faHome, faUsers, faChartBar, faFileAlt, faEnvelope, faCog, faQuestionCircle, faCommentDots, faCamera } from '@fortawesome/free-solid-svg-icons';
import 'tailwindcss/tailwind.css';

const Hotel_Sidebar = ({ darkMode, isOpen }) => {
  return (
    <div className={`fixed inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out md:relative md:translate-x-0 w-60 ${darkMode ? 'bg-gradient-to-b from-gray-800 to-gray-900 text-gray-400' : 'bg-gradient-to-b from-blue-100 to-blue-300 text-gray-900'} flex flex-col justify-between shadow-2xl`}>
      <div>
        <div className="p-4 flex items-center">
          <FontAwesomeIcon icon={faHotel} className="text-blue-500 text-3xl mr-3 animate-spin-slow" />
          <div className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-black'}`}>BlueWave Hotel</div>
        </div>
        <nav className="mt-6 space-y-2">
          <a className={`flex items-center p-3 rounded-lg transition-transform transform hover:scale-105 duration-200 ${darkMode ? 'text-gray-200 hover:bg-blue-700 hover:text-white' : 'text-gray-800 hover:bg-blue-500 hover:text-white'}`} href="/hotel_Financial_KPIs">
            <FontAwesomeIcon icon={faHome} className="mr-3 text-xl" />
            <span className="text-xs">Overview</span>
          </a>
          <a className={`flex items-center p-3 rounded-lg transition-transform transform hover:scale-105 duration-200 ${darkMode ? 'text-gray-200 hover:bg-blue-700 hover:text-white' : 'text-gray-800 hover:bg-blue-500 hover:text-white'}`} href="/visitorPage">
          <FontAwesomeIcon icon={faUsers} className="mr-3 text-xl" />
            <span className="text-xs">Audience</span>
          </a>
          <a className={`flex items-center p-3 rounded-lg transition-transform transform hover:scale-105 duration-200 ${darkMode ? 'text-gray-200 hover:bg-blue-700 hover:text-white' : 'text-gray-800 hover:bg-blue-500 hover:text-white'}`} href="#">
            <FontAwesomeIcon icon={faCamera} className="mr-3 text-xl" />
            <span className="text-xs">Cameras</span>
          </a>
          <a className={`flex items-center p-3 rounded-lg transition-transform transform hover:scale-105 duration-200 ${darkMode ? 'text-gray-200 hover:bg-blue-700 hover:text-white' : 'text-gray-800 hover:bg-blue-500 hover:text-white'}`} href="#">
            <FontAwesomeIcon icon={faChartBar} className="mr-3 text-xl" />
            <span className="text-xs">Global Report</span>
          </a>
          <a className={`flex items-center p-3 rounded-lg transition-transform transform hover:scale-105 duration-200 ${darkMode ? 'text-gray-200 hover:bg-blue-700 hover:text-white' : 'text-gray-800 hover:bg-blue-500 hover:text-white'}`} href="#">
            <FontAwesomeIcon icon={faFileAlt} className="mr-3 text-xl" />
            <span className="text-xs">Saved Documents</span>
          </a>
          <a className={`flex items-center p-3 rounded-lg transition-transform transform hover:scale-105 duration-200 ${darkMode ? 'text-gray-200 hover:bg-blue-700 hover:text-white' : 'text-gray-800 hover:bg-blue-500 hover:text-white'}`} href="#">
            <FontAwesomeIcon icon={faEnvelope} className="mr-3 text-xl" />
            <span className="text-xs">Email</span>
          </a>
          <a className={`flex items-center p-3 rounded-lg transition-transform transform hover:scale-105 duration-200 ${darkMode ? 'text-gray-200 hover:bg-blue-700 hover:text-white' : 'text-gray-800 hover:bg-blue-500 hover:text-white'}`} href="#">
            <FontAwesomeIcon icon={faCog} className="mr-3 text-xl" />
            <span className="text-xs">Settings</span>
          </a>
        </nav>
      </div>
      <div className="p-4">
        <a className={`flex items-center p-3 my-2 rounded-lg transition-transform transform hover:scale-105 duration-200 ${darkMode ? 'text-gray-200 hover:bg-blue-700 hover:text-white' : 'text-gray-800 hover:bg-blue-500 hover:text-white'}`} href="#">
          <FontAwesomeIcon icon={faQuestionCircle} className="mr-3 text-xl" />
          <span className="text-xs">Help</span>
        </a>
        <a className={`flex items-center p-3 my-2 rounded-lg transition-transform transform hover:scale-105 duration-200 ${darkMode ? 'text-gray-200 hover:bg-blue-700 hover:text-white' : 'text-gray-800 hover:bg-blue-500 hover:text-white'}`} href="#">
          <FontAwesomeIcon icon={faCommentDots} className="mr-3 text-xl" />
          <span className="text-xs">Feedback</span>
        </a>
      </div>
    </div>
  );
};

export default Hotel_Sidebar;
