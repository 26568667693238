import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
    backgroundColor: '#f5f5f5',
  },
  section: {
    marginBottom: 15,
    padding: 15,
    backgroundColor: '#ffffff',
    borderRadius: 10,
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
  },
  header: {
    fontSize: 28,
    marginBottom: 20,
    textAlign: 'center',
    color: '#007BFF',
    fontWeight: 'bold',
  },
  subHeader: {
    fontSize: 18,
    marginBottom: 8,
    color: '#333333',
    fontWeight: 'bold',
    borderBottom: '1px solid #dddddd',
    paddingBottom: 5,
  },
  text: {
    fontSize: 14,
    color: '#333333',
    lineHeight: 1.6,
  },
  image: {
    width: '100%',
    height: 'auto',
    marginTop: 10,
    borderRadius: 5,
  },
  footer: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 12,
    color: '#666666',
  },
});

const generateTrendChartData = (filteredData) => {
  const monthlyCounts = filteredData.reduce((acc, item) => {
    const month = new Date(item.DATE).getMonth();
    acc[month] = (acc[month] || 0) + item.RONDE;
    return acc;
  }, {});

  return {
    labels: Array.from({ length: 12 }, (_, i) => `Month ${i + 1}`),
    datasets: [{
      label: 'Rounds',
      data: Array.from({ length: 12 }, (_, i) => monthlyCounts[i] || 0),
      backgroundColor: '#36A2EB',
      borderColor: '#36A2EB',
    }],
  };
};



const generateLocationChartData = (filteredData) => {
  const locations = filteredData.reduce((acc, item) => {
    const location = item.LOCALISATION;
    if (location) {
      acc[location] = (acc[location] || 0) + item.RONDE;
    }
    return acc;
  }, {});

  return {
    labels: Object.keys(locations),
    datasets: [{
      label: 'Rounds',
      data: Object.values(locations),
      backgroundColor: '#36A2EB',
      borderColor: '#36A2EB',
    }],
  };
};

const generateBailleurChartData = (filteredData) => {
  const bailleurs = filteredData.reduce((acc, item) => {
    const bailleur = item.BAILLEURS;
    if (bailleur) {
      acc[bailleur] = (acc[bailleur] || 0) + item.RONDE;
    }
    return acc;
  }, {});

  return {
    labels: Object.keys(bailleurs),
    datasets: [{
      data: Object.values(bailleurs),
      backgroundColor: [
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#FF6384',
        '#36A2EB',
      ],
      datalabels: {
        color: '#ffffff',
        font: {
          weight: 'bold',
        },
        formatter: (value) => value,
      },
    }]
  };
};

const generateBailleurTrendChartData = (filteredData) => {
  const bailleurTrends = filteredData.reduce((acc, item) => {
    const month = new Date(item.DATE).getMonth();
    const bailleur = item.BAILLEURS;
    if (!acc[bailleur]) {
      acc[bailleur] = Array(12).fill(0);
    }
    acc[bailleur][month] += item.RONDE;
    return acc;
  }, {});

  const labels = Array.from({ length: 12 }, (_, i) => `Month ${i + 1}`);
  const datasets = Object.keys(bailleurTrends).map(bailleur => ({
    label: bailleur,
    data: bailleurTrends[bailleur],
    backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16), // Random color for each bailleur
  }));

  return { labels, datasets };
};

const createChartImage = (chartData, chartType, canvas, options = {}) => {
  return new Promise((resolve) => {
    const ctx = canvas.getContext('2d');
    new Chart(ctx, {
      type: chartType,
      data: chartData,
      options: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: true,
          },
        },
        ...options,
      },
      plugins: [ChartDataLabels],
    });
    setTimeout(() => {
      resolve(canvas.toDataURL('image/png'));
    }, 500); // Give some time for the chart to render
  });
};

const PDFReport = ({ filteredData, chartUrls }) => {
  const calculateAverageResponseTime = () => {
    const responseTimes = filteredData
      .filter(item => item['Heures\nDEB'] && item['Heures\nFIN'])
      .map(item => {
        const start = new Date(`1970-01-01T${item['Heures\nDEB']}`);
        const end = new Date(`1970-01-01T${item['Heures\nFIN']}`);
        return (end - start) / 60000; // Convert milliseconds to minutes
      });
    return responseTimes.length ? (responseTimes.reduce((a, b) => a + b, 0) / responseTimes.length).toFixed(2) : 'N/A';
  };

  const calculateMostActiveLocation = () => {
    const locationCounts = filteredData.reduce((acc, item) => {
      const location = item.LOCALISATION;
      if (location) {
        acc[location] = (acc[location] || 0) + item.RONDE;
      }
      return acc;
    }, {});

    return Object.keys(locationCounts).reduce((a, b) => locationCounts[a] > locationCounts[b] ? a : b, '');
  };

  const calculateTotalRounds = () => {
    return filteredData.filter(item => item.RONDE !== null).length;
  };

  const calculateYearlyTrends = () => {
    const monthlyCounts = filteredData.reduce((acc, item) => {
      const month = new Date(item.DATE).getMonth();
      acc[month] = (acc[month] || 0) + item.RONDE;
      return acc;
    }, {});

    return monthlyCounts;
  };

  const generateConclusions = () => {
    const conclusions = [];
    const monthlyCounts = calculateYearlyTrends();

    // Example conclusions
    const highestMonth = Object.keys(monthlyCounts).reduce((a, b) => monthlyCounts[a] > monthlyCounts[b] ? a : b, '');
    if (highestMonth !== '') {
      conclusions.push('The highest number of rounds occurred in month ' + (parseInt(highestMonth) + 1));
    }
    conclusions.push('The average response time is ' + calculateAverageResponseTime() + ' minutes.');
    const mostActiveLocation = calculateMostActiveLocation();
    if (mostActiveLocation) {
      conclusions.push('The most active location is ' + mostActiveLocation + '.');
    }

    return conclusions;
  };

  const currentDateTime = new Date().toLocaleString();

  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.header}>Vacation Dashboard Report</Text>
        
        <View style={styles.section}>
          <Text style={styles.subHeader}>Total Alerts:</Text>
          <Text style={styles.text}>{filteredData.length}</Text>
        </View>
        
        <View style={styles.section}>
          <Text style={styles.subHeader}>Most Active Location:</Text>
          <Text style={styles.text}>{calculateMostActiveLocation()}</Text>
        </View>
        
        <View style={styles.section}>
          <Text style={styles.subHeader}>Total Rounds:</Text>
          <Text style={styles.text}>{calculateTotalRounds()}</Text>
        </View>
        
        <View style={styles.section}>
          <Text style={styles.subHeader}>Average Rounds Duration Time (minutes):</Text>
          <Text style={styles.text}>{calculateAverageResponseTime()}</Text>
        </View>
        
      
        
        <View style={styles.section}>
          <Text style={styles.subHeader}>Monthly Trends:</Text>
          {chartUrls.trendChartUrl && <Image src={chartUrls.trendChartUrl} style={styles.image} />}
        </View>


        <View style={styles.section}>
          <Text style={styles.subHeader}>Top Locations:</Text>
          {chartUrls.locationChartUrl && <Image src={chartUrls.locationChartUrl} style={styles.image} />}
        </View>

        <View style={styles.section}>
          <Text style={styles.subHeader}>Rounds by Bailleur:</Text>
          {chartUrls.bailleurChartUrl && <Image src={chartUrls.bailleurChartUrl} style={styles.image} />}
        </View>

        <View style={styles.section}>
          <Text style={styles.subHeader}>Bailleur Trends:</Text>
          {chartUrls.bailleurTrendChartUrl && <Image src={chartUrls.bailleurTrendChartUrl} style={styles.image} />}
        </View>

        <View style={styles.section}>
          <Text style={styles.subHeader}>Conclusions:</Text>
          {generateConclusions().map((conclusion, index) => (
            <Text key={index} style={styles.text}>{conclusion}</Text>
          ))}
        </View>
        
        <Text style={styles.footer}>Report generated on: {currentDateTime}</Text>
      </Page>
    </Document>
  );
};

const PDFDownloadButton = ({ filteredData }) => {
  const [chartUrls, setChartUrls] = useState({
    trendChartUrl: null,
    interventionTypeChartUrl: null,
    locationChartUrl: null,
    bailleurChartUrl: null,
    bailleurTrendChartUrl: null,
  });

  useEffect(() => {
    const generateCharts = async () => {
      const trendCanvas = document.createElement('canvas');
      trendCanvas.width = 800;
      trendCanvas.height = 400;
      const trendChartData = generateTrendChartData(filteredData);
      const trendChartUrl = await createChartImage(trendChartData, 'bar', trendCanvas);

      const interventionTypeCanvas = document.createElement('canvas');
      interventionTypeCanvas.width = 800;
      interventionTypeCanvas.height = 400;
   


      const locationCanvas = document.createElement('canvas');
      locationCanvas.width = 800;
      locationCanvas.height = 400;
      const locationChartData = generateLocationChartData(filteredData);
      const locationChartUrl = await createChartImage(locationChartData, 'bar', locationCanvas, { indexAxis: 'y' });

      const bailleurCanvas = document.createElement('canvas');
      bailleurCanvas.width = 800;
      bailleurCanvas.height = 400;
      const bailleurChartData = generateBailleurChartData(filteredData);
      const bailleurChartUrl = await createChartImage(bailleurChartData, 'doughnut', bailleurCanvas);

      const bailleurTrendCanvas = document.createElement('canvas');
      bailleurTrendCanvas.width = 800;
      bailleurTrendCanvas.height = 400;
      const bailleurTrendChartData = generateBailleurTrendChartData(filteredData);
      const bailleurTrendChartUrl = await createChartImage(bailleurTrendChartData, 'bar', bailleurTrendCanvas);

      setChartUrls({
        trendChartUrl,
        locationChartUrl,
        bailleurChartUrl,
        bailleurTrendChartUrl,
      });
    };

    generateCharts();
  }, [filteredData]);

  return (
    <PDFDownloadLink
      document={<PDFReport filteredData={filteredData} chartUrls={chartUrls} />}
      fileName="Dashboard_report.pdf"
      style={{
        textDecoration: 'none',
        padding: '10px',
        color: '#fff',
        backgroundColor: '#007BFF',
        border: '1px solid #007BFF',
        borderRadius: '4px',
      }}
    >
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : 'Download PDF Report'
      }
    </PDFDownloadLink>
  );
};

export default PDFDownloadButton;
