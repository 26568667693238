import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  BellAlertIcon,
  MapIcon,
  TvIcon,
  VideoCameraIcon,
  Bars3Icon,
  BuildingOffice2Icon,
} from "@heroicons/react/24/outline";

import avatar2 from "../../assets/img/Magnetoo-logo.jpeg";
import { msalInstance } from "../pages/authConfig";

import { useMsal } from "@azure/msal-react";

import { InteractionRequiredAuthError } from "@azure/msal-browser";

const Navbar = ({ navigate }) => {
  const [time, setTime] = useState(new Date());
  const [userName, setUserName] = useState("");
  const { instance, accounts } = useMsal();
  const [photoURL, setPhotoURL] = useState("");
  const [isScreenBig, setIsScreenBig] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const toggleVisibility = () => {
    setIsMenuVisible((prev) => !prev);
  };

  const getUserPhoto = async (instance, account, setPhotoURL) => {
    try {
      const accessTokenResponse = await instance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: account,
      });

      const accessToken = accessTokenResponse.accessToken;
      const response = await fetch(
        "https://graph.microsoft.com/v1.0/me/photo/$value",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "GET",
          responseType: "blob",
        }
      );

      if (response.ok) {
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        console.log(imageObjectURL);
        setPhotoURL(imageObjectURL);
      } else {
        console.error("Failed to fetch user photo from Microsoft Graph");
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect({
          scopes: ["User.Read"],
          account: account,
        });
      }
      console.error("Error acquiring access token for user photo", error);
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (accounts.length > 0) {
        setUserName(accounts[0].name);
        getUserPhoto(instance, accounts[0], setPhotoURL);
      } else {
        try {
          const loginResponse = await instance.loginPopup({
            scopes: ["openid", "profile", "email"],
          });
          if (loginResponse.account) {
            setUserName(loginResponse.account.name);
          }
        } catch (error) {
          console.error("Login failed", error);
        }
      }
    };

    fetchUserDetails();

    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Cleanup function to clear the timer when the component unmounts
    return () => clearInterval(timer);
  }, [instance, accounts]);

  const handleLogout = () => {
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: "/login",
    });
  };

  useEffect(() => {
    const handleScreenSizeChange = () => {
      if (window.innerWidth >= 1240) {
        setIsScreenBig(true);
      } else {
        setIsScreenBig(false);
      }
    };
    window.addEventListener("resize", handleScreenSizeChange);
    window.addEventListener("load", handleScreenSizeChange);

    return () => {
      window.removeEventListener("resize", handleScreenSizeChange);
      window.removeEventListener("load", handleScreenSizeChange);
    };
  }, []);

  return (
    <div
      className="flex w-full items-center justify-between px-4 sm:px-6 lg:px-8 bg-dark-blue rounded-lg p-4 sm:p-8 gap-x-4"
      style={{ backgroundColor: "#0a1128", borderColor: "#001F54" }}
    >
       <motion.div
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        className="flex items-center justify-end cursor-pointer w-full"
      >
        <p className="text-lg font-bold text-blue-300">Magnetoo</p>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
          src={avatar2}
          className="w-10 h-10 rounded-full"
          alt="Magnetoo Logo"
        />
      </motion.div>
      <motion.div className="relative">
        <motion.button onClick={toggleVisibility}>
          <Bars3Icon class="h-8 w-8 text-blue-300" />
        </motion.button>
        {isMenuVisible && (
          <motion.div className="absolute z-[1100] top-9 right-1 w-80 min-h-64 bg-gray-800 rounded">
            <div className="flex flex-row-reverse items-center bg-gray-900 justify-between w-full space-x-2 py-4 px-2">
              <motion.img
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                src={photoURL}
                className="w-8 h-8 rounded-full"
                alt="User Avatar"
              />
              <motion.div
                initial={{ x: -10, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <p className="text-sm font-bold mr-4 text-slate-200">{userName}</p>
              </motion.div>
              <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              onClick={handleLogout}
              className="px-4 py-2 rounded-md bg-blue-700 hover:bg-blue-600 transition duration-150 ease-in-out"
            >
              Logout
            </motion.button>
            </div>
            <NavItem
              icon={
                <TvIcon className="text-blue-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
              }
              title="Main"
              navigate={navigate}
              to="/main"
            />
            <NavItem
              icon={
                <VideoCameraIcon className="text-blue-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
              }
              title="Cameras"
              navigate={navigate}
              to="/cameras"
            />
            <NavItem
              icon={
                <BellAlertIcon className="text-blue-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
              }
              title="Alerts"
              navigate={navigate}
              to="/alerts"
            />
            <NavItem
              icon={
                <MapIcon className="text-blue-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
              }
              title="Map"
              navigate={navigate}
              to="/map"
            />
            <NavItem
         
              title="Guests Managment"
              navigate={navigate}
              to="/GuestsManagment"
              icon={
                <BuildingOffice2Icon className="text-pink-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
              }
            />
            <NavItem
         
         title="Financial KPI"
         navigate={navigate}
         to="/financial_KPIs"
         icon={
           <BuildingOffice2Icon className="text-pink-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
         }
       />
          <NavItem
         
         title="Elv Systems "
         navigate={navigate}
         to="/Elv_systems"
         icon={
           <BuildingOffice2Icon className="text-pink-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
         }
       />
          <NavItem
         
         title="Main Retail"
         navigate={navigate}
         to="/Main_Retail"
         icon={
           <BuildingOffice2Icon className="text-green-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
         }
       />
           <NavItem
         
         title="Store Sales"
         navigate={navigate}
         to="/store_sales"
         icon={
           <BuildingOffice2Icon className="text-green-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
         }
       />
         <NavItem
              icon={
                <BellAlertIcon className="text-yellow-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
              }
              title="Parking"
              navigate={navigate}
              to="/Parking_Dashboard"
            />
            <NavItem
              icon={
                <BellAlertIcon className="text-red-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
              }
              title="Drilling Dashboard"
              navigate={navigate}
              to="/DrillingDashboard"
            />
              <NavItem
              icon={
                <BellAlertIcon className="text-orange-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
              }
              title="Call Center"
              navigate={navigate}
              to="/CallCenter"
            />
                    <NavItem
              icon={
                <BellAlertIcon className="text-yellow-300 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
              }
              title="Vacation"
              navigate={navigate}
              to="/Vacation_dashboard"
            />
            <NavItem
              icon={
                <BuildingOffice2Icon className="text-blue-100 w-8 sm:w-10 lg:w-8 hover:text-blue-500 transition duration-300 ease-in-out" />
              }
              title="Alain_Dashboard"
              navigate={navigate}
              to="/overview"
            />
          </motion.div>
          
        )}
      </motion.div>
     
    </div>
  );
};

const NavItem = ({ icon, title, navigate, to }) => (
  <motion.div
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: 0.6 }}
    className="flex flex-row-reverse items-center text-white cursor-pointer hover:text-blue-400 text-bold p-3"
    onClick={() => navigate(to)}
  >
    {icon}
    <span className="ml-2 mr-2 text-sm font-bold">{title}</span>
  </motion.div>
);

export default Navbar;
