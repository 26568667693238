import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faFire, faPlug, faDatabase, faDownload, faClock, faBell, faWrench } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SummaryCard from '../../charts/Alain_charts/SummaryCards';
import LineSiteChart from '../../charts/Alain_charts/LineSiteChart';
import DowntimeBarChart from '../../charts/Alain_charts/DownTimeChart';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import ComparisonCard from '../../charts/Alain_charts/VsCard';

const siteData = [
  {
    id: 1,
    name: 'Site A',
    details: {
      totalAlarms: 120,
      severeAlarms: 5,
      totalDowntime: 10,
      lastMaintenance: '2024-07-15',
      resolutionTimeline: 3,
      dataUsage: 150, // in MB
    },
    historicalData: [
      { date: '2024-07-10', alarms: 20, downtime: 2 },
      { date: '2024-07-11', alarms: 30, downtime: 3 },
      { date: '2024-07-12', alarms: 10, downtime: 4 },
      { date: '2024-07-13', alarms: 40, downtime: 5 },
      { date: '2024-07-14', alarms: 20, downtime: 6 },
    ],
  },
  {
    id: 2,
    name: 'Site B',
    details: {
      totalAlarms: 80,
      severeAlarms: 2,
      totalDowntime: 5,
      lastMaintenance: '2024-07-12',
      resolutionTimeline: 2,
      dataUsage: 200, // in MB
    },
    historicalData: [
      { date: '2024-07-10', alarms: 10, downtime: 1 },
      { date: '2024-07-11', alarms: 20, downtime: 2 },
      { date: '2024-07-12', alarms: 15, downtime: 1 },
      { date: '2024-07-13', alarms: 25, downtime: 0.5 },
      { date: '2024-07-14', alarms: 10, downtime: 0.5 },
    ],
  },
];


const siteWithMostAlarms = {
  name: 'Site B',
  alarmCount: 150,
};

const siteWithMostSevereAlarm = {
  name: 'Site C',
  severity: 'Critical',
};

const siteWithMostDisconnections = {
  name: 'Site A',
  disconnectionCount: 30,
};

const siteWithMostDataUsage = {
  name: 'Site D',
  dataUsage: 500, // in MB
};

const siteConnectivityData = {
  labels: ['Site A', 'Site B', 'Site C', 'Site D', 'Site E'],
  datasets: [
    {
      label: 'Connected',
      data: [80, 90, 70, 85, 95],
      backgroundColor: '#4caf50',
    },
    {
      label: 'Disconnected',
      data: [20, 10, 30, 15, 5],
      backgroundColor: '#f44336',
    },
  ],
};

const downtimeData = {
  labels: ['Site A', 'Site B', 'Site C', 'Site D', 'Site E', 'Site F'],
  values: [5, 8, 2, 10, 6, 7], // Downtime in hours for each site
};

const exportPDF = () => {
  const doc = new jsPDF();
  doc.text('Overall Insights', 20, 10);
  doc.autoTable({
    head: [['Metric', 'Value']],
    body: [
      ['Site with Most Alarms', `${siteWithMostAlarms.name}: ${siteWithMostAlarms.alarmCount} Total Alarms`],
      ['Site with Most Severe Alarm', `${siteWithMostSevereAlarm.name}: ${siteWithMostSevereAlarm.severity}`],
      ['Site with Most Disconnections', `${siteWithMostDisconnections.name}: ${siteWithMostDisconnections.disconnectionCount} Total Disconnections`],
      ['Site Using Most Data', `${siteWithMostDataUsage.name}: ${siteWithMostDataUsage.dataUsage} MB`],
    ],
  });
  doc.save('overall_insights.pdf');
};

const Departments = () => {
  const [hoveredSite, setHoveredSite] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (event, site) => {
    setHoveredSite(site);
    setTooltipPosition({ top: event.clientY, left: event.clientX });
  };

  const handleMouseLeave = () => {
    setHoveredSite(null);
  };

  return (
    <div className="min-h-screen p-6 bg-gray-50 ml-64 mr-4 relative">
      {/* Title and Export Button */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-semibold">Overall Insights</h2>
      </div>

      {/* Summary and Horizontal Bar Chart Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-10">
        {/* Summary Cards Container */}
        <div className="bg-white rounded-lg shadow-lg p-4 col-span-2 transform hover:scale-105 transition duration-300">
          <div className="flex justify-between items-center mb-6">
            <div className="flex flex-col">
              <h2 className="text-xl font-semibold text-left text-blue-900">Overall Insights</h2>
              <p className="text-xs font-semibold text-left ml-1 text-blue-300">Overview of the key metrics</p>
            </div>
            <button
              onClick={exportPDF}
              className="bg-blue-500 text-white px-3 py-1 rounded-md shadow-md hover:bg-blue-600 transition duration-300 flex items-center"
            >
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              Export
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <SummaryCard
              icon={faExclamationTriangle}
              title={<span onMouseEnter={(e) => handleMouseEnter(e, siteData[0])} onMouseLeave={handleMouseLeave}>Site with Most Alarms</span>}
              value={`${siteWithMostAlarms.name}: ${siteWithMostAlarms.alarmCount}`}
              description="+5% from last month"
              bgColor="bg-pink-100"
              textColor="text-pink-500"
            />
            <SummaryCard
              icon={faFire}
              title={<span onMouseEnter={(e) => handleMouseEnter(e, siteData[1])} onMouseLeave={handleMouseLeave}>Site with Most Severe Alarm</span>}
              value={`${siteWithMostSevereAlarm.name}: ${siteWithMostSevereAlarm.severity}`}
              description="+3% from last month"
              bgColor="bg-yellow-100"
              textColor="text-yellow-500"
            />
            <SummaryCard
              icon={faPlug}
              title={<span onMouseEnter={(e) => handleMouseEnter(e, siteData[0])} onMouseLeave={handleMouseLeave}>Site with Most Disconnections</span>}
              value={`${siteWithMostDisconnections.name}: ${siteWithMostDisconnections.disconnectionCount}`}
              description="+2% from last month"
              bgColor="bg-green-100"
              textColor="text-green-500"
            />
            <SummaryCard
              icon={faDatabase}
              title={<span onMouseEnter={(e) => handleMouseEnter(e, siteData[1])} onMouseLeave={handleMouseLeave}>Site Using Most Data</span>}
              value={`${siteWithMostDataUsage.name}: ${siteWithMostDataUsage.dataUsage} MB`}
              description="+8% from last month"
              bgColor="bg-purple-100"
              textColor="text-purple-500"
            />
          </div>
        </div>

        {/* Line Chart */}
        <div className="bg-white rounded-lg shadow-lg p-4 transform hover:scale-105 transition duration-300">
          <div className="flex flex-col">
            <h2 className="text-xl font-semibold text-left text-blue-900">Overall Insights</h2>
            <p className="text-xs font-semibold text-left ml-1 text-blue-300">Overview of the key metrics</p>
          </div>
          {siteConnectivityData && siteConnectivityData.labels ? (
            <LineSiteChart title="Site Connectivity Status" data={siteConnectivityData} height={250} />
          ) : (
            <p>No data available for Site Connectivity Status</p>
          )}
        </div>
      </div>

      {/* Additional Summary Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-10">
        <SummaryCard
          icon={faClock}
          title="Average Response Time"
          value="5 mins"
          description="Average time to respond to alarms"
          bgColor="bg-blue-100"
          textColor="text-blue-500"
        />
        <SummaryCard
          icon={faBell}
          title="Total Active Alarms"
          value="45"
          description="Current number of active alarms"
          bgColor="bg-red-100"
          textColor="text-red-500"
        />
        <SummaryCard
          icon={faWrench}
          title="Maintenance Status"
          value="2 sites under maintenance"
          description="Current maintenance activities"
          bgColor="bg-gray-100"
          textColor="text-gray-500"
        />
      </div>

      {/* Additional Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-10">
        <div className="bg-white rounded-lg shadow-lg p-4 transform hover:scale-105 transition duration-300">
          <ComparisonCard sites={siteData} />
        </div>
        <div className="bg-white rounded-lg shadow-lg p-4 transform hover:scale-105 transition duration-300">
          <DowntimeBarChart title="Performance Metrics" data={downtimeData} />
        </div>
      </div>
    </div>
  );
};

export default Departments;
