import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faDoorOpen, faNetworkWired, faSignal, faWifi, faMobileScreen, faKey } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import DonutChart from '../../charts/Alain_charts/SitePieChart';
import BarChartComponentAa from '../../charts/Alain_charts/BarChartAlain';
import { FaRegChartBar } from 'react-icons/fa';
import HorizontalBarChartComponent from '../../charts/Alain_charts/HbarchartAlain';
import GsmDataComponent from './GsmDataConsumption';
import RadialBarChart from '../../charts/Alain_charts/SitePieChart';
import PieChart from '../../charts/Alain_charts/SitePieChart';
import RgbDonutChart from '../../charts/Alain_charts/DonutRgbAlarmsChart';

const dataPie = [680, 20];
const siteConnectivityData = {
  labels: ['Site A', 'Site B', 'Site C', 'Site D', 'Site E'],
  datasets: [
    {
      label: 'Connected',
      data: [80, 90, 70, 85, 95],
      backgroundColor: '#4caf50',
    },
    {
      label: 'Disconnected',
      data: [20, 10, 30, 15, 5],
      backgroundColor: '#f44336',
    },
  ],
};
const performanceMetricsData = {
  labels: ['CPU Usage', 'Memory Usage', 'Disk Usage', 'Network Traffic', 'I/O Operations', 'Latency'],
  datasets: [
    {
      label: 'Usage (%)',
      data: [70, 65, 80, 75, 60, 50],
      backgroundColor: '#2196f3',
    },
  ],
};

const gsmData = {
  currentUsage: 500, // in MB
  totalUsage: 1000, // in MB
  remaining: 300 // in MB
};
const alarmSeverityData = {
  labels: ['Critical', 'High', 'Medium', 'Low', 'Informational','combined'],
  datasets: [
    {
      label: 'Alarms',
      data: [50, 100, 200, 150, 250,40],
      backgroundColor: '#f44336',
    },
  ],
};
const mostDataSite = {
  name: 'Site A',
  usage: 300,
};

const alarmCorrelationData = {
  labels: ['VMS-ACS Correlation', 'VMS-FW Correlation', 'ACS-FW Correlation', 'VMS-KMS Correlation', 'ACS-KMS Correlation'],
  datasets: [
    {
      label: 'Correlated Alarms',
      data: [150, 120, 130, 110, 100],
      backgroundColor: '#ff9800',
    },
  ],
};

const Overview = () => {
  return (
    <div className="min-h-screen p-8 bg-gray-50 ml-64 mr-4">
      <h2 className='text-left mb-2'>OverView </h2>
      {/* Summary Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mb-8">
        {/* Total Sites */}
        <div className="bg-white rounded-lg shadow-sm flex items-center justify-between p-3 transform hover:scale-105 transition duration-300">
          <div className="text-blue-600 text-xl p-2 bg-blue-100 rounded-full">
            <FontAwesomeIcon icon={faNetworkWired} />
          </div>
          <div className="flex-1 ml-4">
            <h2 className="text-sm font-semibold text-gray-600">Total Sites</h2>
            <p className="text-lg font-bold text-gray-800">700</p>
          </div>
        </div>

        {/* Connected Sites */}
        <div className="bg-white rounded-lg shadow-sm flex items-center justify-between p-3 transform hover:scale-105 transition duration-300">
          <div className="text-green-600 text-xl p-2 bg-green-100 rounded-full">
            <FontAwesomeIcon icon={faSignal} />
          </div>
          <div className="flex-1 ml-4">
            <h2 className="text-sm font-semibold text-gray-600">Connected Sites</h2>
            <p className="text-lg font-bold text-gray-800">680</p>
          </div>
        </div>

        {/* Disconnected Sites */}
        <div className="bg-white rounded-lg shadow-sm flex items-center justify-between p-3 transform hover:scale-105 transition duration-300">
          <div className="text-red-600 text-xl p-2 bg-red-100 rounded-full">
            <FontAwesomeIcon icon={faSignal} />
          </div>
          <div className="flex-1 ml-4">
            <h2 className="text-sm font-semibold text-gray-600">Disconnected Sites</h2>
            <p className="text-lg font-bold text-gray-800">20</p>
          </div>
        </div>

        {/* Total Cameras */}
        <div className="bg-white rounded-lg shadow-sm flex items-center justify-between p-3 transform hover:scale-105 transition duration-300">
          <div className="text-purple-600 text-xl p-2 bg-purple-100 rounded-full">
            <FontAwesomeIcon icon={faCamera} />
          </div>
          <div className="flex-1 ml-4">
            <h2 className="text-sm font-semibold text-gray-600">Total Cameras</h2>
            <p className="text-lg font-bold text-gray-800">1400</p>
          </div>
        </div>

        {/* Total ACS Doors */}
        <div className="bg-white rounded-lg shadow-sm flex items-center justify-between p-3 transform hover:scale-105 transition duration-300">
          <div className="text-yellow-600 text-xl p-2 bg-yellow-100 rounded-full">
            <FontAwesomeIcon icon={faDoorOpen} />
          </div>
          <div className="flex-1 ml-4">
            <h2 className="text-sm font-semibold text-gray-600">Total ACS Doors</h2>
            <p className="text-lg font-bold text-gray-800">700</p>
          </div>
        </div>
      </div>

      {/* Status Section */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-12">
        {/* Site Connectivity */}
        <div className="bg-white rounded-lg shadow-md p-4 transform hover:scale-105 transition duration-300">
          <div className='flex flex-row'>
            <FontAwesomeIcon icon={faWifi} className='w-8 h-8 mr-4 text-blue-500' />
            <h2 className="text-2xl font-semibold text-gray-700 mt-2">Site Connectivity Status</h2>
          </div>
          <DonutChart data={dataPie} />
        </div>
        
        {/* GSM Module Status */}
        <div className="bg-white rounded-lg shadow-md p-6 transform hover:scale-105 transition duration-300">
      <div className="flex items-center mb-6">
        <FontAwesomeIcon icon={faMobileScreen} className="w-8 h-8 mr-4 text-blue-500" />
        <h2 className="text-2xl font-semibold text-gray-700 mt-2">GSM Module Status</h2>
      </div>

      <div className="flex items-center mb-4">
        <FontAwesomeIcon icon={faSignal} className="w-6 h-6 mr-3 text-gray-600" />
        <span className="text-lg font-semibold text-gray-600">Operational Status:</span>
        <span className="ml-2 text-xl font-bold text-green-600">Operational</span>
      </div>
      <div className="text-lg text-gray-600 flex items-center mb-4">
        <FontAwesomeIcon icon={faNetworkWired} className="w-6 h-6 mr-3 text-gray-600" />
        <p>Last Communication: <span className="font-semibold">10 minutes ago</span></p>
      </div>
      <div className="text-lg text-gray-600 flex items-center mb-4">
        <FontAwesomeIcon icon={faWifi} className="w-6 h-6 mr-3 text-gray-600" />
        <p>Signal Strength: <span className="font-semibold">Excellent</span></p>
      </div>
      <div className="relative h-8 mt-4 mb-4">
        <div className="overflow-hidden h-3 rounded bg-blue-200">
          <div style={{ width: '90%' }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
        </div>
      </div>
      <div className="text-lg text-gray-600 flex items-center mb-4">
        <FontAwesomeIcon icon={faMobileScreen} className="w-6 h-6 mr-3 text-gray-600" />
        <p>Connection Type: <span className="font-semibold">GSM</span></p>
      </div>
      <div className="text-lg text-gray-600 flex items-center mb-4">
        <FontAwesomeIcon icon={faKey} className="w-6 h-6 mr-3 text-gray-600" />
        <p>Encryption: <span className="font-semibold">Enabled</span></p>
      </div>
    </div>
        <GsmDataComponent gsmData={gsmData} mostDataSite={mostDataSite} />
      </div>

      {/* Additional Insights */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mt-4">
        {/* Bar Charts */}
        <BarChartComponentAa title="Site Connectivity Status" data={siteConnectivityData} color="rgba(75, 192, 192, 0.6)" icon={<FaRegChartBar className="text-gray-700" />} />
        <BarChartComponentAa title="Alarm Severity Distribution" data={alarmSeverityData} color="rgba(75, 192, 192, 0.6)" icon={<FaRegChartBar className="text-gray-700" />} />
        <BarChartComponentAa title="Performance Metrics" data={performanceMetricsData} color="rgba(75, 192, 192, 0.6)" icon={<FaRegChartBar className="text-gray-700" />} />
        <HorizontalBarChartComponent title="Alarm Correlation Overview" data={alarmCorrelationData} color="rgba(245, 40, 145, 0.8)" icon={<FaRegChartBar className="text-gray-700" />} />
      </div>
    </div>
  );
};

export default Overview;
