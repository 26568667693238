import React from 'react';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router';
import Draggable from 'react-draggable';

import TicketAreachart from '../../charts/ticket_charts/TicketAreachart';
import GuestsDistributionPieChart from '../../charts/ticket_charts/GuestsDistributionpiechart';
import ServicesDistirbution from '../../charts/ticket_charts/ServicesDistirbutionpiechart';
import TotalPersoninout from '../../charts/ticket_charts/TotalPersoninout';
import HotelChart from '../../charts/ticket_charts/HotelOccbarchart';
const GuestsManagment = () => {
  const navigate = useNavigate();

  
  return (
    <div className="bg-black p-2 ">
      <Navbar navigate={navigate} />
      <br />
      <div className="grid grid-cols-12 gap-6 w-full">
        {/* The first two divs */}
        <div className="col-span-12 sm:col-span-3 h-36 bg-gradient-to-r from-purple-400 via-purple-500 to-purple-200 rounded-lg relative">
  <p className="absolute top-0 left-0 text-white p-4">Avg length of stay</p>
  <p className="absolute bottom-0 left-1/4 transform -translate-x-1/2 text-white p-4 text-7xl flex items-center">
    <span class="mr-1">1</span>
    <span className="text-sm">day</span>
  </p>
  <p className="absolute bottom-0 right-10 text-white p-4 text-7xl flex items-center">
    <span class="mr-1">89</span>
    <span className="text-sm">minutes</span>
  </p>
</div>


<div className="col-span-12 sm:col-span-3 h-36 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% rounded-lg relative flex justify-center items-center">
  <p className="absolute top-0 left-0 text-white p-4">Avg Daily Spending</p>
  <div className="absolute inset-0 flex justify-center items-center">
    <p className="text-white p-4 text-7xl flex items-center mt-8">
      <span class="mr-1">160</span>
      <span className="text-sm">USD</span>
    </p>
  </div>
</div>




        {/* Smaller divs */}
        <div className="col-span-12 sm:col-span-2">
        <div className="h-16 bg-gray-900 mb-4 rounded-full flex items-center justify-center relative">
  <p className='text-white absolute left-7 pl-1 text-lg'>12</p>
  <p className='text-white text-xs'>Repeat Customers</p>
  <div className="absolute right-5 bg-green-900 text-white px-1 py-1 text-xs">+20%</div>
</div>


<div className="h-16 bg-gray-900 mb-4 rounded-full flex items-center justify-center relative">
  <p className='text-white absolute left-7 pl-1 text-lg'>50</p>
  <p className='text-white text-xs'>Repeat Service</p>
  <div className="absolute right-5 bg-green-900 text-white px-1 py-1 text-xs">+7%</div>
</div>
        </div>
        

        {/* div4 and div5, staying in the first row */}
        <div className="col-span-12 sm:col-span-4 row-span-2 ">
          <div className="h-96 bg-gray-900 rounded-lg mb-4"><TicketAreachart/></div>
          <div className="h-[31.5rem] bg-gray-900 rounded-lg">
            <HotelChart/>
          </div>
        </div>

        {/* New big div below the first two divs and the smaller divs, across the bottom */}
        <div className="col-span-12 sm:col-span-8  ">
          <div className="col-span-12 sm:col-span-2 row-start-2 bg-gray-900 rounded-lg h-96 mb-4">
            <TotalPersoninout/>
          </div>
          <div className="col-span-12 sm:col-span-2 sm:grid sm:grid-cols-2 sm:gap-6">
            <div className="bg-gray-900 rounded-lg h-80 mb-4">
           < GuestsDistributionPieChart/>
            </div>
            <div className="bg-gray-900 rounded-lg h-80">
              <ServicesDistirbution/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuestsManagment;
