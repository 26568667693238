import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import { FaDoorOpen, FaExclamationTriangle, FaFileCsv, FaPrint, FaSearch } from 'react-icons/fa';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router';
import ExitsPerHourChart from '../../charts/Parking_charts/ExitsChart';
import AvgDurationPerHourChart from '../../charts/Parking_charts/AvgDurationChart';
import EntriesPerHourChart from '../../charts/Parking_charts/EntriesChart';

const socket = io('https://magnetoo.fr');

function ParkingDashboard() {
    const [entriesData, setEntriesData] = useState([]);
    const navigate = useNavigate();
    const [exitsData, setExitsData] = useState([]);
    const [durationData, setDurationData] = useState([]);
    const [entriesPerHourData, setEntriesPerHourData] = useState([]);
    const [exitsPerHourData, setExitsPerHourData] = useState([]);
    const [avgDurationPerHourData, setAvgDurationPerHourData] = useState([]);
    const [carCount, setCarCount] = useState(0);
    const [currentCars, setCurrentCars] = useState([]);
    const [carEntries, setCarEntries] = useState([]);
    const [carExits, setCarExits] = useState([]);
    const [alertValue, setAlertValue] = useState(1);
    const [alertUnit, setAlertUnit] = useState('days');
    const [alertResults, setAlertResults] = useState([]);
    const [searchPlate, setSearchPlate] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [entryFilterStart, setEntryFilterStart] = useState(null);
    const [entryFilterEnd, setEntryFilterEnd] = useState(null);
    const [exitFilterStart, setExitFilterStart] = useState(null);
    const [exitFilterEnd, setExitFilterEnd] = useState(null);
    const [alertStartTime, setAlertStartTime] = useState(null);
    const [newEntriesAfterAlert, setNewEntriesAfterAlert] = useState([]);

    const fetchData = async (entryStart, entryEnd, exitStart, exitEnd) => {
        try {
            const carCountResponse = await axios.get('https://magnetoo.fr/car-count');
            setCarCount(carCountResponse.data.count);
            setCurrentCars(carCountResponse.data.plates.split(','));

            const carEntriesResponse = await axios.get('https://magnetoo.fr/car-entries', {
                params: { start: entryStart, end: entryEnd }
            });
            const entries = carEntriesResponse.data.map(entry => [new Date(entry.entry_time).getTime(), 1]);
            setEntriesData(entries);
            setCarEntries(carEntriesResponse.data);

            const carExitsResponse = await axios.get('https://magnetoo.fr/car-exits', {
                params: { start: exitStart, end: exitEnd }
            });
            const exits = carExitsResponse.data.map(exit => [new Date(exit.exit_time).getTime(), 1]);
            const duration = carExitsResponse.data.map(exit => [new Date(exit.exit_time).getTime(), exit.duration]);
            setExitsData(exits);
            setDurationData(duration);
            setCarExits(carExitsResponse.data);

            const entriesPerHourResponse = await axios.get('https://magnetoo.fr/car-entries-per-hour');
            const entriesPerHour = entriesPerHourResponse.data.map(item => [new Date(item.entry_hour).getTime(), item.number_of_entries]);
            setEntriesPerHourData(entriesPerHour);

            const exitsPerHourResponse = await axios.get('https://magnetoo.fr/car-exits-per-hour');
            const exitsPerHour = exitsPerHourResponse.data.map(item => [new Date(item.exit_hour).getTime(), item.number_of_exits]);
            setExitsPerHourData(exitsPerHour);

            const avgDurationPerHourResponse = await axios.get('https://magnetoo.fr/average-duration-per-hour');
            const avgDurationPerHour = avgDurationPerHourResponse.data.map(item => [new Date(item.exit_hour).getTime(), item.avg_duration_seconds]);
            setAvgDurationPerHourData(avgDurationPerHour);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData(entryFilterStart, entryFilterEnd, exitFilterStart, exitFilterEnd);
        const intervalId = setInterval(() => {
            fetchData(entryFilterStart, entryFilterEnd, exitFilterStart, exitFilterEnd);
            checkAlerts();
        }, 6000);

        socket.on('carCountUpdate', data => {
            setCarCount(data.count);
            setCurrentCars(data.plates.split(','));
        });

        socket.on('newCarEntry', data => {
            if (alertStartTime && new Date(data.entry_time).getTime() > alertStartTime) {
                setNewEntriesAfterAlert(prevEntries => [...prevEntries, data]);
            }
        });

        socket.on('alertUpdate', data => {
            setAlertResults(prevResults => [...prevResults, ...data]);
        });

        return () => {
            clearInterval(intervalId);
            socket.off('carCountUpdate');
            socket.off('newCarEntry');
            socket.off('alertUpdate');
        };
    }, [entryFilterStart, entryFilterEnd, exitFilterStart, exitFilterEnd]);

    const checkAlerts = () => {
        if (!alertStartTime) return;
        const now = new Date().getTime();
        const alertDuration = alertValue * (alertUnit === 'days' ? 86400000 : alertUnit === 'hours' ? 3600000 : 60000);
        const newAlerts = newEntriesAfterAlert.filter(entry => {
            const entryTime = new Date(entry.entry_time).getTime();
            return now - entryTime > alertDuration;
        });
        setAlertResults(prevResults => [...prevResults, ...newAlerts]);
        setNewEntriesAfterAlert(prevEntries => prevEntries.filter(entry => !newAlerts.includes(entry)));
    };

    const handleSetAlert = () => {
        setAlertStartTime(new Date().getTime());
        setNewEntriesAfterAlert([]);
        setAlertResults([]);
        alert('Alerte définie avec succès.');
    };

    const handleStopAlert = () => {
        setAlertStartTime(null);
        setNewEntriesAfterAlert([]);
        setAlertResults([]);
        alert('Alerte arrêtée avec succès.');
    };

    const handleExportCSV = () => {
        axios.get('https://magnetoo.fr/export-csv')
            .then(response => {
                alert('CSV exporté avec succès.');
            })
            .catch(error => console.error('Erreur lors de l\'exportation du CSV:', error));
    };

    const handleSearchPlate = () => {
        axios.post('https://magnetoo.fr/search-plate', { plate: searchPlate })
            .then(response => setSearchResults(response.data))
            .catch(error => console.error('Erreur lors de la recherche de la plaque:', error));
    };

    const handleEntryFilter = () => {
        fetchData(entryFilterStart, entryFilterEnd, exitFilterStart, exitFilterEnd);
    };

    const handleExitFilter = () => {
        fetchData(entryFilterStart, entryFilterEnd, exitFilterStart, exitFilterEnd);
    };

    const handlePrintPlates = () => {
        axios.post('https://magnetoo.fr/print-plates')
            .then(response => {
                alert('Impression des plaques: ' + response.data.plates);
            })
            .catch(error => console.error('Erreur lors de l\'impression des plaques:', error));
    };

    return (
        <div className="bg-black min-h-screen p-4">
            <Navbar navigate={navigate} />
            <br />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="relative bg-gradient-to-r from-indigo-600 to-purple-800 p-6 text-white h-48 rounded-lg shadow-lg transform hover:scale-105 transition duration-500 ease-in-out flex items-center justify-center">
                    <div className="absolute inset-0 flex items-center justify-center opacity-20">
                        <svg width="100%" height="100%">
                            <circle cx="30%" cy="50%" r="10%" fill="rgba(255, 255, 255, 0.1)" />
                            <rect x="50%" y="40%" width="15%" height="20%" fill="rgba(255, 255, 255, 0.1)" />
                            <ellipse cx="70%" cy="50%" rx="10%" ry="5%" fill="rgba(255, 255, 255, 0.1)" />
                        </svg>
                    </div>
                    <div className="relative z-10 flex flex-col items-center">
                        <h2 className="text-5xl font-bold text-yellow-500 mb-2">
                            {carCount}
                        </h2>
                        <p className="text-xl font-medium text-gray-400">Voitures dans le parking</p>
                    </div>
                </div>

                <div className="bg-gray-900 p-4 text-white h-42 rounded-lg">
                    <div className="bg-gray-800 p-4 rounded-lg shadow-md">
                        <h3 className="text-sm font-semibold mb-4 text-white flex items-center">
                            <FaFileCsv className="mr-2" /> Exporter CSV
                        </h3>
                        <button onClick={handleExportCSV} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg">
                            Exporter
                        </button>
                    </div>
                </div>
                <div className="bg-gray-900 p-4 text-white h-42 rounded-lg">
                    <div className="bg-gray-800 p-4 rounded-lg shadow-md">
                        <h3 className="text-sm font-semibold mb-4 text-white flex items-center">
                            <FaPrint className="mr-2" /> Afficher les plaques
                        </h3>
                        <button onClick={handlePrintPlates} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-lg flex items-center">
                            Imprimer les plaques
                        </button>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                <EntriesPerHourChart data={entriesPerHourData} />
                <ExitsPerHourChart data={exitsPerHourData} />
                <AvgDurationPerHourChart data={avgDurationPerHourData} />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <div className="bg-gray-900 p-4 text-white rounded-lg">
                    <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
                        <FaDoorOpen className="mr-2" /> Entrées de voitures
                    </h3>
                    <div className="mb-4">
                        <label className="block text-lg mb-2">Date de début :</label>
                        <Datetime
                            value={entryFilterStart}
                            onChange={date => setEntryFilterStart(date)}
                            className="bg-gray-300 text-black rounded px-3 py-2 mb-3 w-full text-sm"
                        />
                        <label className="block text-lg mb-2">Date de fin :</label>
                        <Datetime
                            value={entryFilterEnd}
                            onChange={date => setEntryFilterEnd(date)}
                            className="bg-gray-300 text-black rounded px-3 py-2 w-full text-sm"
                        />
                        <button onClick={handleEntryFilter} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mb-4 w-full">
                            Filtrer
                        </button>
                    </div>
                    <div className="overflow-y-auto max-h-64">
                        <table className="table-auto w-full text-left">
                            <thead>
                                <tr>
                                    <th className="px-2 py-2 border-b border-gray-700">Plaque</th>
                                    <th className="px-2 py-2 border-b border-gray-700">Heure d'entrée</th>
                                </tr>
                            </thead>
                            <tbody>
                                {carEntries.map((entry, index) => (
                                    <tr key={index} className="hover:bg-gray-700">
                                        <td className="px-2 py-2 border-b border-gray-700">{entry.plate}</td>
                                        <td className="px-2 py-2 border-b border-gray-700">{new Date(entry.entry_time).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="bg-gray-900 p-4 text-white rounded-lg">
                    <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
                        <FaDoorOpen className="mr-2" /> Voitures sorties
                    </h3>
                    <div className="mb-4">
                        <label className="block text-lg mb-2">Date de début :</label>
                        <Datetime
                            value={exitFilterStart}
                            onChange={date => setExitFilterStart(date)}
                            className="bg-gray-300 text-black rounded px-3 py-2 mb-3 w-full text-sm"
                        />
                        <label className="block text-lg mb-2">Date de fin :</label>
                        <Datetime
                            value={exitFilterEnd}
                            onChange={date => setExitFilterEnd(date)}
                            className="bg-gray-300 text-black rounded px-3 py-2 w-full text-sm"
                        />
                        <button onClick={handleExitFilter} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mb-4 w-full">
                            Filtrer
                        </button>
                    </div>
                    <div className="overflow-y-auto max-h-64">
                        <table className="table-auto w-full text-left">
                            <thead>
                                <tr>
                                    <th className="px-2 py-2 border-b border-gray-700">Plaque</th>
                                    <th className="px-2 py-2 border-b border-gray-700">Heure d'entrée</th>
                                    <th className="px-2 py-2 border-b border-gray-700">Heure de sortie</th>
                                    <th className="px-2 py-2 border-b border-gray-700">Durée</th>
                                </tr>
                            </thead>
                            <tbody>
                                {carExits.map((exit, index) => (
                                    <tr key={index} className="hover:bg-gray-700">
                                        <td className="px-2 py-2 border-b border-gray-700">{exit.plate}</td>
                                        <td className="px-2 py-2 border-b border-gray-700">{new Date(exit.entry_time).toLocaleString()}</td>
                                        <td className="px-2 py-2 border-b border-gray-700">{new Date(exit.exit_time).toLocaleString()}</td>
                                        <td className="px-2 py-2 border-b border-gray-700">{exit.duration}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="bg-gray-900 p-4 text-white rounded-lg">
                    <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
                        <FaExclamationTriangle className="mr-2" /> Alertes
                    </h3>
                    <div className="mb-4">
                        <label className="block text-lg mb-2">Valeur :</label>
                        <input type="number" value={alertValue} onChange={e => setAlertValue(e.target.value)} className="bg-gray-300 text-black rounded px-3 py-2 mb-3 w-full text-sm" />
                        <label className="block text-lg mb-2">Unité :</label>
                        <select value={alertUnit} onChange={e => setAlertUnit(e.target.value)} className="bg-gray-300 text-black rounded px-3 py-2 w-full text-sm">
                            <option value="minutes">Minutes</option>
                            <option value="hours">Heures</option>
                            <option value="days">Jours</option>
                        </select>
                    </div>
                    <button onClick={handleSetAlert} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg mb-4 w-full">
                        Définir l'alerte
                    </button>
                    <button onClick={handleStopAlert} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg mb-4 w-full">
                        Arrêter l'alerte
                    </button>
                    <div className="overflow-y-auto max-h-64">
                        <table className="table-auto w-full text-left">
                            <thead>
                                <tr>
                                    <th className="px-2 py-2 border-b border-gray-700">Plaque</th>
                                    <th className="px-2 py-2 border-b border-gray-700">Heure d'entrée</th>
                                    <th className="px-2 py-2 border-b border-gray-700">Heure de sortie</th>
                                    <th className="px-2 py-2 border-b border-gray-700">Durée</th>
                                </tr>
                            </thead>
                            <tbody>
                                {alertResults.map((alert, index) => (
                                    <tr key={index} className="hover:bg-gray-700 transition-transform duration-500 transform translate-x-0">
                                        <td className="px-2 py-2 border-b border-gray-700">{alert.plate}</td>
                                        <td className="px-2 py-2 border-b border-gray-700">{new Date(alert.entry_time).toLocaleString()}</td>
                                        <td className="px-2 py-2 border-b border-gray-700">{new Date(alert.exit_time).toLocaleString()}</td>
                                        <td className="px-2 py-2 border-b border-gray-700">{alert.duration}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="bg-gray-900 p-4 text-white rounded-lg md:col-span-3">
                    <h3 className="text-2xl font-semibold mb-4 text-white flex items-center">
                        <FaSearch className="mr-2" /> Rechercher une plaque
                    </h3>
                    <div className="mb-4">
                        <label className="block text-lg mb-2">Plaque :</label>
                        <input type="text" value={searchPlate} onChange={e => setSearchPlate(e.target.value)} className="bg-gray-300 text-black rounded px-3 py-2 w-full text-sm" />
                    </div>
                    <button onClick={handleSearchPlate} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg mb-4 w-full">
                        Rechercher
                    </button>
                    <div className="overflow-y-auto max-h-64">
                        <table className="table-auto w-full text-left">
                            <thead>
                                <tr>
                                    <th className="px-2 py-2 border-b border-gray-700">Plaque</th>
                                    <th className="px-2 py-2 border-b border-gray-700">Heure d'entrée</th>
                                    <th className="px-2 py-2 border-b border-gray-700">Heure de sortie</th>
                                    <th className="px-2 py-2 border-b border-gray-700">URL de la vidéo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults.map((result, index) => (
                                    <tr key={index} className="hover:bg-gray-700">
                                        <td className="px-2 py-2 border-b border-gray-700">{result.plate}</td>
                                        <td className="px-2 py-2 border-b border-gray-700">{new Date(result.entry_time).toLocaleString()}</td>
                                        <td className="px-2 py-2 border-b border-gray-700">{new Date(result.exit_time).toLocaleString()}</td>
                                        <td className="px-2 py-2 border-b border-gray-700"><a href={result.video_url} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">Regarder la vidéo</a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ParkingDashboard;
