import React, { useState, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import { FaDesktop } from 'react-icons/fa';
import { Chart as ChartJS, RadialLinearScale, PointElement, Filler, Tooltip, Legend } from 'chart.js';
import IotaAreaChart from './IotAreachart';

ChartJS.register(RadialLinearScale, PointElement, Filler, Tooltip, Legend);

const PerformanceCard = ({ data }) => {
  const [selectedCluster1, setSelectedCluster1] = useState('');
  const [selectedCluster2, setSelectedCluster2] = useState('');
  const [selectedServer1, setSelectedServer1] = useState('');
  const [selectedServer2, setSelectedServer2] = useState('');
  const [selectedMetric, setSelectedMetric] = useState('cpu_usage');

  useEffect(() => {
    if (data.length > 0) {
      const defaultCluster1 = data[0].cluster_name;
      setSelectedCluster1(defaultCluster1);
      const defaultServer1 = data.find((item) => item.cluster_name === defaultCluster1)?.server_type;
      setSelectedServer1(defaultServer1);

      const defaultCluster2 = data[1]?.cluster_name || data[0].cluster_name;
      setSelectedCluster2(defaultCluster2);
      const defaultServer2 = data.find((item) => item.cluster_name === defaultCluster2)?.server_type;
      setSelectedServer2(defaultServer2);
    }
  }, [data]);

  const clusters = data.map((item) => item.cluster_name).filter((value, index, self) => self.indexOf(value) === index);

  const servers = (cluster) =>
    data
      .filter((item) => item.cluster_name === cluster)
      .map((item) => item.server_type)
      .filter((value, index, self) => self.indexOf(value) === index);

  const getServerData = (cluster, serverType) =>
    data.find((item) => item.cluster_name === cluster && item.server_type === serverType);

  const normalize = (value, max) => (value / max) * 100;

  const server1Data = getServerData(selectedCluster1, selectedServer1) || {};
  const server2Data = getServerData(selectedCluster2, selectedServer2) || {};

  const radarData = {
    labels: ['Server Health', 'CPU Usage', 'Upload Speed', 'Download Speed', 'Alarms'],
    datasets: [
      {
        label: 'Server 1',
        data: [
          normalize(server1Data.server_health || 0, 100), // Normalize percentage (max 100)
          normalize(server1Data.cpu_usage || 0, 100), // Normalize percentage (max 100)
          normalize(server1Data.upload_speed || 0, 1000), // Adjust max value as needed
          normalize(server1Data.download_speed || 0, 1000), // Adjust max value as needed
          normalize(server1Data.alarms || 0, 10), // Normalize to max value 10
        ],
        backgroundColor: 'rgba(34, 202, 236, 0.2)',
        borderColor: 'rgba(34, 202, 236, 1)',
        borderWidth: 1,
        pointBackgroundColor: 'rgba(34, 202, 236, 1)',
      },
      {
        label: 'Server 2',
        data: [
          normalize(server2Data.server_health || 0, 100), // Normalize percentage (max 100)
          normalize(server2Data.cpu_usage || 0, 100), // Normalize percentage (max 100)
          normalize(server2Data.upload_speed || 0, 1000), // Adjust max value as needed
          normalize(server2Data.download_speed || 0, 1000), // Adjust max value as needed
          normalize(server2Data.alarms || 0, 10), // Normalize to max value 10
        ],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        pointBackgroundColor: 'rgba(255, 99, 132, 1)',
      },
    ],
  };

  const radarOptions = {
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          display: false,
        },
        pointLabels: {
          font: {
            size: 12,
          },
          color: '#ffffff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#ffffff',
        },
      },
    },
  };

  const latestRecords1 = data.filter((record) => record.cluster_name === selectedCluster1 && record.server_type === selectedServer1).slice(-5);
  const latestRecords2 = data.filter((record) => record.cluster_name === selectedCluster2 && record.server_type === selectedServer2).slice(-5);

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <FaDesktop className="text-blue-500 text-2xl mr-2" />
          <h2 className="font-bold text-xl text-gray-100">Performance Comparison</h2>
        </div>
      </div>
      <div className="mb-4 flex flex-wrap gap-2">
        <div className="flex-1 min-w-40">
          <label className="block text-gray-400 mb-1 text-xs">Cluster 1</label>
          <select
            value={selectedCluster1}
            onChange={(e) => setSelectedCluster1(e.target.value)}
            className="w-full p-1 bg-gray-700 rounded border border-gray-600 focus:outline-none focus:border-blue-400 text-xs text-gray-100"
          >
            <option value="">Select Cluster</option>
            {clusters.map((cluster, index) => (
              <option key={index} value={cluster}>
                {cluster}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-1 min-w-40">
          <label className="block text-gray-400 mb-1 text-xs">Server 1</label>
          <select
            value={selectedServer1}
            onChange={(e) => setSelectedServer1(e.target.value)}
            className="w-full p-1 bg-gray-700 rounded border border-gray-600 focus:outline-none focus:border-blue-400 text-xs text-gray-100"
            disabled={!selectedCluster1}
          >
            <option value="">Select Server</option>
            {servers(selectedCluster1).map((server, index) => (
              <option key={index} value={server}>
                {server}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mb-4 flex flex-wrap gap-2">
        <div className="flex-1 min-w-40">
          <label className="block text-gray-400 mb-1 text-xs">Cluster 2</label>
          <select
            value={selectedCluster2}
            onChange={(e) => setSelectedCluster2(e.target.value)}
            className="w-full p-1 bg-gray-700 rounded border border-gray-600 focus:outline-none focus:border-blue-400 text-xs text-gray-100"
          >
            <option value="">Select Cluster</option>
            {clusters.map((cluster, index) => (
              <option key={index} value={cluster}>
                {cluster}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-1 min-w-40">
          <label className="block text-gray-400 mb-1 text-xs">Server 2</label>
          <select
            value={selectedServer2}
            onChange={(e) => setSelectedServer2(e.target.value)}
            className="w-full p-1 bg-gray-700 rounded border border-gray-600 focus:outline-none focus:border-blue-400 text-xs text-gray-100"
            disabled={!selectedCluster2}
          >
            <option value="">Select Server</option>
            {servers(selectedCluster2).map((server, index) => (
              <option key={index} value={server}>
                {server}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedCluster1 && selectedCluster2 && selectedServer1 && selectedServer2 && (
        <>
          <Radar data={radarData} options={radarOptions} />
          <div className="mt-6">
            <h3 className="font-bold text-lg text-gray-100 mb-4">Latest Records</h3>
            <div className="overflow-x-auto">
              <table className="w-full bg-gray-700 text-gray-100 text-xs text-center">
                <thead className="bg-gray-900">
                  <tr>
                    <th className="py-2 px-2 border-b border-gray-600">Cluster</th>
                    <th className="py-2 px-2 border-b border-gray-600">Server</th>
                    <th className="py-2 px-2 border-b border-gray-600">Health</th>
                    <th className="py-2 px-2 border-b border-gray-600">CPU Usage</th>
                    <th className="py-2 px-2 border-b border-gray-600">Upload Speed</th>
                    <th className="py-2 px-2 border-b border-gray-600">Download Speed</th>
                    <th className="py-2 px-2 border-b border-gray-600">Alarms</th>
                  </tr>
                </thead>
                <tbody>
                  {latestRecords1.map((record, index) => (
                    <tr key={index}>
                      <td className="py-2 px-2 border-b border-gray-600">{record.cluster_name}</td>
                      <td className="py-2 px-2 border-b border-gray-600">{record.server_type}</td>
                      <td className="py-2 px-2 border-b border-gray-600">{record.server_health}</td>
                      <td className="py-2 px-2 border-b border-gray-600">{record.cpu_usage}</td>
                      <td className="py-2 px-2 border-b border-gray-600">{record.upload_speed}</td>
                      <td className="py-2 px-2 border-b border-gray-600">{record.download_speed}</td>
                      <td className="py-2 px-2 border-b border-gray-600">{record.alarms}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="bg-gray-900 p-4 rounded-lg shadow-lg mt-4 h-[600px]">
            <h3 className="font-bold text-lg text-gray-100 mb-4">Server 1 - {selectedServer1}</h3>
            <IotaAreaChart data={latestRecords1} metric={selectedMetric} onMetricChange={setSelectedMetric} />
            <h3 className="font-bold text-lg text-gray-100 mb-4">Server 2 - {selectedServer2} </h3>
            <IotaAreaChart data={latestRecords2} metric={selectedMetric} onMetricChange={setSelectedMetric} />
          </div>
          
         
         
        </>
      )}
    </div>
  );
};

export default PerformanceCard;
