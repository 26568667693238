import React, { useState } from 'react';
import axios from 'axios';
import avatar1 from "../../assets/img/output-onlinegiftools.gif";
import userIcon from "../../assets/img/user-icon.jpeg"; // Path to user icon
import botIcon from "../../assets/img/bot_icon.png";  // Path to bot icon
import './ChatBot.css';

const ChatBot = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [messages, setMessages] = useState([]);

  const toggleChatWindow = () => {
    setIsVisible(!isVisible);
  };

  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userInput.trim()) {
      setMessages((prevMessages) => [...prevMessages, { text: userInput, sender: 'user' }]);

      try {
        const response = await axios.post('http://localhost:4142/query ', { question: userInput });
        const apiResponse = response.data.result;
        setMessages((prevMessages) => [...prevMessages, { text: apiResponse, sender: '' }]);
      } catch (error) {
        console.error(error);        
      }

      setUserInput('');
    }
  };

  return (
    <div className="fixed bottom-4 right-4 md:bottom-8 md:right-8 z-50">
      <button
        onClick={toggleChatWindow}
        aria-label="Toggle Chat"
        className={`transform transition-transform duration-300 hover:scale-110 ${isVisible ? 'rotate-45' : ''}`}
      >
        <img src={avatar1} alt="Chat" className="w-20 h-20" />
      </button>
      {isVisible && (
        <div className="w-72 h-96 md:w-96 md:h-128 bg-blue-500 text-white shadow-xl rounded-lg overflow-hidden flex flex-col absolute bottom-16 right-0 transition-all duration-300 transform translate-x-full md:translate-x-0">
          <div className="flex-none p-4 bg-blue-600 border-b border-blue-700">
            ChatBot
          </div>
          <div className="p-4 flex-auto overflow-y-auto">
            <div className="flex flex-col">
              {messages.map((message, index) => (
                <div key={index} className={`my-2 flex flex-col ${message.sender === 'user' ? 'items-start' : 'items-end'}`}>
                  <div className={`flex items-center gap-2 p-2 ${message.sender === 'user' ? 'bg-blue-200 text-blue-900' : 'bg-blue-300 text-blue-800'} rounded-t`}>
                    <img src={message.sender === 'user' ? userIcon : botIcon} alt="icon" className="w-6 h-6"/>
                    <span>{message.sender}</span>
                  </div>
                  <div className={`text-left flex items-center gap-2 p-2 ${message.sender === 'user' ? 'bg-blue-200 text-blue-900' : 'bg-blue-300 text-blue-800'} rounded-b`}>
                    <span className="flex-1">{message.text}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <form onSubmit={handleSubmit} className="flex-none p-4 bg-blue-600">
            <input
              type="text"
              className="w-full p-2 border rounded focus:outline-none focus:shadow-outline bg-blue-200 text-blue-900"
              placeholder="Hey,how can i help you today ? .."
              value={userInput}
              onChange={handleUserInput}
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
