import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import 'echarts/theme/macarons';

const TopStoresChart = () => {
  useEffect(() => {
    const chartDom = document.getElementById('main');
    const myChart = echarts.init(chartDom, 'macarons');

    const fakeData = [
      { store: 'Store A', sales: 120 },
      { store: 'Store B', sales: 200 },
      { store: 'Store C', sales: 150 },
      { store: 'Store D', sales: 80 },
      { store: 'Store E', sales: 70 }
    ];

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        top: 50,
        left: 80,
        right: 50,
        bottom: 50
      },
      xAxis: {
        type: 'value',
        show: false
      },
      yAxis: {
        type: 'category',
        data: fakeData.map(item => item.store),
        axisLabel: {
          interval: 0,
          textStyle: {
            color: '#ffffff'
          }
        }
      },
      series: [
        {
          data: fakeData.map(item => item.sales),
          type: 'bar',
          barWidth: 20,
          label: {
            show: true,
            position: 'right',
            color: '#ffffff'
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#f46f4e' },
                { offset: 0.7, color: '#f49e4e' },
                { offset: 1, color: '#f4e24e' }
              ]
            )
          },
          emphasis: {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: '#f46f4e' },
                  { offset: 0.7, color: '#f49e4e' },
                  { offset: 1, color: '#f4e24e' }
                ]
              )
            }
          }
        }
      ],
      legend: {
        show: true,
        textStyle: {
          color: '#ffffff'
        }
      }
    };

    myChart.setOption(option);

    window.addEventListener('resize', () => {
      myChart.resize();
    });

    return () => {
      myChart.dispose();
    };
  }, []);

  return <div id="main" style={{ width: '100%', height: '100%' }} />;
};

export default TopStoresChart;
