import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import AlarmCard from './AlarmCards';

const AlarmCardSlider = ({ alarms }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentRef = useRef(null);
  const containerRef = useRef(null);

  const cardWidth = 340; // Adjust this width according to your design
  const visibleCards = 4; // Number of cards visible in one view

  const handleScrollLeft = () => {
    setScrollPosition((prev) => Math.max(prev - cardWidth * visibleCards, 0));
  };

  const handleScrollRight = () => {
    const contentWidth = contentRef.current.offsetWidth;
    const containerWidth = containerRef.current.offsetWidth;
    const maxScroll = contentWidth - containerWidth;
    setScrollPosition((prev) => Math.min(prev + cardWidth * visibleCards, maxScroll));
  };

  return (
    <div className="relative">
      <button
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300 z-10"
        onClick={handleScrollLeft}
        style={{ visibility: scrollPosition > 0 ? 'visible' : 'hidden' }}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div
        ref={containerRef}
        className="flex overflow-hidden"
        style={{ maxWidth: '100%', overflowX: 'hidden' }}
      >
        <div
          ref={contentRef}
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${scrollPosition}px)` }}
        >
          {alarms.map((alarm, index) => (
            <div key={index} style={{ flex: '0 0 auto', width: `${cardWidth}px`, margin: '0 10px' }}>
              <AlarmCard alarm={alarm} />
            </div>
          ))}
        </div>
      </div>
      <button
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
        onClick={handleScrollRight}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

export default AlarmCardSlider;
