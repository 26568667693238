import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignal, faChartLine, faDatabase, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const GsmDataComponent = ({ gsmData, mostDataSite }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-4 transform hover:scale-105 transition duration-300">
      <div className="flex items-center mb-3">
        <FontAwesomeIcon icon={faSignal} className="w-8 h-8 mr-4 text-blue-500" />
        <h2 className="text-2xl font-semibold text-gray-700 mt-2">GSM Data Consumption</h2>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="bg-gray-50 rounded-lg p-3 shadow-sm flex items-center">
          <FontAwesomeIcon icon={faChartLine} className="w-5 h-5 mr-2 text-green-500" />
          <div>
            <p className="text-xs text-gray-500">Current Usage:</p>
            <p className="text-lg font-semibold text-gray-800">{gsmData.currentUsage} MB</p>
          </div>
        </div>
        <div className="bg-gray-50 rounded-lg p-3 shadow-sm flex items-center">
          <FontAwesomeIcon icon={faDatabase} className="w-5 h-5 mr-2 text-purple-500" />
          <div>
            <p className="text-xs text-gray-500">Total Usage:</p>
            <p className="text-lg font-semibold text-gray-800">{gsmData.totalUsage} MB</p>
          </div>
        </div>
        <div className="bg-gray-50 rounded-lg p-1 shadow-sm col-span-2 flex flex-col items-center">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="w-5 h-5 mb-1 text-red-500" />
          <div className="text-center">
            <p className="text-xs text-gray-500">Most Data Used by Site:</p>
            <p className="text-lg font-semibold text-gray-800">{mostDataSite.name}</p>
            <p className="text-xs text-gray-500">Usage: {mostDataSite.usage} MB</p>
          </div>
        </div>
        <div className="bg-gray-50 rounded-lg p-3 col-span-2 shadow-sm">
          <p className="text-xs text-gray-500 mb-1">Remaining:</p>
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
                  Remaining
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-blue-600">
                  {gsmData.remaining} MB
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-2 text-xs flex rounded bg-blue-200">
              <div style={{ width: `${(gsmData.remaining / gsmData.totalUsage) * 100}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GsmDataComponent;
