import React, { useEffect, useState } from 'react';
import { Sparklines, SparklinesLine, SparklinesReferenceLine, SparklinesSpots } from 'react-sparklines';
import Navbar from '../../components/Navbar';

import { FaWifi, FaBed, FaUtensils, FaSwimmer } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import NetRevenueChart from '../../charts/Financial_kpi_charts/RevenueComparisonlinechart';



const Financial_KPIs = () => {
  const data = [4, 6, 7, 8, 4, 3, 2, 1, 5, 7, 9, 11];
  const [services, setServices] = useState([]);
  const navigate = useNavigate();

    

  useEffect(() => {
    // Fetch data from API or mock data
    const data = [
      { name: 'Wifi', icon: <FaWifi />, revenue: 5000 },
      { name: 'Rooms', icon: <FaBed />, revenue: 10000 },
      { name: 'Restaurant', icon: <FaUtensils />, revenue: 8000 },
      { name: 'Pool', icon: <FaSwimmer />, revenue: 3000 },
    ];
    setServices(data);
  }, []);
  return (
    <div className="bg-black min-h-screen p-4">
       <Navbar navigate={navigate} />
       <br />

      <div className="grid grid-cols-12 gap-4">
        {/* Top row: Four smaller blocks and one medium block */}
        <div className="col-span-12 sm:col-span-4 md:col-span-2 bg-gradient-to-b from-green-800 to-black h-64 rounded-xl shadow-lg flex flex-col justify-start items-center text-white relative overflow-hidden">
  <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-purple-600 to-blue-500 opacity-20 animate-pulse"></div>
  <div className="absolute bottom-0 left-0 w-full h-16 bg-gradient-to-r from-purple-600 to-blue-500 opacity-30 animate-pulse"></div>
  <div className="absolute bottom-0 right-0 w-32 h-32 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 opacity-30 animate-pulse"></div>
  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home text-white opacity-10 animate-spin-slow w-24 h-24">
      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
      <polyline points="9 22 9 12 15 12 15 22"></polyline>
    </svg>
  </div>
  <h2 className="text-center text-gray-300 mt-4 mb-3 uppercase tracking-wider font-semibold z-10 relative">Hotel Occupancy Rate</h2>
  <div className="flex flex-col items-center flex-grow w-full p-3 z-10 relative">
    <p className="text-center font-sans font-semibold text-6xl mb-1 z-10 relative">13.62<span className="text-3xl">%</span></p>
    <div className='flex flex-row items-center justify-center w-full'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trending-up text-green-500 w-6 h-6 animate-bounce z-10 relative">
        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
        <polyline points="17 6 23 6 23 12"></polyline>
      </svg>
      <span className="text-green-500 text-xl ml-2 z-10 relative">+6.98%</span>
    </div>

    <div className="w-full py-3 overflow-hidden mt-3 z-10 relative">
      <Sparklines data={data} width={300} height={70} margin={5}>
        <SparklinesLine style={{ fill: "none", strokeWidth: 2 }} color="#10B981" />
        <SparklinesReferenceLine type="avg" style={{ strokeWidth: 2, strokeDasharray: "2,2" }} color="#ffffff40" />
        <SparklinesSpots size={4} style={{ fill: "#34D399" }} />
      </Sparklines>
    </div>
  </div>
</div>






<div className="col-span-12 sm:col-span-4 md:col-span-2 bg-gradient-to-b from-yellow-800 to-black h-64 rounded-xl shadow-lg flex flex-col justify-start items-center text-white relative overflow-hidden">
  <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-purple-600 to-blue-500 opacity-20 animate-pulse"></div>
  <div className="absolute bottom-0 left-0 w-full h-16 bg-gradient-to-r from-purple-600 to-blue-500 opacity-30 animate-pulse"></div>
  <div className="absolute bottom-0 right-0 w-32 h-32 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 opacity-30 animate-pulse"></div>
  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-dollar-sign text-white opacity-10 animate-spin-slow w-24 h-24">
      <line x1="12" y1="1" x2="12" y2="23"></line>
      <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
    </svg>
  </div>
  <h2 className="text-center text-gray-300 mt-4 mb-3 uppercase tracking-wider font-semibold z-10 relative">Average Daily Rate</h2>
  <div className="flex flex-col items-center flex-grow w-full p-3 z-10 relative">
    <p className="text-center font-sans font-semibold text-6xl mb-1 z-10 relative">541<span className="text-3xl">$</span></p>
    <div className='flex flex-row items-center justify-center w-full'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trending-up text-green-500 w-6 h-6 animate-bounce z-10 relative">
        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
        <polyline points="17 6 23 6 23 12"></polyline>
      </svg>
      <span className="text-green-500 text-xl ml-2 z-10 relative">+6.98%</span>
    </div>

    <div className="w-full py-3 overflow-hidden mt-3 z-10 relative">
      <Sparklines data={data} width={300} height={70} margin={5}>
        <SparklinesLine style={{ fill: "none", strokeWidth: 2 }} color="#10B981" />
        <SparklinesReferenceLine type="avg" style={{ strokeWidth: 2, strokeDasharray: "2,2" }} color="#ffffff40" />
        <SparklinesSpots size={4} style={{ fill: "#34D399" }} />
      </Sparklines>
    </div>
  </div>
</div>


<div className="col-span-12 sm:col-span-4 md:col-span-2 bg-gradient-to-b from-red-800 to-black h-64 rounded-xl shadow-lg flex flex-col justify-start items-center text-white relative overflow-hidden">
  <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-purple-600 to-blue-500 opacity-20 animate-pulse"></div>
  <div className="absolute bottom-0 left-0 w-full h-16 bg-gradient-to-r from-purple-600 to-blue-500 opacity-30 animate-pulse"></div>
  <div className="absolute bottom-0 right-0 w-32 h-32 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 opacity-30 animate-pulse"></div>
  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home text-white opacity-10 animate-spin-slow w-24 h-24">
      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
      <polyline points="9 22 9 12 15 12 15 22"></polyline>
    </svg>
  </div>
  <h2 className="text-center text-gray-300 mt-4 mb-3 uppercase tracking-wider font-semibold z-10 relative">Hotel Occupancy Rate</h2>
  <div className="flex flex-col items-center flex-grow w-full p-3 z-10 relative">
    <p className="text-center font-sans font-semibold text-6xl mb-1 z-10 relative">13.62<span className="text-3xl">%</span></p>
    <div className='flex flex-row items-center justify-center w-full'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trending-up text-green-500 w-6 h-6 animate-bounce z-10 relative">
        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
        <polyline points="17 6 23 6 23 12"></polyline>
      </svg>
      <span className="text-green-500 text-xl ml-2 z-10 relative">+6.98%</span>
    </div>

    <div className="w-full py-3 overflow-hidden mt-3 z-10 relative">
      <Sparklines data={data} width={300} height={70} margin={5}>
        <SparklinesLine style={{ fill: "none", strokeWidth: 2 }} color="#10B981" />
        <SparklinesReferenceLine type="avg" style={{ strokeWidth: 2, strokeDasharray: "2,2" }} color="#ffffff40" />
        <SparklinesSpots size={4} style={{ fill: "#34D399" }} />
      </Sparklines>
    </div>
  </div>
</div>
<div className="col-span-12 sm:col-span-4 md:col-span-2 bg-gradient-to-b from-purple-800 to-black h-64 rounded-xl shadow-lg flex flex-col justify-start items-center text-white relative overflow-hidden">
  <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-purple-600 to-blue-500 opacity-20 animate-pulse"></div>
  <div className="absolute bottom-0 left-0 w-full h-16 bg-gradient-to-r from-purple-600 to-blue-500 opacity-30 animate-pulse"></div>
  <div className="absolute bottom-0 right-0 w-32 h-32 rounded-full bg-gradient-to-r from-purple-600 to-blue-500 opacity-30 animate-pulse"></div>
  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-home text-white opacity-10 animate-spin-slow w-24 h-24">
      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
      <polyline points="9 22 9 12 15 12 15 22"></polyline>
    </svg>
  </div>
  <h2 className="text-center text-gray-300 mt-4 mb-3 uppercase tracking-wider font-semibold z-10 relative">Average Room Rate</h2>
  <div className="flex flex-col items-center flex-grow w-full p-3 z-10 relative">
    <p className="text-center font-sans font-semibold text-6xl mb-1 z-10 relative">813<span className="text-3xl">$</span></p>
    <div className='flex flex-row items-center justify-center w-full'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trending-up text-green-500 w-6 h-6 animate-bounce z-10 relative">
        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
        <polyline points="17 6 23 6 23 12"></polyline>
      </svg>
      <span className="text-green-500 text-xl ml-2 z-10 relative">+6.98%</span>
    </div>

    <div className="w-full py-3 overflow-hidden mt-3 z-10 relative">
      <Sparklines data={data} width={300} height={70} margin={5}>
        <SparklinesLine style={{ fill: "none", strokeWidth: 2 }} color="#10B981" />
        <SparklinesReferenceLine type="avg" style={{ strokeWidth: 2, strokeDasharray: "2,2" }} color="#ffffff40" />
        <SparklinesSpots size={4} style={{ fill: "#34D399" }} />
      </Sparklines>
    </div>
  </div>
</div>

<div className="col-span-12 sm:col-span-4 md:col-span-4 bg-gray-900 h-64 rounded-lg flex flex-col justify-center items-start text-white  p-4 shadow-lg">
  <h2 className="text-center text-gray-300 mb-3 uppercase tracking-wider font-semibold z-10 relative">Discover Our Services</h2>
  <ul className="grid grid-cols-2 gap-4 mt-4">
    {services.map((service, index) => (
      <li key={index} className="flex items-center py-2 border-b border-gray-700 hover:bg-gray-800 transition duration-300 cursor-pointer">
        <div className="mr-4 rounded-full bg-gray-800 p-3">
          <span className="text-2xl text-blue-500">{service.icon}</span>
        </div>
        <div className="flex-grow">
          <h3 className="text-lg font-semibold mb-1">{service.name}</h3>
          <div className="w-full h-2 bg-gray-800 rounded-lg overflow-hidden">
            <div className="h-2 rounded-lg bg-blue-500" style={{ width: `${(service.revenue / 20000) * 100}%` }}></div>
          </div>
        </div>
        <span className="ml-4 text-lg font-semibold text-gray-300">{service.revenue}$</span>
      </li>
    ))}
  </ul>
</div>


        {/* Second row: Two medium blocks */}
        <div className="col-span-12 sm:col-span-6 md:col-span-6 bg-gray-900 h-96 rounded-lg flex justify-center items-center text-white text-xl">
        <NetRevenueChart/>
        </div>
        <div class="col-span-12 sm:col-span-6 md:col-span-6 bg-gradient-to-r from-gray-900 to-gray-700 rounded-lg p-6 flex flex-col justify-between h-full">
  <div class="flex justify-between items-center mb-1">
    <h2 class="text-2xl font-bold text-white">Income & Expenses</h2>
    <p class="text-sm text-gray-300">Jan 1, 2024 to Mar 15, 2024</p>
  </div>
  <div class="flex flex-col sm:flex-row justify-between items-center mb-2">
    <div class="bg-gradient-to-r from-green-800 to-green-400 rounded-lg p-4 flex flex-col justify-center items-center text-white w-full sm:w-auto">
      <h3 class="text-xl font-bold mb-2">Income</h3>
      <p class="text-4xl font-bold mb-2">$82,291</p>
      <p class="text-sm">42.01% vs $56,699 (prev.)</p>
    </div>
    <div class="bg-gradient-to-r from-red-500 to-red-700 rounded-lg p-4 flex flex-col justify-center items-center text-white w-full sm:w-auto mt-4 sm:mt-0 sm:ml-4">
      <h3 class="text-xl font-bold mb-2">Expenses</h3>
      <p class="text-4xl font-bold mb-2">$45,458</p>
      <p class="text-sm">88.97% vs $24,596 (prev.)</p>
    </div>
    <div class="bg-gradient-to-r from-yellow-500 to-yellow-700 rounded-lg p-4 flex flex-col justify-center items-center text-white w-full sm:w-auto mt-4 sm:mt-0 sm:ml-4">
      <h3 class="text-xl font-bold mb-2">Cost of Goods Sold</h3>
      <p class="text-4xl font-bold mb-2">$47,716</p>
      <p class="text-sm">42.01% vs $8,662 (prev.)</p>
    </div>
  </div>
  <div class="bg-gradient-to-r from-blue-500 to-blue-700 rounded-lg p-4 flex flex-col justify-center items-center text-white w-full mt-4 animate-pulse">
    <h3 class="text-xl font-bold mb-2">Net Profit</h3>
    <p class="text-4xl font-bold mb-2">$84,220</p>
    <p class="text-sm">152.50% vs $33,353 (prev.)</p>
  </div>
</div>


        
        {/* Bottom row: One large block */}
        <div className="col-span-12 bg-gray-900 h-80 rounded-lg flex justify-center items-center text-white text-2xl">
          
       
        </div>
      </div>
    </div>
  );
}


export default Financial_KPIs;
