import React from "react";
import ReactEcharts from "echarts-for-react";

const PieAlertsSeveritychart = ({ data }) => {
  const colors = ["#CCFFCC", "#90caf9", "#EB5406", "#801818", "#e0115F", "#FF2400"];
  const categories = Object.keys(data);

  const seriesData = categories.map((category) => ({
    value: data[category],
    name: category,
  })).sort((a, b) => b.value - a.value);

  const total = seriesData.reduce((sum, current) => sum + current.value, 0);

  const getOption = () => {
    return {
      backgroundColor: 'transparent',
      tooltip: {
        trigger: "item",
        textStyle: {
          fontFamily: "Roboto, sans-serif",
          fontSize: 14,
          color: "#000000", // Set tooltip text color to black
        },
        formatter: "{a} <br/>{b}: {c} ({d}%)"
      },
      legend: {
        orient: "horizontal",
        bottom: "3%", // Moved closer to the bottom
        left: "center",
        itemWidth: 14,
        itemHeight: 14,
        textStyle: {
          fontFamily: "Roboto, sans-serif",
          fontSize: 12,
          color: "#ffffff",
        },
        formatter: function (name) {
          const category = seriesData.find((item) => item.name === name);
          const percentage = (category.value / total * 100).toFixed(2);
          return `${name} (${category.value} - ${percentage}%)`;
        },
      },
      series: [
        {
          name: "Severity",
          type: "pie",
          radius: ["35%", "60%"], // Increased radius to make the pie larger
          center: ["50%", "48%"], // Moved slightly closer to the top
          avoidLabelOverlap: true,
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
            },
            itemStyle: {
              shadowBlur: 20,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.7)",
            },
          },
          labelLine: {
            show: false,
          },
          data: seriesData,
          color: colors,
        },
      ],
    };
  };

  return <ReactEcharts option={getOption()} style={{ height: '288px', width: '100%' }} />;
};

export default PieAlertsSeveritychart;
