import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Navbar from '../../components/Navbar';
import { FaClipboardList, FaDollarSign, FaUsers, FaGlobeAmericas, FaArrowUp, FaArrowDown, FaSyncAlt } from 'react-icons/fa';
import TotalSalesLineChart from '../../charts/Retail_charts/Totalsales';
import TotalSalesDonutChart from '../../charts/Retail_charts/Donut_charts';
import TotalBarChart from '../../charts/Retail_charts/Barchart';

const StoreSales = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [recordsToDisplay, setRecordsToDisplay] = useState(10);

  const [countries, setCountries] = useState([]);
  const [sources, setSources] = useState([]);
  const [financialStatuses, setFinancialStatuses] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [startDate, endDate] = selectedDateRange;

  const [totalOrders, setTotalOrders] = useState(0);
  const [previousTotalOrders, setPreviousTotalOrders] = useState(0);

  const [averageOrderValue, setAverageOrderValue] = useState(0);
  const [previousAverageOrderValue, setPreviousAverageOrderValue] = useState(0);

  const [numberOfCustomers, setNumberOfCustomers] = useState(0);
  const [totalCountries, setTotalCountries] = useState(0);
  const [tableData, setTableData] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedFinancialStatus, setSelectedFinancialStatus] = useState(null);
  const formatDataForTable = (data) => {
    const tableData = data.map((item) => ({
      orderId: item.order_id,
      orderDate: new Date(item.order_date).toLocaleDateString(),
      country: item.country,
      source: item.source,
      financialStatus: item.financial_status,
      totalPrice: item.total_price,
    }));

    return tableData;
  };

  useEffect(() => {
    setIsLoading(true);
    fetch('https://magnetoo.fr/order_data')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        const uniqueCountries = Array.from(new Set(data.map((item) => item.country)));
        const uniqueSources = Array.from(new Set(data.map((item) => item.source)));
        const uniqueFinancialStatuses = Array.from(new Set(data.map((item) => item.financial_status)));

        setCountries(uniqueCountries);
        setSources(uniqueSources);
        setFinancialStatuses(uniqueFinancialStatuses);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Failed to fetch filter options');
      })
      .finally(() => setIsLoading(false));
  }, []);

  const filterData = () => {
    let filtered = data;

    setFilteredData(filtered);
    setTableData(formatDataForTable(filtered));

    if (selectedCountry) {
      filtered = filtered.filter((item) => item.country === selectedCountry.value);
    }

    if (selectedSource) {
      filtered = filtered.filter((item) => item.source === selectedSource.value);
    }

    if (selectedFinancialStatus) {
      filtered = filtered.filter((item) => item.financial_status === selectedFinancialStatus.value);
    }

    if (startDate && endDate) {
      filtered = filtered.filter((item) => {
        const createdAt = new Date(item.order_date);
        const createdAtLocal = new Date(createdAt.getTime() - createdAt.getTimezoneOffset() * 60000);
        return createdAtLocal >= startDate && createdAtLocal <= endDate;
      });
    }

    setFilteredData(filtered);

    setPreviousTotalOrders(totalOrders);
    setTotalOrders(filtered.length);

    const totalPrice = filtered.reduce((total, item) => {
      const price = parseFloat(item.total_price);
      if (!isNaN(price)) {
        return total + price;
      }
      return total;
    }, 0);
    setPreviousAverageOrderValue(averageOrderValue);
    setAverageOrderValue(totalPrice / filtered.length);
    setNumberOfCustomers(Array.from(new Set(filtered.map((item) => item.customer))).length);
    setTotalCountries(Array.from(new Set(filtered.map((item) => item.country))).length);
  };

  useEffect(filterData, [selectedCountry, selectedSource, selectedFinancialStatus, startDate, endDate, data]);

  const resetFilters = () => {
    setSelectedCountry(null);
    setSelectedSource(null);
    setSelectedFinancialStatus(null);
    setSelectedDateRange([null, null]);
  };

  const totalOrdersChange = totalOrders - previousTotalOrders;
  const totalOrdersPercentageChange = (totalOrdersChange / (previousTotalOrders || 1)) * 100;
  const totalOrdersIncrease = totalOrdersChange > 0;

  const averageOrderValueChange = averageOrderValue - previousAverageOrderValue;
  const averageOrderValuePercentageChange = (averageOrderValueChange / (previousAverageOrderValue || 1)) * 100;
  const averageOrderValueIncrease = averageOrderValueChange > 0;

  const aggregateOrdersByMonth = (data) => {
    const ordersByMonth = data.reduce((acc, item) => {
      const date = new Date(item.order_date);
      const yearMonth = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;

      if (!acc[yearMonth]) {
        acc[yearMonth] = 0;
      }

      acc[yearMonth] += 1;
      return acc;
    }, {});

    const sortedMonths = Object.keys(ordersByMonth).sort((a, b) => new Date(a) - new Date(b));

    return sortedMonths.map((yearMonth) => {
      const [year, month] = yearMonth.split('-');
      return {
        x: new Date(year, month - 1).getTime(),
        y: ordersByMonth[yearMonth],
      };
    });
  };

  const lineChartData = aggregateOrdersByMonth(filteredData);

  const aggregateAverageOrderValueByMonth = (data) => {
    const orderValuesByMonth = data.reduce((acc, item) => {
      const date = new Date(item.order_date);
      const yearMonth = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`;

      if (!acc[yearMonth]) {
        acc[yearMonth] = { totalValue: 0, count: 0 };
      }

      acc[yearMonth].totalValue += parseFloat(item.total_price);
      acc[yearMonth].count += 1;

      return acc;
    }, {});

    const sortedMonths = Object.keys(orderValuesByMonth).sort((a, b) => new Date(a) - new Date(b));

    return sortedMonths.map((yearMonth) => {
      const [year, month] = yearMonth.split('-');
      const { totalValue, count } = orderValuesByMonth[yearMonth];
      return {
        x: new Date(year, month - 1).getTime(),
        y: (totalValue / count).toFixed(3), // Limit to 3 decimal places
      };
    });
  };

  const lineOrdersChartData = aggregateAverageOrderValueByMonth(filteredData);

  const aggregateSalesBySource = (data) => {
    const salesBySource = data.reduce((acc, item) => {
      const source = item.source;

      if (!acc[source]) {
        acc[source] = { totalCount: 0 };
      }

      acc[source].totalCount += 1;

      return acc;
    }, {});

    const sources = Object.keys(salesBySource);

    return sources.map((source) => {
      const { totalCount } = salesBySource[source];
      return {
        name: source,
        value: totalCount,
      };
    });
  };

  const pieSalesBySourceData = aggregateSalesBySource(filteredData);
  const aggregateSalesByCountry = (data) => {
    const salesByCountry = data.reduce((acc, item) => {
      const country = item.country;

      if (!acc[country]) {
        acc[country] = { totalCount: 0 };
      }

      acc[country].totalCount += 1;

      return acc;
    }, {});

    const countries = Object.keys(salesByCountry);

    return countries.map((country) => {
      const { totalCount } = salesByCountry[country];
      return {
        name: country,
        value: totalCount,
      };
    });
  };
  const pieSalesByContryData = aggregateSalesByCountry(filteredData);
  const aggregateQuantityByProductName = (data) => {
    const quantityByProductName = data.reduce((acc, item) => {
      const productName = item.product_name;
  
      if (!acc[productName]) {
        acc[productName] = { totalQuantity: 0 };
      }
  
      acc[productName].totalQuantity += item.quantity;
  
      return acc;
    }, {});
  
    const productNames = Object.keys(quantityByProductName);
  
    return productNames.map((productName) => {
      const { totalQuantity } = quantityByProductName[productName];
      return {
        name: productName,
        value: totalQuantity,
      };
    });
  };
  
  const pieQuantityByProductNameData = aggregateQuantityByProductName(filteredData);
  const aggregateSalesByProductName = (data) => {
    const salesByProductName = data.reduce((acc, item) => {
      const productName = item.product_name;
    
      if (!acc[productName]) {
        acc[productName] = { totalSales: 0 };
      }
    
      acc[productName].totalSales += item.quantity * parseFloat(item.total_price);
    
      return acc;
    }, {});
    
    const productNames = Object.keys(salesByProductName);
    
    return productNames.map((productName) => {
      const { totalSales } = salesByProductName[productName];
      return {
        name: productName,
        value: totalSales,
      };
    });
  };
    
  const pieSalesByProductNameData = aggregateSalesByProductName(filteredData);
  
  

 




  return (
    <div className="bg-black min-h-screen p-4 font-orbitron text-neon-pink">
      <Navbar navigate={navigate} />
      <br />
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-6">
        <div className="relative z-10">
          <Select
            options={countries.map((country) => ({ value: country, label: country }))}
            placeholder="Select a country"
            onChange={setSelectedCountry}
            value={selectedCountry}
            styles={{
              control: (provided, state) => ({
                ...provided,
                backgroundColor: '#1a1a2e',
                color: '#0ff',
                borderColor: 'transparent',
                borderRadius: '8px',
                padding: '8px',
                minHeight: '48px',
                boxShadow: state.isFocused ? '0 0 0 2px #00f' : null,
                transition: 'all 0.3s ease',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#00f' : state.isFocused ? '#0ff' : '#1a1a2e',
                color: state.isSelected ? '#0ff' : state.isFocused ? '#000' : '#fff',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
              }),
              singleValue: (provided, state) => ({
                ...provided,
                color: '#0ff',
              }),
              menu: (provided, state) => ({
                ...provided,
                backgroundColor: '#1a1a2e',
                borderRadius: '8px',
                overflow: 'hidden',
                zIndex: 10,
              }),
            }}
            className="text-neon-pink"
          />
        </div>
        <div className="relative z-10">
          <Select
            options={sources.map((source) => ({ value: source, label: source }))}
            placeholder="Select a source"
            onChange={setSelectedSource}
            value={selectedSource}
            styles={{
              control: (provided, state) => ({
                ...provided,
                backgroundColor: '#1a1a2e',
                color: '#0ff',
                borderColor: 'transparent',
                borderRadius: '8px',
                padding: '8px',
                minHeight: '48px',
                boxShadow: state.isFocused ? '0 0 0 2px #00f' : null,
                transition: 'all 0.3s ease',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#00f' : state.isFocused ? '#0ff' : '#1a1a2e',
                color: state.isSelected ? '#0ff' : state.isFocused ? '#000' : '#fff',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
              }),
              singleValue: (provided, state) => ({
                ...provided,
                color: '#0ff',
              }),
              menu: (provided, state) => ({
                ...provided,
                backgroundColor: '#1a1a2e',
                borderRadius: '8px',
                overflow: 'hidden',
                zIndex: 10,
              }),
            }}
            className="text-neon-pink"
          />
        </div>
        <div className="relative z-10">
          <Select
            options={financialStatuses.map((status) => ({ value: status, label: status }))}
            placeholder="Select a financial status"
            onChange={setSelectedFinancialStatus}
            value={selectedFinancialStatus}
            styles={{
              control: (provided, state) => ({
                ...provided,
                backgroundColor: '#1a1a2e',
                color: '#0ff',
                borderColor: 'transparent',
                borderRadius: '8px',
                padding: '8px',
                minHeight: '48px',
                boxShadow: state.isFocused ? '0 0 0 2px #00f' : null,
                transition: 'all 0.3s ease',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#00f' : state.isFocused ? '#0ff' : '#1a1a2e',
                color: state.isSelected ? '#0ff' : state.isFocused ? '#000' : '#fff',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
              }),
              singleValue: (provided, state) => ({
                ...provided,
                color: '#0ff',
              }),
              menu: (provided, state) => ({
                ...provided,
                backgroundColor: '#1a1a2e',
                borderRadius: '8px',
                overflow: 'hidden',
                zIndex: 10,
              }),
            }}
            className="text-neon-pink"
          />
        </div>
        <div className="relative z-10">
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => setSelectedDateRange(update)}
            isClearable={true}
            placeholderText="Select date range"
            className="rounded-md bg-gray-800 text-neon-pink w-full h-12 p-3 border border-transparent outline-none focus:ring-2 focus:ring-blue-500 transition-all "
          />
        </div>
        <div className="relative z-10 flex justify-end">
          <button
            onClick={resetFilters}
            className="bg-red-600 text-neon-pink px-4 py-2 rounded-md flex items-center hover:bg-red-700 transition-all"
          >
            <FaSyncAlt className="mr-2" />
            Reset Filters
          </button>
        </div>
      </div>
      {isLoading && <p className="text-neon-pink">Loading data...</p>}
      {error && <p className="text-red-500">{error}</p>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-12">
        <div className="p-6 bg-gradient-to-r from-pink-500 to-purple-700 rounded-xl flex items-center justify-between relative shadow-lg">
          <FaClipboardList className="text-9xl text-white/10 absolute right-8 top-6 -mt-4 -ml-4" />
          <div className="z-10">
            <p className="text-sm mb-4 text-gray-300 uppercase tracking-wide">Total Orders</p>
            <p className="text-3xl font-extrabold text-white">{totalOrders}</p>
            <div className="flex items-center mt-2">
              {totalOrdersIncrease ? (
                <FaArrowUp className="text-neon-green mr-2" />
              ) : (
                <FaArrowDown className="text-neon-red mr-2" />
              )}
              <p className={`text-lg font-bold ${totalOrdersIncrease ? 'text-neon-green' : 'text-neon-red'}`}>
                {totalOrdersPercentageChange.toFixed(2)}%
              </p>
            </div>
          </div>
        </div>
        <div className="p-6 bg-gradient-to-r from-green-500 to-blue-700 rounded-xl flex items-center justify-between relative shadow-lg">
          <FaDollarSign className="text-9xl text-white/10 absolute right-8 top-6 -mt-4 -ml-4" />
          <div className="z-10">
            <p className="text-sm mb-4 text-gray-300 uppercase tracking-wide ">Average Order Value</p>
            <p className="text-3xl font-extrabold text-white">${averageOrderValue.toFixed(2)}</p>
            <div className="flex items-center mt-2">
              {averageOrderValueIncrease ? (
                <FaArrowUp className="text-neon-green mr-2" />
              ) : (
                <FaArrowDown className="text-neon-red mr-2" />
              )}
              <p className={`text-lg font-bold ${averageOrderValueIncrease ? 'text-neon-green' : 'text-neon-red'}`}>
                {averageOrderValuePercentageChange.toFixed(2)}%
              </p>
            </div>
          </div>
        </div>
        <div className="p-6 bg-gradient-to-r from-red-500 to-pink-700 rounded-xl flex items-center justify-between relative shadow-lg">
          <FaUsers className="text-9xl text-white/10 absolute right-8 top-6 -mt-4 -ml-4" />
          <div className="z-10">
            <p className="text-sm mb-4 text-gray-300 uppercase tracking-wide">Number of Customers</p>
            <p className="text-3xl font-extrabold text-white">{numberOfCustomers}</p>
          </div>
        </div>
        <div className="p-6 bg-gradient-to-r from-yellow-500 to-orange-700 rounded-xl flex items-center justify-between relative shadow-lg">
          <FaGlobeAmericas className="text-9xl text-white/10 absolute right-8 top-6 -mt-4 -ml-4" />
          <div className="z-10">
            <p className="text-sm mb-4 text-gray-300 uppercase tracking-wide">Total Countries</p>
            <p className="text-3xl font-extrabold text-white">{totalCountries}</p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
        <TotalSalesLineChart data={lineChartData} title={'Total Orders Count'} />
        <TotalSalesLineChart data={lineOrdersChartData} title={'Average Order Value'} />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
        <TotalSalesDonutChart data={pieSalesBySourceData} title={'Sales by Source'} />
        <TotalSalesDonutChart data={pieSalesByContryData} title={'Sales by Country'} />
      </div>
      <div className="mt-10 bg-gray-900 p-4 h-64 overflow-y-auto mb-4">
        <table className="w-full text-left divide-y divide-gray-700 table-auto">
          <thead>
            <tr>
              <th className="px-6 py-3 text-xs font-medium text-blue-400 uppercase tracking-wider">Order ID</th>
              <th className="px-6 py-3 text-xs font-medium text-blue-400 uppercase tracking-wider">Order Date</th>
              <th className="px-6 py-3 text-xs font-medium text-blue-400 uppercase tracking-wider">Country</th>
              <th className="px-6 py-3 text-xs font-medium text-blue-400 uppercase tracking-wider">Source</th>
              <th className="px-6 py-3 text-xs font-medium text-blue-400 uppercase tracking-wider">Financial Status</th>
              <th className="px-6 py-3 text-xs font-medium text-blue-400 uppercase tracking-wider">Total Price</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {tableData.slice(2, recordsToDisplay).map((item) => (
              <tr key={item.orderId} className="bg-gradient-to-r from-gray-800 to-gray-900">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-200">{item.orderId}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-200">{item.orderDate}</td>
                <td
                  className={`px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-200 ${
                    item.country === 'USA'
                      ? 'bg-gradient-to-r from-blue-400 to-blue-600'
                      : item.country === 'Canada'
                      ? 'bg-gradient-to-r from-red-400 to-red-600'
                      : item.country === 'UK'
                      ? 'bg-gradient-to-r from-green-400 to-green-600'
                      : ''
                  }`}
                >
                  {item.country}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-200">{item.source}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-200">{item.financialStatus}</td>
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-200"
                  style={{
                    background: `linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) ${Math.min(
                      100,
                      (item.totalPrice / Math.max(...tableData.map((d) => d.totalPrice))) * 100
                    )}%, rgba(0,255,0,1) 100%)`,
                  }}
                >
                  ${item.totalPrice}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-2">
   
        <TotalBarChart data={pieQuantityByProductNameData} title={'Sales by Product'} />
        <TotalBarChart data={pieSalesByProductNameData} title={'Sales by Product'} />
      </div>
    </div>
  );
};

export default StoreSales;
