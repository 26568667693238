import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Navbar from "../components/Navbar";
import { FaBell, FaExclamationCircle, FaCheckCircle, FaFilter, FaFilePdf } from 'react-icons/fa';
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Alarmlinechart from "../charts/AlarmCharts/Alarmlinechart";
import PieAlertsSeveritychart from "../charts/AlarmCharts/PieAlertsSeveritychart";
import PieAlertsCertaintychart from "../charts/AlarmCharts/PieAlertsCertaintychart";
// Charts will be updated later

const Alerts = () => {
  const navigate = useNavigate();
  const [alarm_data, setAlarm_data] = useState([]);
  const [CategoryIntrusion, setCategoryIntrusion] = useState(0);
  const [CategoryIntrusionAccidental, setCategoryIntrusionAccidental] = useState(0);
  const [CategoryCriminal, setCategoryCriminal] = useState(0);
  const [dateData, setDateData] = useState([]);
  const [CategoryInfrastructure, setCategoryInfrastructure] = useState(0);
  const [CategoryFire, setCategoryFire] = useState(0);
  const [CategoryVideoAnalytics, setCategoryVideoAnalytics] = useState(0);
  const [SeverityExtreme, setSeverityExtreme] = useState(0);
  const [SeverityCritical, setSeverityCritical] = useState(0);
  const [SeverityDisaster, setSeverityDisaster] = useState(0);
  const [SeverityMajor, setSeverityMajor] = useState(0);
  const [SeverityMinor, setSeverityMinor] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [CertaintyWitnessed, setCertaintyWitnessed] = useState(0);
  const [CertaintyConfirmed, setCertaintyConfirmed] = useState(0);
  const [CertaintyReported, setCertaintyReported] = useState(0);
  const [CertaintyUnknown, setCertaintyUnknown] = useState(0);
  const [CertaintyLikely, setCertaintyLikely] = useState(0);
  const [openedStatusCount, setOpenedStatusCount] = useState(0);
  const [closedStatusCount, setClosedStatusCount] = useState(0);
  const [NewStatusCount, setNewStatusCount] = useState(0);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const [filteredAlarmData, setFilteredAlarmData] = useState([]);
  const [filteredDateData, setFilteredDateData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("All");

  useEffect(() => {
    const fetchAlarmData = async () => {
      try {
        const response = await fetch("https://magnetoo.fr/api/Alarm");
        const data = await response.json();

        setAlarm_data(data);
        setCategoryIntrusion(data.filter((alarm_line) => alarm_line.category === "Intrusion").length);
        setCategoryIntrusionAccidental(data.filter((alarm_line) => alarm_line.category === "Accidental").length);
        setCategoryInfrastructure(data.filter((alarm_line) => alarm_line.category === "Infrastructure").length);
        setCategoryFire(data.filter((alarm_line) => alarm_line.category === "Fire").length);
        setCategoryVideoAnalytics(data.filter((alarm_line) => alarm_line.category === "VideoAnalytics").length);
        setDateData(data.map((alarm_line) => alarm_line.creationDate));
        setCategoryCriminal(data.filter((alarm_line) => alarm_line.category === "Criminal").length);
        setSeverityExtreme(data.filter((alarm_line) => alarm_line.severity === "Extreme").length);
        setSeverityCritical(data.filter((alarm_line) => alarm_line.severity === "Critical").length);
        setSeverityDisaster(data.filter((alarm_line) => alarm_line.severity === "Disaster").length);
        setSeverityMajor(data.filter((alarm_line) => alarm_line.severity === "Major").length);
        setSeverityMinor(data.filter((alarm_line) => alarm_line.severity === "Minor").length);
        setCertaintyWitnessed(data.filter((alarm_line) => alarm_line.certainty === "Witnessed").length);
        setCertaintyConfirmed(data.filter((alarm_line) => alarm_line.certainty === "Confirmed").length);
        setCertaintyReported(data.filter((alarm_line) => alarm_line.certainty === "Reported").length);
        setCertaintyLikely(data.filter((alarm_line) => alarm_line.certainty === "Likely").length);
        setCertaintyUnknown(data.filter((alarm_line) => alarm_line.certainty === "Unknown").length);
        setOpenedStatusCount(data.filter((alarm_line) => alarm_line.status === "Opened").length);
        setClosedStatusCount(data.filter((alarm_line) => alarm_line.status === "Closed").length);
        setNewStatusCount(data.filter((alarm_line) => alarm_line.status === "New").length);
      } catch (error) {
        console.error("Error fetching alarm_data:", error);
      }
    };

    fetchAlarmData();
    const intervalId = setInterval(fetchAlarmData, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const handleOpenAlertsClick = () => {
    setStatusFilter("Opened");
    const filteredData = alarm_data.filter((alarm_line) => alarm_line.status === "Opened");
    setFilteredAlarmData(filteredData);
    setFilteredDateData(filteredData.map((alarm_line) => alarm_line.creationDate));
    setFiltersApplied(true);
  };

  const generatePDF = async () => {
    const input = document.getElementById('Alerts');

    if (input) {
      html2canvas(input, { backgroundColor: null }).then((canvas) => {
        const ctx = canvas.getContext('2d');
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = '#000'; // Set the background color to black
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.restore();

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        const currentDate = moment().format('YYYY-MM-DD_HH-mm-ss');
        const pageTitle = 'Alerts'; // Adjusted title
        const fileName = `${pageTitle}_${currentDate}.pdf`;

        pdf.addImage(imgData, 'PNG', 0, 0, width, height);
        pdf.save(fileName);
      });
    } else {
      console.error("Dashboard element not found.");
    }
  };

  const handleClosedAlertsClick = () => {
    setStatusFilter("Closed");
    const filteredData = alarm_data.filter((alarm_line) => alarm_line.status === "Closed");
    setFilteredAlarmData(filteredData);
    setFilteredDateData(filteredData.map((alarm_line) => alarm_line.creationDate));
    setFiltersApplied(true);
  };

  const handleNewAlertsClick = () => {
    setStatusFilter("New");
    const filteredData = alarm_data.filter((alarm_line) => alarm_line.status === "New");
    setFilteredAlarmData(filteredData);
    setFilteredDateData(filteredData.map((alarm_line) => alarm_line.creationDate));
    setFiltersApplied(true);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);

    if (e.target.value === "All") {
      setFilteredAlarmData(alarm_data);
      setFilteredDateData(dateData);
    } else {
      const filteredData = alarm_data.filter((alarm_line) => alarm_line.category === e.target.value);
      setFilteredAlarmData(filteredData);
      setFilteredDateData(filteredData.map((alarm_line) => alarm_line.creationDate));
    }
    setFiltersApplied(true);
  };

  const handleResetFilters = () => {
    setStatusFilter("All");
    setSelectedCategory("All");
    setFilteredAlarmData(alarm_data);
    setFilteredDateData(dateData);
    setFiltersApplied(false);
  };

  
  return (
    <div className="bg-gradient-to-br from-gray-900 via-black to-gray-800 min-h-screen flex flex-col lg:flex-row">
      <div id="Alerts" className="flex-grow p-4 flex flex-col text-white">
        <Navbar navigate={navigate} />
        <br />

        <div className="flex flex-col lg:flex-row gap-3 mb-3">
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-gradient-to-r from-emerald-500 to-emerald-700 rounded-lg p-4 flex-1 flex flex-col justify-center items-center shadow-lg cursor-pointer transform transition-all hover:shadow-xl"
            onClick={handleOpenAlertsClick}
          >
            <FaBell className="text-white text-2xl lg:text-3xl mb-1 lg:mb-2" />
            <p className="text-white text-2xl lg:text-4xl font-bold">{openedStatusCount}</p>
            <h1 className="text-white text-md lg:text-lg font-medium mt-1 lg:mt-2">Open Alerts</h1>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-gradient-to-r from-sky-500 to-sky-700 rounded-lg p-4 flex-1 flex flex-col justify-center items-center shadow-lg cursor-pointer transform transition-all hover:shadow-xl"
            onClick={handleNewAlertsClick}
          >
            <FaExclamationCircle className="text-white text-2xl lg:text-3xl mb-1 lg:mb-2" />
            <p className="text-white text-2xl lg:text-4xl font-bold">{NewStatusCount}</p>
            <h1 className="text-white text-md lg:text-lg font-medium mt-1 lg:mt-2">New Alerts</h1>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-gradient-to-r from-rose-500 to-rose-700 rounded-lg p-4 flex-1 flex flex-col justify-center items-center shadow-lg cursor-pointer transform transition-all hover:shadow-xl"
            onClick={handleClosedAlertsClick}
          >
            <FaCheckCircle className="text-white text-2xl lg:text-3xl mb-1 lg:mb-2" />
            <p className="text-white text-2xl lg:text-4xl font-bold">{closedStatusCount}</p>
            <h1 className="text-white text-md lg:text-lg font-medium mt-1 lg:mt-2">Closed Alerts</h1>
          </motion.div>
        </div>

        <div className="flex flex-col lg:flex-row gap-3 mb-3">
  <div className="bg-gray-800 rounded-lg p-2 w-full shadow-xl">
    <h1 className="text-white text-lg font-bold text-center">Category</h1>
    <div className="h-72"> {/* Adjust height to reduce overall height */}
      <Alarmlinechart
        data={{
          Intrusion: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.category === "Intrusion").length : CategoryIntrusion,
          Accidental: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.category === "Accidental").length : CategoryIntrusionAccidental,
          Criminal: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.category === "Criminal").length : CategoryCriminal,
          Infrastructure: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.category === "Infrastructure").length : CategoryInfrastructure,
          Fire: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.category === "Fire").length : CategoryFire,
          VideoAnalytics: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.category === "VideoAnalytics").length : CategoryVideoAnalytics,
        }}
        dateData={filteredDateData.length > 0 ? filteredDateData : dateData}
      />
    </div>
  </div>

  <div className="bg-gray-800 rounded-lg p-2 w-full shadow-xl">
    <h1 className="text-white text-lg font-bold text-center">Severity</h1>
    <div className="h-48"> {/* Adjust height to reduce overall height */}
      <PieAlertsSeveritychart
        data={{
          Extreme: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.severity === "Extreme").length : SeverityExtreme,
          Critical: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.severity === "Critical").length : SeverityCritical,
          Disaster: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.severity === "Disaster").length : SeverityDisaster,
          Major: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.severity === "Major").length : SeverityMajor,
          Minor: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.severity === "Minor").length : SeverityMinor,
        }}
      />
    </div>
  </div>

  <div className="bg-gray-800 rounded-lg p-2 w-full shadow-xl">
    <h1 className="text-white text-lg font-bold text-center">Certainty</h1>
    <div className="h-48"> {/* Adjust height to reduce overall height */}
      <PieAlertsCertaintychart
        data={{
          Witnessed: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.certainty === "Witnessed").length : CertaintyWitnessed,
          Confirmed: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.certainty === "Confirmed").length : CertaintyConfirmed,
          Reported: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.certainty === "Reported").length : CertaintyReported,
          Unknown: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.certainty === "Unknown").length : CertaintyUnknown,
          Likely: filteredAlarmData.length > 0 ? filteredAlarmData.filter((alarm_line) => alarm_line.certainty === "Likely").length : CertaintyLikely,
        }}
      />
    </div>
  </div>
</div>


        <div className="bg-gray-800 rounded-lg p-4 shadow-xl overflow-hidden mb-4">
          <table className="table-auto w-full text-sm lg:text-sm">
            <thead>
              <tr className="border-b border-gray-700 bg-gray-900">
                <th className="font-semibold p-2 text-left">Originator System</th>
                <th className="font-semibold p-2 text-left">Category</th>
                <th className="font-semibold p-2 text-left">Severity</th>
                <th className="font-semibold p-2 text-left">Certainty</th>
                <th className="font-semibold p-2 text-left">Status</th>
                <th className="font-semibold p-2 text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredAlarmData.slice(0, 10).map((alarm_line, index) => (
                <tr key={index} className="hover:bg-gray-700 transition-all duration-300">
                  <td className="p-2">{alarm_line.originatorSystem}</td>
                  <td className="p-2">{alarm_line.category}</td>
                  <td className="p-2">{alarm_line.severity}</td>
                  <td className="p-2">{alarm_line.certainty}</td>
                  <td className="p-2">{alarm_line.status}</td>
                  <td className="p-2">{alarm_line.creationDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex flex-col lg:flex-row items-center gap-3 mt-4">
          <div className="flex items-center gap-2">
            <FaFilter className="text-white text-xl" />
            <select
              value={selectedCategory}
              onChange={handleCategoryChange}
              className="bg-gray-900 text-white p-2 rounded-lg shadow-inner focus:outline-none"
            >
              <option value="All">All</option>
              <option value="Intrusion">Intrusion</option>
              <option value="Accidental">Accidental</option>
              <option value="Criminal">Criminal</option>
              <option value="Infrastructure">Infrastructure</option>
              <option value="Fire">Fire</option>
              <option value="VideoAnalytics">Video Analytics</option>
            </select>
          </div>

          {filtersApplied && (
            <button
              onClick={handleResetFilters}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-700 transition-colors flex items-center gap-2"
            >
              <FaFilter /> Reset Filters
            </button>
          )}

          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-blue-700 transition-colors flex items-center gap-2"
            onClick={generatePDF}
          >
            <FaFilePdf /> Save as PDF
          </button>
        </div>
      </div>
    </div>
  );
  
};

export default Alerts;
