import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const DonutChart = ({ data }) => {
  const chartData = {
    labels: ['Connected', 'Disconnected'],
    datasets: [
      {
        label: 'Site Connectivity',
        data: data,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return;
          }

          const gradientConnected = ctx.createLinearGradient(0, 0, chartArea.right, chartArea.bottom);
          gradientConnected.addColorStop(0, 'rgba(0, 184, 235, 0.8)');
          gradientConnected.addColorStop(1, 'rgba(0, 123, 255, 0.8)');

          const gradientDisconnected = ctx.createLinearGradient(0, 0, chartArea.right, chartArea.bottom);
          gradientDisconnected.addColorStop(0, 'rgba(255, 138, 101, 0.8)');
          gradientDisconnected.addColorStop(1, 'rgba(255, 85, 184, 0.8)');

          return [gradientConnected, gradientDisconnected];
        },
        hoverBackgroundColor: [
          'rgba(0, 184, 235, 1)',
          'rgba(255, 85, 184, 1)',
        ],
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 70, // Adjust the size of the hole in the center (0 for pie, 50 for half doughnut)
    plugins: {
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleFont: {
          size: 16,
          weight: 'bold',
          family: 'Arial, sans-serif',
          color: '#fff',
        },
        bodyFont: {
          size: 14,
          family: 'Arial, sans-serif',
          color: '#fff',
        },
        callbacks: {
          label: function (tooltipItem) {
            const label = chartData.labels[tooltipItem.dataIndex];
            const value = chartData.datasets[0].data[tooltipItem.dataIndex];
            return `${label}: ${value}%`;
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: '#000',
          font: {
            size: 14,
            family: 'Arial, sans-serif',
          },
          boxWidth: 20,
        },
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  return (
    <div className="flex items-center justify-center h-full w-full">
      <div className="relative h-80 w-80">
        <Doughnut data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default DonutChart;
