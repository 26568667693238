import React, { useEffect, useState } from 'react';
import {
  faSun, faMoon, faBars, faSignOutAlt, faCheckCircle, faBed, faHotel, faExclamationCircle, faRedoAlt, faStar, faCalendarAlt
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../img/color1_text-logoname_transparent_background.png';
import Hotel_Sidebar from './Hotel_Side_Bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KPI from '../../charts/Hotel_charts/KpiHotel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HotelGuestsMap from '../../charts/Hotel_charts/MapGuests';
import PopulationPyramidChart from '../../charts/Hotel_charts/Populationpyramid';
import ReactCountryFlag from 'react-country-flag';
import RoomTypeRevenueChart from '../../charts/Hotel_charts/RoomTypeRevenuChart';
import MealsSpendCard from '../../charts/Hotel_charts/MealsSpendCard';

const MedalSVG = ({ type }) => {
  const sizes = {
    gold: { width: '1.5em', height: '1.5em' },
    silver: { width: '1.3em', height: '1.3em' },
    bronze: { width: '1.2em', height: '1.2em' },
  };

  const colors = {
    gold: '#FFD700',
    silver: '#C0C0C0',
    bronze: '#CD7F32',
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={colors[type]} width={sizes[type].width} height={sizes[type].height}>
      <path d="M12 2C9.243 2 7 4.243 7 7c0 2.757 2.243 5 5 5s5-2.243 5-5c0-2.757-2.243-5-5-5zM4 22h16v-2H4v2zM18.803 15.225L16.9 12.82l-1.729 1.731 1.73 1.731c-.952 1.318-3.073 2.718-5.901 2.718-2.829 0-4.949-1.399-5.901-2.718L8.1 14.552 6.371 12.82l-1.903 2.405c-1.031-1.426-1.648-3.167-1.648-5.102 0-4.688 4.39-9 9-9s9 4.312 9 9c0 1.935-.618 3.676-1.648 5.102z" />
    </svg>
  );
};

const VisitorPage = () => {
  const [darkMode, setDarkMode] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [aggregatedData, setAggregatedData] = useState({});
  const [topCountriesByRevenue, setTopCountriesByRevenue] = useState([]);

  useEffect(() => {
    fetch('https://magnetoo.fr/hotel_data')
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched data:', data);
        setData(data);
        aggregateData(data);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const aggregateData = (data) => {
    if (!data || data.length === 0) return;

    const totalBookings = data.length;
    const totalRevenue = data.reduce((acc, booking) => acc + booking.total_room_revenue, 0).toFixed(2);
    const today = new Date().toISOString().split('T')[0];
    const todayCheckIns = data.filter(booking => booking.Check_in_date.startsWith(today)).length;
    const todayCheckOuts = data.filter(booking => booking.Check_Out_Date.startsWith(today)).length;
    const availableRooms = data.reduce((acc, booking) => acc + booking.rooms_sold, 0);
    const totalGuests = data.reduce((acc, booking) => acc + booking.No_of_People, 0);
    const cancelledBookings = data.filter(booking => booking.Booking_Status === 'Cancelled').length;

    const averageHotelRating = calculateAverageHotelRating(data);
    const averageLengthOfStay = calculateAverageLengthOfStay(data);

    const revenueByCountry = data.reduce((acc, booking) => {
      acc[booking.Origin_Country] = (acc[booking.Origin_Country] || 0) + booking.total_room_revenue;
      return acc;
    }, {});

    const bookingsByCountry = data.reduce((acc, booking) => {
      acc[booking.Origin_Country] = (acc[booking.Origin_Country] || 0) + 1;
      return acc;
    }, {});

    const sortedRevenueByCountry = Object.entries(revenueByCountry)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([country, revenue]) => ({
        country,
        revenue: revenue.toFixed(2),
        bookings: bookingsByCountry[country],
      }));

    setAggregatedData({
      totalBookings,
      totalRevenue,
      todayCheckIns,
      todayCheckOuts,
      availableRooms,
      totalGuests,
      cancelledBookings,
      averageHotelRating,
      averageLengthOfStay,
    });

    setTopCountriesByRevenue(sortedRevenueByCountry);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark', darkMode);
  };

  const getCountryCode = (countryName) => {
    const countryCodes = {
      "Sri Lanka": "LK",
      "United States": "US",
      "Korea": "KR",
      "Congo": "CG",
      "New Caledonia": "NC",
      "Moldova": "MD",
      "Slovakia": "SK",
      // Add more country mappings as needed
    };
    return countryCodes[countryName] || "";
  };

  return (
    <div className={`flex h-screen ${darkMode ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-900'}`}>
      <Hotel_Sidebar darkMode={darkMode} isOpen={isOpen} />
      <div className="flex-1 flex flex-col">
        <header className={`flex justify-between items-center p-4 shadow-md ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <button className="md:hidden p-2" onClick={() => setIsOpen(!isOpen)}>
            <FontAwesomeIcon icon={faBars} className="text-xl" />
          </button>
          <div className="text-xl font-semibold">Real-Time Feedback</div>
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="h-16 w-auto" />
            <button className="ml-4" onClick={toggleDarkMode}>
              <FontAwesomeIcon icon={darkMode ? faSun : faMoon} className={`text-xl ${darkMode ? 'text-yellow-400' : 'text-gray-800'}`} />
            </button>
          </div>
        </header>
        <main className="flex-1 p-4 overflow-y-auto">
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <KPI title="Cancellation Rate " value={`12%`} darkMode={darkMode} icon={faExclamationCircle} />
          <KPI title="Repeat Guest Rate" value={`33.23%`} darkMode={darkMode} icon={faRedoAlt} />
          <KPI title="Average Hotel Rating" value={aggregatedData.averageHotelRating ? aggregatedData.averageHotelRating.toFixed(1) : '0.0'} darkMode={darkMode} icon={faStar} />
          <KPI title="Average Length of Stay" value={`${aggregatedData.averageLengthOfStay || 0} nights`} darkMode={darkMode} icon={faCalendarAlt} />
        </div>

          <div className="mt-6 grid grid-cols-1 lg:grid-cols-3 gap-6">
            <RoomTypeRevenueChart data={data} darkMode={darkMode} />
            <PopulationPyramidChart darkMode={darkMode} data={data} />
            <MealsSpendCard darkMode={darkMode} mealsData={data} />
          </div>
          <div className="mt-6 grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2">
              <HotelGuestsMap darkMode={darkMode} bookingData={data} />
            </div>
            <div className={`p-4 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800 text-gray-200' : 'bg-white text-gray-900'}`}>
              <h3 className="text-lg font-semibold mb-4 border-b pb-2">Top Countries by Revenue</h3>
              <ul className="space-y-2">
                {topCountriesByRevenue.map((country, index) => (
                  <li
                    key={index}
                    className="flex items-left justify-between rounded-md p-3"
                    style={{
                      borderLeft: `4px solid ${index === 0 ? '#FFD700' : index === 1 ? '#C0C0C0' : '#CD7F32'}`,
                      backgroundColor: darkMode ? '#3a3a3a' : '#f5f5f5',
                    }}
                  >
                    <div className="flex items-center space-x-2">
                      <MedalSVG type={index === 0 ? 'gold' : index === 1 ? 'silver' : 'bronze'} />
                      <ReactCountryFlag
                        countryCode={getCountryCode(country.country)}
                        svg
                        style={{ width: '2em', height: '2em' }}
                      />
                      <span className="ml-2 font-medium text-lg">{country.country}</span>
                    </div>
                    <div className="text-right">
                      <div className="font-bold text-xl">${country.revenue}</div>
                      <div className="text-sm text-gray-600">{country.bookings} bookings</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};



const calculateAverageHotelRating = (data) => {
  const totalRating = data.reduce((acc, booking) => acc + (booking.Hotel_Rating || 0), 0);
  const totalBookings = data.length || 1;
  const averageHotelRating = totalRating / totalBookings;
  return averageHotelRating;
};

const calculateAverageLengthOfStay = (data) => {
  const totalNights = data.reduce((acc, booking) => acc + (booking.room_nights || 0), 0);
  const totalBookings = data.length || 1;
  const averageLengthOfStay = totalNights / totalBookings;
  return averageLengthOfStay.toFixed(2);
};

export default VisitorPage;
