import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";

class ColumnChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: []
        }
      ],
      options: {
        backgroundColor: 'transparent',
        chart: {
          height: 350,
          type: 'rangeBar',
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            isDumbbell: true,
            columnWidth: 30,
            dumbbellColors: [['#008FFB', '#00E396', '#775DD0', '#FF4560']]
          }
        },
        legend: {
          show: true,
          showForSingleSeries: false,
          position: 'top',
          horizontalAlign: 'left',
          customLegendItems: [],
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: ['#008FFB', '#00E396', '#775DD0', '#FF4560'],
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            type: 'vertical',
            gradientToColors: ['#00E396'],
            inverseColors: true,
            stops: [0, 100]
          }
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          tickPlacement: 'on',
          labels: {
            style: {
              colors: '#fff'
            }
          }
        }
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      const chartData = this.props.data.map(item => ({
        x: item.cluster_name,
        y: [0, item.storage_used]
      }));

      this.setState({
        series: [
          {
            data: chartData
          }
        ],
        options: {
          ...this.state.options,
          legend: {
            ...this.state.options.legend,
            customLegendItems: this.props.data.map(item => item.cluster_name)
          }
        }
      });
    }
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} type="rangeBar" height={330} width="100%" />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default ColumnChart;
