import React from 'react';
import Chart from 'react-apexcharts';

const TotalBarChart = ({ data, title }) => {
  // Sort data in descending order to show the biggest values on top
  const sortedData = [...data].sort((a, b) => b.value - a.value);

  const options = {
    chart: {
      id: 'total-sales-bar-chart',
      type: 'bar',
      background: 'transparent',
      foreColor: '#FFFFFF',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '70%',
        distributed: true,
      }
    },
    xaxis: {
      categories: sortedData.map(item => item.name),
      labels: {
        style: {
          colors: sortedData.map(() => '#FFFFFF'),
          fontSize: '14px',
          fontFamily: 'Orbitron, sans-serif',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: ['#FFFFFF'],
          fontSize: '14px',
          fontFamily: 'Orbitron, sans-serif',
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#FFFFFF'],
        fontSize: '14px',
        fontFamily: 'Orbitron, sans-serif',
        fontWeight: 'bold',
      },
      formatter: function (val) {
        return val;
      }
    },
    legend: {
      show: false,
    },
    colors: ['#1E90FF', '#00CED1', '#4682B4', '#87CEEB', '#5F9EA0', '#6495ED', '#00BFFF', '#FF69B4', '#FF6347', '#FF4500'],
    title: {
      text: title,
      align: 'left',
      style: {
        fontSize: '26px',
        color: '#00BFFF',
        fontFamily: 'Orbitron, sans-serif',
        fontWeight: 'bold',
      }
    },
    grid: {
      borderColor: '#444444',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 280
          },
          title: {
            style: {
              fontSize: '20px'
            }
          }
        }
      }
    ]
  };

  const series = [{
    data: sortedData.map(item => item.value)
  }];

  return (
    <div className="p-6 rounded-3xl shadow-2xl bg-gray-900 transform transition-transform hover:scale-105 hover:shadow-lg hover:shadow-cyan-500/50" style={{ boxShadow: '0 0 20px #00BFFF' }}>
      <Chart options={options} series={series} type="bar" height="350" />
    </div>
  );
};

export default TotalBarChart;
