import React from 'react';
import ReactECharts from 'echarts-for-react';

const GaugeChart = ({ data, title }) => {
  const formattedData = parseFloat(data).toFixed(2);

  const option = {
    series: [
      {
        type: 'gauge',
        progress: {
          show: true,
          width: 18,
        },
        axisLine: {
          lineStyle: {
            width: 18,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          length: 15,
          lineStyle: {
            width: 2,
            color: '#999',
          },
        },
        axisLabel: {
          distance: 25,
          color: '#999',
          fontSize: 10,
        },
        anchor: {
          show: true,
          showAbove: true,
          size: 10,
          itemStyle: {
            borderWidth: 10,
          },
        },
        title: {
          show: false,
        },
        detail: {
          valueAnimation: true,
          fontSize: 40,
          offsetCenter: [0, '70%'],
          color: 'white',
          formatter: '{value}%', // Format the displayed value with a '%' sign
        },
        data: [
          {
            value: formattedData,
          },
        ],
      },
    ],
  };

  return (
    <>
      <h1 className='text-xl text-white mt-2 -mb-2'>{title}</h1>
      <ReactECharts style={{ display: "flex", justifyContent: "center" }} option={option} />
    </>
  );
};

export default GaugeChart;
