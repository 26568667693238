import React from 'react';
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import { FaBell, FaCamera, FaMapMarkerAlt, FaCalendarAlt, FaClock, FaExclamationTriangle } from 'react-icons/fa';

const useStyles = makeStyles({
  card: {
    width: '300px',
    height: '220px',
    margin: '10px',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
      transform: 'translateY(-10px)',
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '8px',
    backgroundColor: props => getBackgroundColor(props.severity),
    color: 'black',
  },
  header: {
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  icon: {
    marginRight: '5px',
    fontSize: '18px',
  },
  content: {
    padding: '10px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  footer: {
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  detailIcon: {
    marginRight: '5px',
  },
  backgroundIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    fontSize: '80px',
    color: 'rgba(0, 0, 0, 0.1)',
  },
});

const AlarmCard = ({ alarm }) => {
  const classes = useStyles(alarm);

  return (
    <Card className={classes.card}>
      <div className={classes.header}>
        <div className={classes.headerText}>
          <FaBell className={classes.icon} />
          {alarm.type}
        </div>
        <FaExclamationTriangle className={classes.icon} />
      </div>
      <CardContent className={classes.content}>
        <div className={classes.detail}>
          <FaCamera className={classes.detailIcon} />
          <Typography variant="body2" component="span">
            Camera: {alarm.camera}
          </Typography>
        </div>
        <div className={classes.detail}>
          <FaMapMarkerAlt className={classes.detailIcon} />
          <Typography variant="body2" component="span">
            Site: {alarm.site}
          </Typography>
        </div>
        <div className={classes.detail}>
          <FaCalendarAlt className={classes.detailIcon} />
          <Typography variant="body2" component="span">
            Date: {alarm.date}
          </Typography>
        </div>
        <div className={classes.detail}>
          <FaClock className={classes.detailIcon} />
          <Typography variant="body2" component="span">
            Time: {alarm.time}
          </Typography>
        </div>
      </CardContent>
      <div className={classes.footer}>
        <span>{alarm.comment}</span>
      </div>
      <FaBell className={classes.backgroundIcon} />
    </Card>
  );
};

const getBackgroundColor = (severity) => {
  switch (severity) {
    case 'Critical':
      return 'rgba(255, 82, 82, 0.2)'; // Very light red
    case 'High':
      return 'rgba(255, 193, 7, 0.2)'; // Very light yellow
    case 'Medium':
      return 'rgba(255, 152, 0, 0.2)'; // Very light orange
    case 'Low':
      return 'rgba(76, 175, 80, 0.2)'; // Very light green
    default:
      return 'rgba(96, 125, 139, 0.2)'; // Very light grey
  }
};

export default AlarmCard;
