import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the plugin for rounded bars and data labels
Chart.register(ChartDataLabels);
Chart.defaults.elements.bar.borderRadius = 20;

const BarChartComponentAa = ({ title, data, color, icon }) => {
  const chartData = {
    ...data,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      backgroundColor: color,
    })),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false, // Hide x-axis labels
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: false, // Hide y-axis labels
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.raw}`;
          },
        },
      },
      datalabels: {
        display: false, // Hide data labels
      },
    },
    animation: {
      duration: 1000, // Customize the animation duration
      easing: 'easeInOutQuart', // Customize the easing function
    },
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4 transform hover:scale-105 transition duration-300">
      <div className="flex items-center mb-4">
        {icon && <div className="mr-2">{icon}</div>}
        <h2 className="text-lg font-semibold text-gray-700">{title}</h2>
      </div>
      <div className="relative h-40">
        <Bar data={chartData} options={options} />
      </div>
    </div>
  );
};

export default BarChartComponentAa;
