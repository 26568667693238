import React, { useState, useEffect } from 'react';
import Hotel_Sidebar from './Hotel_Side_Bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faBars } from '@fortawesome/free-solid-svg-icons';
import ReservationStatusChart from '../../charts/Hotel_charts/ReservationStatusPieChart';
import KPI from '../../charts/Hotel_charts/KpiHotel';
import BookingTrendsChart from '../../charts/Hotel_charts/BookingLineChart';
import GuestActivityChart from '../../charts/Hotel_charts/GuestActivityLineChart';
import MonthlyRoomPerformanceChart from '../../charts/Hotel_charts/MonthlyRoomPerformanceBarChart';
import { faCheckCircle, faSignOutAlt, faBed, faHotel, faCalendarCheck, faUsers, faBan, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import logo from '../../img/color1_text-logoname_transparent_background.png';
import BookingChannelsChart from '../../charts/Hotel_charts/BookingChannelChart';

const Hotel_Financial_KPIs = () => {
  const [darkMode, setDarkMode] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [aggregatedData, setAggregatedData] = useState({});

  useEffect(() => {
    const fetchData = () => {
      fetch(`https://magnetoo.fr/hotel_data`)
        .then((response) => response.json())
        .then((data) => {
          console.log('Fetched data:', data);
          setData(data);
          aggregateData(data);
        })
        .catch((error) => console.error("Error fetching data:", error));
    };

    fetchData();
  }, []);

  const aggregateData = (data) => {
    const totalBookings = data.length;
    const totalRevenue = data.reduce((acc, booking) => acc + booking.total_room_revenue, 0).toFixed(2);
    const today = new Date().toISOString().split('T')[0];
    const todayCheckIns = data.filter(booking => booking.Check_in_date.startsWith(today)).length;
    const todayCheckOuts = data.filter(booking => booking.Check_Out_Date.startsWith(today)).length;
    const availableRooms = data.reduce((acc, booking) => acc + booking.rooms_sold, 0);
    const totalGuests = data.reduce((acc, booking) => acc + booking.No_of_People, 0);
    const cancelledBookings = data.filter(booking => booking.Booking_Status === 'Cancelled').length;

    setAggregatedData({
      totalBookings,
      totalRevenue,
      todayCheckIns,
      todayCheckOuts,
      availableRooms,
      totalGuests,
      cancelledBookings
    });
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (darkMode) {
      document.documentElement.classList.remove('dark');
    } else {
      document.documentElement.classList.add('dark');
    }
  };

  return (
    <div className={`flex h-screen ${darkMode ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-900'}`}>
      <Hotel_Sidebar darkMode={darkMode} isOpen={isOpen} />
      <div className="flex-1 flex flex-col">
        <header className="flex justify-between items-center p-4 shadow-md">
          <button className="md:hidden p-2" onClick={() => setIsOpen(!isOpen)}>
            <FontAwesomeIcon icon={faBars} className="text-xl" />
          </button>
          <div className="text-xl font-semibold">Overview</div>
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="h-16 w-auto" />
            <button className="ml-4" onClick={toggleDarkMode}>
              <FontAwesomeIcon icon={darkMode ? faSun : faMoon} className={`text-xl ${darkMode ? 'text-white' : 'text-gray-800'}`} />
            </button>
          </div>
        </header>
        <main className="flex-1 p-4 overflow-y-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <KPI title="Today's Check-Ins" value="45" darkMode={darkMode} icon={faCheckCircle} />
            <KPI title="Today's Check-Outs" value="36" darkMode={darkMode} icon={faSignOutAlt} />
            <KPI title="Available Rooms" value="124" darkMode={darkMode} icon={faBed} />
            <KPI title="Total Occupied Rooms" value="523" darkMode={darkMode} icon={faHotel} />
            <KPI title="Total Bookings" value={aggregatedData.totalBookings || "N/A"} darkMode={darkMode} icon={faCalendarCheck} />
            <KPI title="Total Guests" value={aggregatedData.totalGuests || "N/A"} darkMode={darkMode} icon={faUsers} />
            <KPI title="Cancelled Bookings" value={aggregatedData.cancelledBookings || "N/A"} darkMode={darkMode} icon={faBan} />
            <KPI title="Total Revenue" value={aggregatedData.totalRevenue ? `$${aggregatedData.totalRevenue}` : "N/A"} darkMode={darkMode} icon={faDollarSign} />
          </div>
          <div className="mt-6 grid grid-cols-1 lg:grid-cols-2 gap-6">
            <BookingTrendsChart darkMode={darkMode} bookingData={data} />
            <div className="h-64">
              <MonthlyRoomPerformanceChart darkMode={darkMode} bookingData={data} />
            </div>
   


          </div>
          <div className="mt-6 flex flex-wrap gap-6 w-full">
  <div className="flex-1 h-64">
    <GuestActivityChart darkMode={darkMode} bookingData={data} />
  </div>
  <div className="flex-1 h-64">
    <ReservationStatusChart darkMode={darkMode} bookingData={data} />
  </div>
  <div className="flex-1 h-64">
    <BookingChannelsChart darkMode={darkMode} bookingData={data} />
  </div>
</div>

        </main>
      </div>
    </div>
  );
};

export default Hotel_Financial_KPIs;
