import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';


const GuestsDistributionPieChart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [fontSize, setFontSize] = useState(14);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      let newFontSize = 14;

      if (windowWidth <= 768) {
        newFontSize = 10;
      } else if (windowWidth <= 576) {
        newFontSize = 8;
      } else if (windowWidth <= 425) {
        newFontSize = 6;
      }

      setFontSize(newFontSize);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const chartDom = chartRef.current;
    chartInstance.current = echarts.init(chartDom);
    const option = {
      title: {
        text: 'Guest Distribution by Category',
        left: '30',
        top:'20',
        textStyle: {
          color: '#fff',
          fontSize: `${fontSize}px`,
          fontWeight: 'bold'
        }
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'horizontal', // Change the orientation to horizontal for better alignment
        left: 'center', // Adjust the left position if needed
        bottom: '5%', // Move the legend to the bottom
        textStyle: {
          color: '#999',
          fontSize: `${fontSize - 2}px`
        },
        itemStyle: {
          borderWidth: 2, // Add border to the legend marker
          borderType: 'solid',
         // Set border color
          padding: [5, 10], // Adjust padding as needed
          borderRadius: 200, // Set borderRadius to a large value to make it a circle
        }
      },
      
      color: ['#4a4aff', '#7953d2', '#9c27b0'], // Variation of blue colors
      grid: {
        left: '10%', // Adjust the left value to position the grid on the left side
        top: '10%',
        right: '10%',
        bottom: '10%'
      },
      series: [
        {
          name: 'Category',
          type: 'pie',
          radius: ['40%', '60%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
            fontSize: `${fontSize}px`
          },
          emphasis: {
            label: {
              show: true,
              fontSize: `${fontSize}px`,
              fontWeight: 'bold',
              color: '#fff'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: 1048, name: 'Men' },
            { value: 735, name: 'Women' },
            { value: 580, name: 'Kids' },
            
          ]
        }
      ]
    };

    chartInstance.current.setOption(option);

    return () => {
      chartInstance.current.dispose();
    };
  }, [fontSize]);

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

export default GuestsDistributionPieChart;
