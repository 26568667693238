import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const RetailSalesCostProfitChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    let myChart = echarts.init(chartRef.current);

    const months = Array.from({ length: 12 }, (_, i) => `Month ${i + 1}`);
    const retailSalesData = Array.from({ length: 12 }, () => Math.round(Math.random() * 1000));
    const costData = Array.from({ length: 12 }, () => Math.round(Math.random() * 500) + 500);
    const profitData = retailSalesData.map((value, index) => value - costData[index]);

    const option = {
      backgroundColor: 'transparent',
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        textStyle: {
          color: '#FFD700' // tropical yellow
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderColor: '#FFD700',
        borderWidth: 1
      },
      legend: {
        data: ['Retail Sales', 'Cost', 'Profit'],
        textStyle: {
          color: '#FFD700' // tropical yellow
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '15%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: months,
        axisLine: {
          lineStyle: {
            color: '#B0BEC5' // light grey
          }
        },
        axisLabel: {
          color: '#B0BEC5' // light grey
        }
      },
      yAxis: {
        type: 'value',
        name: 'Amount',
        axisLine: {
          lineStyle: {
            color: '#B0BEC5' // light grey
          }
        },
        axisLabel: {
          color: '#B0BEC5' // light grey
        },
        splitLine: {
          lineStyle: {
            color: '#FFE4B5' // light tropical color for split lines
          }
        }
      },
      series: [
        {
          name: 'Retail Sales',
          type: 'bar',
          stack: 'total',
          data: retailSalesData,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#8A2BE2' }, // blue violet
                { offset: 1, color: '#d3a6f7' }  // bright yellow
              ]
            ),
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: 10
          }
        },
        {
          name: 'Cost',
          type: 'bar',
          stack: 'total',
          data: costData,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#d02070' }, // medium purple
                { offset: 1, color: '#f4794e' }  // bright yellow
              ]
            ),
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: 10
          }
        },
        {
          name: 'Profit',
          type: 'bar',
          stack: 'total',
          data: profitData,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#2083d0' }, // medium orchid
                { offset: 1, color: '#c0d6e7' }  // bright yellow
              ]
            ),
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: 10
          }
        }
      ]
    };

    myChart.setOption(option);

    const resizeChart = () => {
      myChart.resize();
    };

    window.addEventListener('resize', resizeChart);

    return () => {
      window.removeEventListener('resize', resizeChart);
      myChart.dispose();
    };
  }, []);

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

export default RetailSalesCostProfitChart;
