import React from 'react';

const AverageTimeCard = ({ data }) => {
  const responseTimes = data
    .filter(item => item['Heures\nDEB'] && item['Heures\nFIN'])
    .map(item => {
      const start = new Date(`1970-01-01T${item['Heures\nDEB']}`);
      const end = new Date(`1970-01-01T${item['Heures\nFIN']}`);
      return (end - start) / 60000; // Convert milliseconds to minutes
    });

  const averageResponseTime = responseTimes.length
    ? (responseTimes.reduce((a, b) => a + b, 0) / responseTimes.length).toFixed(2)
    : 'N/A';

  return (
    <div className="bg-gray-900 p-4 rounded-lg shadow-lg flex items-center justify-between transform hover:scale-105 transition-transform duration-300 h-28 w-85">
      <div className="text-white animate-bounce">
        <i className="fas fa-clock text-3xl"></i>
      </div>
      <div className="text-center flex-1">
        <h3 className="text-lg font-bold text-white mb-1 text-center">Average Time Spent (minutes)</h3>
        <p className="text-3xl font-extrabold text-yellow-300 text-center">{averageResponseTime}</p>
      </div>
    </div>
  );
};

export default AverageTimeCard;
