import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: '1cf943ed-1850-4a45-87ef-8a87a055ea07',
    authority: 'https://login.microsoftonline.com/23b453d1-45b8-4cd1-9c07-fb3a527a43ac',
    redirectUri: 'https://magnetoo.tech/landingpage',
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const getStoredAuthState = () => {
  const account = sessionStorage.getItem('msal.account.keys');
  return account ? JSON.parse(sessionStorage.getItem(
    JSON.parse(account)[0]
  )) : null;
};
