import React, { useEffect, useState, useMemo, useCallback } from "react";
import Navbar from "../../components/Navbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faHandsHelping, faClock, faUsers, faChartLine, faBolt } from '@fortawesome/free-solid-svg-icons';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    BarChart, Bar, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, LineChart, Line
} from 'recharts';
import logo from "../../img/1200px-Écusson_garde_nationale,_Tunisie.svg.png";
import { MapContainer, TileLayer, CircleMarker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import TimelineAreaChart from "../../charts/Call_center_charts/Timelineareachart";
import TimelineAreaMonthChart from "../../charts/Call_center_charts/Timelinemonthareachart";

const CallCenter = () => {
    const [calls, setCalls] = useState([]);
    const [filteredCalls, setFilteredCalls] = useState([]);
    const [error, setError] = useState(null);
    const data = [
        { day: 'Monday', avgHandlingTime: 30 },
        { day: 'Tuesday', avgHandlingTime: 25 },
        { day: 'Wednesday', avgHandlingTime: 35 },
        { day: 'Thursday', avgHandlingTime: 20 },
        { day: 'Friday', avgHandlingTime: 40 },
        { day: 'Saturday', avgHandlingTime: 45 },
        { day: 'Sunday', avgHandlingTime: 28 },
    ];
    const [filter, setFilter] = useState({
        nightCalls: false,
        weekendCalls: false,
        holidayCalls: false,
        month: "",
        dayOfWeek: "",
        startDate: "",
        endDate: "",
        maxAcceptableWaitTime: 10,
        maxAcceptableHandlingTime: 5,
        maxAcceptableInterventionTime: 20
    });

    const navigate = useNavigate();

    useEffect(() => {
        fetch("https://magnetoo.fr/call_data")
            .then(response => {
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                return response.json();
            })
            .then(data => {
                if (Array.isArray(data)) {
                    setCalls(data);
                    setFilteredCalls(data);
                } else {
                    throw new Error("Data format is incorrect");
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);
                setError(error.message);
            });
    }, []);

    useEffect(() => {
        const filteredData = calls.filter(call => {
            if (!call) return false;

            if (filter.nightCalls && call.day_period !== "night") {
                return false;
            }
            if (filter.weekendCalls && !call.is_weekend) {
                return false;
            }
            if (filter.holidayCalls && !call.is_holiday) {
                return false;
            }
            if (filter.month && call.month !== filter.month) {
                return false;
            }
            if (filter.dayOfWeek && call.day_of_week !== filter.dayOfWeek) {
                return false;
            }
            if (filter.startDate && filter.endDate) {
                const startDate = new Date(filter.startDate);
                const endDate = new Date(filter.endDate);
                const callDate = new Date(call.date);
                if (callDate < startDate || callDate > endDate) {
                    return false;
                }
            }
            return true;
        });
        setFilteredCalls(filteredData);
    }, [calls, filter]);

    const handleFilterChange = useCallback(event => {
        const { name, value, checked, type } = event.target;
        const newValue = type === "checkbox" ? checked : value;
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: newValue
        }));
    }, []);

    const calculateAverage = useCallback((arr) => {
        if (arr.length === 0) return 0;
        const sum = arr.reduce((acc, val) => acc + val, 0);
        return (sum / arr.length).toFixed(2);
    }, []);

    const totalCalls = filteredCalls.length;
    const totalInterventions = filteredCalls.filter(call => call && call.call_type === 'intervention').length;
    const totalNonUrgent = filteredCalls.filter(call => call && call.call_type === 'non_urgent').length;
    const totalAbusive = filteredCalls.filter(call => call && call.call_type === 'abusive').length;
    const avgWaitTime = calculateAverage(filteredCalls.map(call => call.wait_time));
    const avgHandlingTime = calculateAverage(filteredCalls.map(call => call.handling_time));
    const avgInterventionTime = calculateAverage(filteredCalls.filter(call => call.intervention_time).map(call => call.intervention_time));
    const longWaitCalls = filteredCalls.filter(call => call.wait_time > filter.maxAcceptableWaitTime).length;
    const unacceptableWaitCalls = filteredCalls.filter(call => call.wait_time > (filter.maxAcceptableWaitTime + 10)).length;

    const timelineData = filteredCalls.map(call => ({
        date: new Date(call.date).toLocaleString(),
        wait_time: call.wait_time,
        handling_time: call.handling_time,
        intervention_time: call.intervention_time,
        call_type: call.call_type
    }));

    const callTimesByHour = useMemo(() => {
        const grouped = filteredCalls.reduce((acc, call) => {
            const hour = new Date(call.date).getHours();
            if (!acc[hour]) acc[hour] = [];
            acc[hour].push(call.wait_time);
            return acc;
        }, {});
        return Object.keys(grouped).map(hour => ({
            hour,
            avgWaitTime: calculateAverage(grouped[hour])
        }));
    }, [filteredCalls, calculateAverage]);

    const handlingTimesByPeriod = useMemo(() => {
        const periods = [
            { name: 'Morning', start: 5, end: 12 },
            { name: 'Afternoon', start: 12, end: 17 },
            { name: 'Evening', start: 17, end: 21 },
            { name: 'Night', start: 21, end: 5 }
        ];

        const grouped = periods.map(period => {
            const callsInPeriod = filteredCalls.filter(call => {
                const hour = new Date(call.date).getHours();
                if (period.name === 'Night') {
                    return hour >= period.start || hour < period.end;
                }
                return hour >= period.start && hour < period.end;
            });

            return {
                period: period.name,
                avgHandlingTime: calculateAverage(callsInPeriod.map(call => call.handling_time))
            };
        });

        return grouped;
    }, [filteredCalls, calculateAverage]);

    const callTimesByDay = useMemo(() => {
        const grouped = filteredCalls.reduce((acc, call) => {
            const day = new Date(call.date).getDay();
            if (!acc[day]) acc[day] = [];
            acc[day].push(call.wait_time);
            return acc;
        }, {});
        return Object.keys(grouped).map(day => ({
            day,
            avgWaitTime: calculateAverage(grouped[day])
        }));
    }, [filteredCalls, calculateAverage]);
    

    const handlingTimesByDay = useMemo(() => {
        const grouped = filteredCalls.reduce((acc, call) => {
            const day = new Date(call.date).getDay();
            if (!acc[day]) acc[day] = [];
            acc[day].push(call.handling_time);
            return acc;
        }, {});
        return Object.keys(grouped).map(day => ({
            day,
            avgHandlingTime: calculateAverage(grouped[day])
        }));
    }, [filteredCalls, calculateAverage]);

    const interventionTimesByHour = useMemo(() => {
        const grouped = filteredCalls.reduce((acc, call) => {
            const hour = new Date(call.date).getHours();
            if (!acc[hour]) acc[hour] = [];
            acc[hour].push(call.intervention_time);
            return acc;
        }, {});
        return Object.keys(grouped).map(hour => ({
            hour,
            avgInterventionTime: calculateAverage(grouped[hour])
        }));
    }, [filteredCalls, calculateAverage]);

    const recentCalls = filteredCalls.slice(-10).reverse();

    return (
        <div className="min-h-screen bg-gray-900 text-gray-100 p-6 relative">
            <div className="absolute inset-0 flex justify-center items-center">
                <img src={logo} alt="Logo" className="opacity-10 w-1/3 h-1/3 object-contain" />
            </div>
            <div className="relative">
                <Navbar navigate={navigate} />
                <div className="text-center py-6">
                    <h1 className="text-5xl font-bold mb-3 text-indigo-400">Police Call Supervision Dashboard</h1>
                    <p className="text-xl text-gray-300">Comprehensive view of call center statistics and performance</p>
                </div>

                <div className="bg-gray-800 p-6 rounded-lg shadow-md mb-12">
                    <h2 className="text-3xl font-semibold mb-5 text-indigo-400">Filters</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-inner">
                            <input
                                type="checkbox"
                                name="nightCalls"
                                checked={filter.nightCalls}
                                onChange={handleFilterChange}
                                className="mr-2 h-4 w-4 text-indigo-400 bg-gray-600 border-gray-500 rounded focus:ring-indigo-500 focus:ring-opacity-50"
                            />
                            <label className="text-lg text-gray-300 font-semibold">Night Calls</label>
                        </div>
                        <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-inner">
                            <input
                                type="checkbox"
                                name="weekendCalls"
                                checked={filter.weekendCalls}
                                onChange={handleFilterChange}
                                className="mr-2 h-4 w-4 text-indigo-400 bg-gray-600 border-gray-500 rounded focus:ring-indigo-500 focus:ring-opacity-50"
                            />
                            <label className="text-lg text-gray-300 font-semibold">Weekend Calls</label>
                        </div>
                        <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-inner">
                            <input
                                type="checkbox"
                                name="holidayCalls"
                                checked={filter.holidayCalls}
                                onChange={handleFilterChange}
                                className="mr-2 h-4 w-4 text-indigo-400 bg-gray-600 border-gray-500 rounded focus:ring-indigo-500 focus:ring-opacity-50"
                            />
                            <label className="text-lg text-gray-300 font-semibold">Holiday Calls</label>
                        </div>
                        <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-inner">
                            <label className="mr-2 text-lg text-gray-300 font-semibold">Month:</label>
                            <select name="month" value={filter.month} onChange={handleFilterChange} className="p-2 rounded-md bg-gray-600 text-gray-300 border-gray-500 focus:ring-indigo-500 focus:ring-opacity-50">
                                <option value="">All</option>
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                            </select>
                        </div>
                        <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-inner">
                            <label className="mr-2 text-lg text-gray-300 font-semibold">Day of Week:</label>
                            <select name="dayOfWeek" value={filter.dayOfWeek} onChange={handleFilterChange} className="p-2 rounded-md bg-gray-600 text-gray-300 border-gray-500 focus:ring-indigo-500 focus:ring-opacity-50">
                                <option value="">All</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                                <option value="Saturday">Saturday</option>
                                <option value="Sunday">Sunday</option>
                            </select>
                        </div>
                        <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-inner">
                            <label className="mr-2 text-lg text-gray-300 font-semibold">Start Date:</label>
                            <input
                                type="date"
                                name="startDate"
                                value={filter.startDate}
                                onChange={handleFilterChange}
                                className="p-2 rounded-md bg-gray-600 text-gray-300 border-gray-500 focus:ring-indigo-500 focus:ring-opacity-50"
                            />
                        </div>
                        <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-inner">
                            <label className="mr-2 text-lg text-gray-300 font-semibold">End Date:</label>
                            <input
                                type="date"
                                name="endDate"
                                value={filter.endDate}
                                onChange={handleFilterChange}
                                className="p-2 rounded-md bg-gray-600 text-gray-300 border-gray-500 focus:ring-indigo-500 focus:ring-opacity-50"
                            />
                        </div>
                        <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-inner">
                            <label className="mr-2 text-lg text-gray-300 font-semibold">Max Acceptable Wait Time (min):</label>
                            <input
                                type="number"
                                name="maxAcceptableWaitTime"
                                value={filter.maxAcceptableWaitTime}
                                onChange={handleFilterChange}
                                className="p-2 rounded-md bg-gray-600 text-gray-300 border-gray-500 focus:ring-indigo-500 focus:ring-opacity-50"
                            />
                        </div>
                        <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-inner">
                            <label className="mr-2 text-lg text-gray-300 font-semibold">Max Acceptable Handling Time (min):</label>
                            <input
                                type="number"
                                name="maxAcceptableHandlingTime"
                                value={filter.maxAcceptableHandlingTime}
                                onChange={handleFilterChange}
                                className="p-2 rounded-md bg-gray-600 text-gray-300 border-gray-500 focus:ring-indigo-500 focus:ring-opacity-50"
                            />
                        </div>
                        <div className="flex items-center bg-gray-700 p-3 rounded-md shadow-inner">
                            <label className="mr-2 text-lg text-gray-300 font-semibold">Max Acceptable Intervention Time (min):</label>
                            <input
                                type="number"
                                name="maxAcceptableInterventionTime"
                                value={filter.maxAcceptableInterventionTime}
                                onChange={handleFilterChange}
                                className="p-2 rounded-md bg-gray-600 text-gray-300 border-gray-500 focus:ring-indigo-500 focus:ring-opacity-50"
                            />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
                    <div className="bg-gray-800 p-6 rounded-lg shadow-md flex items-center">
                        <div className="flex-grow">
                            <span className="text-sm font-medium text-gray-400">Total Calls</span>
                            <h2 className="text-2xl font-bold text-gray-100">{totalCalls}</h2>
                        </div>
                        <FontAwesomeIcon icon={faPhoneAlt} size="2x" className="text-indigo-400" />
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg shadow-md flex items-center">
                        <div className="flex-grow">
                            <span className="text-sm font-medium text-gray-400">Interventions</span>
                            <h2 className="text-2xl font-bold text-gray-100">{totalInterventions}</h2>
                        </div>
                        <FontAwesomeIcon icon={faHandsHelping} size="2x" className="text-indigo-400" />
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg shadow-md flex items-center">
                        <div className="flex-grow">
                            <span className="text-sm font-medium text-gray-400">Non-Urgent Calls</span>
                            <h2 className="text-2xl font-bold text-gray-100">{totalNonUrgent}</h2>
                        </div>
                        <FontAwesomeIcon icon={faUsers} size="2x" className="text-indigo-400" />
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg shadow-md flex items-center">
                        <div className="flex-grow">
                            <span className="text-sm font-medium text-gray-400">Abusive Calls</span>
                            <h2 className="text-2xl font-bold text-gray-100">{totalAbusive}</h2>
                        </div>
                        <FontAwesomeIcon icon={faChartLine} size="2x" className="text-indigo-400" />
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg shadow-md flex items-center">
                        <div className="flex-grow">
                            <span className="text-sm font-medium text-gray-400">Avg Wait Time (min)</span>
                            <h2 className="text-2xl font-bold text-gray-100">{avgWaitTime}</h2>
                        </div>
                        <FontAwesomeIcon icon={faClock} size="2x" className="text-indigo-400" />
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg shadow-md flex items-center">
                        <div className="flex-grow">
                            <span className="text-sm font-medium text-gray-400">Avg Handling Time (min)</span>
                            <h2 className="text-2xl font-bold text-gray-100">{avgHandlingTime}</h2>
                        </div>
                        <FontAwesomeIcon icon={faBolt} size="2x" className="text-indigo-400" />
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg shadow-md flex items-center">
                        <div className="flex-grow">
                            <span className="text-sm font-medium text-gray-400">Avg Intervention Time (min)</span>
                            <h2 className="text-2xl font-bold text-gray-100">{avgInterventionTime}</h2>
                        </div>
                        <FontAwesomeIcon icon={faHandsHelping} size="2x" className="text-indigo-400" />
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg shadow-md flex items-center">
                        <div className="flex-grow">
                            <span className="text-sm font-medium text-gray-400">Long Wait Calls</span>
                            <h2 className="text-2xl font-bold text-gray-100">{longWaitCalls}</h2>
                        </div>
                        <FontAwesomeIcon icon={faClock} size="2x" className="text-indigo-400" />
                    </div>
                    <div className="bg-gray-800 p-6 rounded-lg shadow-md flex items-center">
                        <div className="flex-grow">
                            <span className="text-sm font-medium text-gray-400">Unacceptable Wait Calls</span>
                            <h2 className="text-2xl font-bold text-gray-100">{unacceptableWaitCalls}</h2>
                        </div>
                        <FontAwesomeIcon icon={faClock} size="2x" className="text-indigo-400" />
                    </div>
                </div>

                {/* Map and Table Section */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md mt-12">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        <div className="relative">
                            <h2 className="text-3xl font-semibold mb-5 text-indigo-400">Last Calls Location</h2>
                            <MapContainer center={[34.0, 9.0]} zoom={6} className="w-full h-96">
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                />
                                {recentCalls.map((call, index) => (
                                    <CircleMarker
                                        key={index}
                                        center={[call.latitude, call.longitude]}
                                        radius={10}
                                        fillColor="#3572EF"
                                        color="#3572EF"
                                        fillOpacity={0.5}
                                        className="animate-pulse"
                                    >
                                        <Popup>
                                            <div>
                                                <p><strong>Date:</strong> {new Date(call.date).toLocaleString()}</p>
                                                <p><strong>Type:</strong> {call.call_type}</p>
                                                <p><strong>Wait Time:</strong> {call.wait_time} min</p>
                                                <p><strong>Handling Time:</strong> {call.handling_time} min</p>
                                                {call.intervention_time && <p><strong>Intervention Time:</strong> {call.intervention_time} min</p>}
                                            </div>
                                        </Popup>
                                    </CircleMarker>
                                ))}
                            </MapContainer>
                        </div>

                        {/* Table Section */}
                        <div className="relative">
                            <h2 className="text-3xl font-semibold mb-5 text-indigo-400">Recent Call Details</h2>
                            <div className="overflow-auto h-96">
                                <table className="min-w-full bg-gray-900 text-gray-400">
                                    <thead>
                                        <tr>
                                            <th className="py-3 px-6 text-left">Date</th>
                                            <th className="py-3 px-6 text-left">Type</th>
                                            <th className="py-3 px-6 text-left">Wait Time</th>
                                            <th className="py-3 px-6 text-left">Handling Time</th>
                                            <th className="py-3 px-6 text-left">Intervention Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recentCalls.map((call, index) => (
                                            <tr key={index} className="border-b border-gray-700">
                                                <td className="py-3 px-6">{new Date(call.date).toLocaleString()}</td>
                                                <td className="py-3 px-6">{call.call_type}</td>
                                                <td className="py-3 px-6">{call.wait_time}</td>
                                                <td className="py-3 px-6">{call.handling_time}</td>
                                                <td className="py-3 px-6">{call.intervention_time}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Call Times by Hour */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md mt-12 grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <h2 className="text-3xl font-semibold mb-5 text-indigo-400">Call Duration by Hour</h2>callTimesByHour
                        <ResponsiveContainer width="100%" height={400}>
                        <AreaChart
    data={callTimesByHour}
    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
  >
    <defs>
      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
      </linearGradient>
    </defs>
    <XAxis dataKey="hour" tick={{ fill: '#ffffff' }} />
    <YAxis tick={{ fill: '#ffffff' }} />
    <Tooltip contentStyle={{ backgroundColor: '#333', border: 'none' }} />
    <Legend verticalAlign="top" align="right" wrapperStyle={{ color: '#ffffff' }} />
    <Area 
      type="monotone" 
      dataKey="avgWaitTime" 
      stroke="#82ca9d" 
      fillOpacity={1} 
      fill="url(#colorUv)" 
      name="Duration Time" 
    />
  </AreaChart>
                        </ResponsiveContainer>
                    </div>
                    <div>
                        <h2 className="text-3xl font-semibold mb-5 text-indigo-400">Detailed Call Times per hour</h2>
                        <ResponsiveContainer width="100%" height={400}>
                        <TimelineAreaChart data={timelineData}/>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Handling Times by Period */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md mt-12 grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <h2 className="text-3xl font-semibold mb-5 text-indigo-400">Handling Times by Period</h2>
                        <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                data={handlingTimesByPeriod}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0.1} />
                    </linearGradient>
                </defs>
                <Bar dataKey="avgHandlingTime" fill="url(#colorUv)" />
                <XAxis dataKey="period" tick={{ fill: '#8884d8' }} />
                <YAxis tick={{ fill: '#8884d8' }} />
                <Tooltip cursor={{ fill: 'transparent' }} />
                <Legend />
            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div style={{ width: '100%', height: 400 }}>
                        <h2 className="text-3xl font-semibold mb-5 text-indigo-400">Detailed Call Times by Month</h2>
                        <ResponsiveContainer>
                       < TimelineAreaMonthChart data={timelineData}/>
                        </ResponsiveContainer>
                    </div>
                </div>

               

                {/* Table for last 10 records */}
                <div className="bg-gray-800 p-6 rounded-lg shadow-md mt-12">
                    <h2 className="text-3xl font-semibold mb-5 text-indigo-400">Last 10 Calls</h2>
                    <table className="min-w-full bg-gray-900 text-gray-400">
                        <thead>
                            <tr>
                                <th className="py-3 px-6 text-left">Date</th>
                                <th className="py-3 px-6 text-left">Type</th>
                                <th className="py-3 px-6 text-left">Wait Time</th>
                                <th className="py-3 px-6 text-left">Handling Time</th>
                                <th className="py-3 px-6 text-left">Intervention Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCalls.slice(-10).reverse().map((call, index) => (
                                <tr key={index} className="border-b border-gray-700">
                                    <td className="py-3 px-6">{new Date(call.date).toLocaleString()}</td>
                                    <td className="py-3 px-6">{call.call_type}</td>
                                    <td className="py-3 px-6">{call.wait_time}</td>
                                    <td className="py-3 px-6">{call.handling_time}</td>
                                    <td className="py-3 px-6">{call.intervention_time}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CallCenter;
