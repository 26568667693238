import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

// Custom hook to get window size
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Call handleResize initially to get the current window size
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};

const TotalPersoninout = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const windowSize = useWindowSize();

  const initializeChart = () => {
    if (chartRef.current) {
      const renderedInstance = echarts.getInstanceByDom(chartRef.current);
      if (renderedInstance) {
        chartInstance.current = renderedInstance;
      } else {
        chartInstance.current = echarts.init(chartRef.current);
      }
      setChartOptions();
    }
  };

  const setChartOptions = () => {
    let fontSize = 14;

    if (windowSize.width <= 768) {
      fontSize = 12;
    }

    if (windowSize.width <= 576) {
      fontSize = 10;
    }

    if (windowSize.width <= 425) {
      fontSize = 8;
    }

    const option = {
      title: {
        text: 'Hotel Visitor Traffic',
        textStyle: {
          color: '#fff',
          fontSize: fontSize,
        },
        left: '30',
        top: '20',
      },
      color: ['#55E2E9', '#EE6666'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },
        textStyle: {
          color: '#000', // Change text color to black when hovering
        },
      },
      legend: {
        top: '20',
        right: '5%',
        textStyle: {
          color: '#C0C0C0',
          fontSize: fontSize - 2,
        },
      },
      grid: {
        top: 80,
        bottom: 50,
        left: '3%',
        right: '10%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          axisLabel: {
            color: '#999',
            fontSize: fontSize - 4,
          },
          axisLine: {
            lineStyle: {
              color: '#999',
            },
          },
          splitLine: {
            show: false, // Hide vertical grid lines
            lineStyle: {
              color: '#999',
            },
          },
        },
      ],
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#999',
          fontSize: fontSize - 4,
        },
        axisLine: {
          lineStyle: {
            color: '#999',
          },
        },
        splitLine: {
          lineStyle: {
            color: '#999',
          },
        },
      },
      series: [
        {
          name: 'Guests Checked In',
          type: 'line',
          smooth: true,
          emphasis: {
            focus: 'series',
          },
          data: [320, 302, 341, 374, 390, 450, 420, 432, 401, 454, 332, 301],
        },
        {
          name: 'Guests Checked Out',
          type: 'line',
          smooth: true,
          emphasis: {
            focus: 'series',
          },
          lineStyle: {
            type: 'dotted',
          },
          data: [220, 182, 191, 234, 290, 330, 310, 123, 442, 321, 90, 149],
        },
      ],
    };

    chartInstance.current.setOption(option);
  };

  useEffect(() => {
    initializeChart();
    window.addEventListener('resize', setChartOptions);

    return () => {
      window.removeEventListener('resize', setChartOptions);
      chartInstance.current && chartInstance.current.dispose();
    };
  }, [windowSize]);

  return (
    <div
      ref={chartRef}
      className="w-full h-full"
      style={{ width: '100%', height: '100%' }}
    ></div>
  );
};

export default TotalPersoninout;
