import { useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';

const PopupAlert = ({ onAlert }) => {
  const toast = useToast();  // Correct placement of useToast

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://magnetoo.fr/data');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const highCpuServer = data.find(server => server.cpu_usage > 95);
        if (highCpuServer) {
          toast({
            
            title: "High CPU usage",
            description: `Server: ${highCpuServer.server_type} in  ${highCpuServer.cluster_name} is ${highCpuServer.cpu_usage}%! `,
            status: "error",
            position: "top-right",
            isClosable: true,
            duration: 9000,
            containerStyle: {
                maxWidth: '200px',
             // Makes the toast smaller
                opacity: 0.6, // Lowers the opacity
                fontSize: '0.8em' // Smaller text size for better responsiveness
              }
          });
          onAlert({
            clusterName: highCpuServer.cluster_name,
            serverType: highCpuServer.server_type,
            cpuUsage: highCpuServer.cpu_usage
          });
        }
      } catch (error) {
    
        
      }
    };

    const interval = setInterval(fetchData, 5000); // Interval at which the alert is checked

    return () => clearInterval(interval);
  }, [onAlert, toast]); // Include toast in the dependency array

  return null;
};

export default PopupAlert;
