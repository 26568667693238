import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

const BarCameraChart = () => {
  const chartRef = useRef(null);
  const [buildingGroups, setBuildingGroups] = useState({});
  const myChart = useRef(null); // Create a mutable ref for myChart

  useEffect(() => {
    myChart.current = echarts.init(chartRef.current); // Assign echarts instance to myChart ref

    const fetchData = () => {
      fetch("https://magnetoo.fr/camera-api-data")
        .then((response) => response.json())
        .then((data) => {
          // Initialize counts for each category
          const categoryCounts = {
            'Khalifa': 0,
            'AlSadd': 0,
            'Al Jazeera': 0,
            'Mesaimeer': 0,
            'Others': 0
          };

          // Process each data item
          data.forEach(item => {
            if (item.buildingGroup) {
              if (item.buildingGroup.startsWith('Khalifa') || item.buildingGroup.startsWith('kha')) {
                categoryCounts['Khalifa'] += 1;
              } else if (item.buildingGroup.startsWith('AlSadd')) {
                categoryCounts['AlSadd'] += 1;
              } else if (item.buildingGroup.startsWith('/QP')|| item.buildingGroup.startsWith('QP')) {
                categoryCounts['Al Jazeera'] += 1;
              } else if (item.buildingGroup.startsWith('/MESA') || item.buildingGroup.startsWith('MESA') || item.buildingGroup.startsWith('SAFE')) {
                categoryCounts['Mesaimeer'] += 1;
              } else {
                categoryCounts['Others'] += 1;
              }
            }
          });

          // Convert categoryCounts to an array, sort it, then convert back to an object
          const sortedCategoryCounts = Object.entries(categoryCounts)
            .sort((a, b) => b[1] - a[1]) // Sort in descending order
            .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

          setBuildingGroups(sortedCategoryCounts);
        })
        .catch((error) => console.error("Error fetching data:", error));
    };

    fetchData();
    const intervalId = setInterval(fetchData, 1000);

    return () => {
      clearInterval(intervalId);
      myChart.current.dispose();
    };
  }, []);

  useEffect(() => {
    const option = {
      responsive: true, // Enable responsive mode
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: '{b}: {c}'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLine: {
          show: false // Hide the axis line
        },
        splitLine: {
          show: false // Hide the grid lines
        },
        axisTick: {
          show: false // Hide the axis ticks
        },
        axisLabel: {
          color: 'white', // Change the label text color to white
          // You can add other styling options for the label text here
        },
      },
      yAxis: {
        type: 'category',
        data: Object.keys(buildingGroups)
      },
      axisLabel: {
        color: 'white', // Change the label text color to white
        // You can add other styling options for the label text here
      },
      series: [
        {
          type: 'bar',
          data: Object.values(buildingGroups),
          itemStyle: {
            borderRadius: [0, 10, 10, 0],
            color: function(params) {
              // Define bar colors here based on category
              const colorMap = {
                'Khalifa': '#5470C6',
                'AlSadd': '#91CC75',
                'Al Jazeera': '#EE6666',
                'Mesaimeer': '#8b5cf6',
                'Others': '#FC8452'
              };
              return colorMap[params.name] || '#FFFFFF';
            }
          }
        }
      ]
    };

    myChart.current.setOption(option);
  }, [buildingGroups]);

  return <div ref={chartRef} style={{ height: '500px', width: '100%' }}></div>;
};

export default BarCameraChart;
