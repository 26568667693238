import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SummaryCard = ({ icon, title, value, description, bgColor, textColor }) => {
  return (
    <div className={`flex flex-col items-start justify-center p-6 rounded-lg ${bgColor} shadow-md transform hover:scale-105 transition duration-300`}>
      <div className={`text-2xl ${textColor} mb-2`}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="text-left">
        <p className="text-base font-bold text-gray-800 mb-2">{value}</p>
        <p className="text-sm font-semibold text-gray-600 mb-2">{title}</p>
        <p className="text-xs text-blue-500 mb-2">{description}</p>
      </div>
    </div>
  );
};

export default SummaryCard;
