import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const HotelChart = ({ hotelData }) => {
  
  useEffect(() => {
    const chartDom = document.getElementById('main');
    const myChart = echarts.init(chartDom);

    const option = {
      title: {
        text: 'Hotel Visitor Traffic',
        textStyle: {
          color: '#fff',
          fontSize: "14",
        },
        left: '30',
        top: '20',
      },
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisLine: {
          lineStyle: {
            color: '#fff',
          },
        },
        axisLabel: {
          color: '#fff',
          fontSize: 14,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#fff',
          },
        },
        axisLabel: {
          color: '#fff',
          fontSize: 14,
          margin: 10, // Adding margin to the y-axis label
        },
        splitLine: {
          lineStyle: {
            color: '#333',
            type: 'dashed',
          },
        },
      },
      series: [
        {
          data: hotelData || [120, 200, 150, 80, 70, 110, 130],
          type: 'bar',
          barWidth: '35%',
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  { offset: 0, color: '#00416B' },
                  { offset: 1, color: '#4A94D1' }
                ]
              ),
              barBorderRadius: [5, 5, 0, 0],
            },
          },
        },
      ],
      grid: {
        top: '15%',
        left: '8%', // Adjusting left padding to accommodate y-axis label
        right: '5%',
        bottom: '10%',
        show: false,
      },
    };

    const resizeChart = () => {
      myChart.resize();
    };

    window.addEventListener('resize', resizeChart);

    myChart.setOption(option);

    return () => {
      window.removeEventListener('resize', resizeChart);
      myChart.dispose();
    };
  }, [hotelData]);

  return <div id="main" style={{ width: '100%', height: '100%' }} />;
};

export default HotelChart;
