import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';

function AvgDurationPerHourChart({ data }) {
    const chartData = {
        labels: data.map(item => new Date(item[0])),
        datasets: [
            {
                label: 'Average Duration per Hour (seconds)',
                data: data.map(item => item[1]),
                fill: false,
                borderColor: 'rgba(54,162,235,1)',
                backgroundColor: 'rgba(54,162,235,0.4)',
                tension: 0.4,
                pointRadius: 6,
                pointHoverRadius: 10,
                pointBackgroundColor: 'rgba(54,162,235,1)',
                pointBorderColor: 'rgba(255,255,255,1)',
                pointBorderWidth: 2,
            }
        ]
    };

    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        const date = tooltipItems[0].label;
                        return `Date: ${date}`;
                    },
                    label: (tooltipItem) => {
                        return `Avg Duration: ${tooltipItem.raw.toFixed(2)} seconds`;
                    }
                },
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                titleFont: {
                    size: 16,
                    weight: 'bold'
                },
                bodyFont: {
                    size: 14,
                    weight: 'normal'
                },
                displayColors: false
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    tooltipFormat: 'PPpp',
                    displayFormats: {
                        hour: 'HH:mm',
                    }
                },
                ticks: {
                    color: 'rgba(255,255,255,0.7)',
                },
                grid: {
                    color: 'rgba(255,255,255,0.1)'
                }
            },
            y: {
                ticks: {
                    color: 'rgba(255,255,255,0.7)',
                },
                grid: {
                    color: 'rgba(255,255,255,0.1)'
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    return (
        <div className="bg-gray-900 p-4 text-white rounded-lg shadow-lg" style={{ height: '400px' }}>
            <h3 className="text-2xl font-semibold mb-4 text-white">Average Duration per Hour</h3>
            <div style={{ height: 'calc(100% - 40px)' }}>
                <Line data={chartData} options={options} />
            </div>
        </div>
    );
}

export default AvgDurationPerHourChart;
