import React, { useState, useEffect } from 'react';
import { FaServer, FaMicrochip, FaUpload, FaDownload, FaExclamationCircle } from 'react-icons/fa';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const SummaryCard = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState('Overview');
  const [selectedCluster, setSelectedCluster] = useState('');
  const [selectedServer, setSelectedServer] = useState('');
  const [selectedMetric, setSelectedMetric] = useState('cpu_usage');

  useEffect(() => {
    if (data.length > 0) {
      const defaultCluster = data[0].cluster_name;
      setSelectedCluster(defaultCluster);
      const defaultServer = data.find((item) => item.cluster_name === defaultCluster)?.server_type;
      setSelectedServer(defaultServer);
    }
  }, [data]);

  const clusters = data.map((item) => item.cluster_name).filter((value, index, self) => self.indexOf(value) === index);

  const servers = (cluster) =>
    data
      .filter((item) => item.cluster_name === cluster)
      .map((item) => item.server_type)
      .filter((value, index, self) => self.indexOf(value) === index);

  const getServerData = (cluster, serverType) =>
    data.find((item) => item.cluster_name === cluster && item.server_type === serverType);

  const serverData = getServerData(selectedCluster, selectedServer) || {};

  const renderProgressBar = (label, value, icon, unit = '%') => (
    <div className="mb-4 flex items-center">
      {icon}
      <p className="text-gray-300 text-sm ml-2">{label}</p>
      <div className="w-1/2 ml-auto mr-2">
        <div className="bg-gray-700 rounded-full h-2 overflow-hidden">
          <div className={`h-2 rounded-full ${value > 50 ? 'bg-green-500' : 'bg-red-500'}`} style={{ width: `${value}%` }}></div>
        </div>
      </div>
      <p className="text-gray-300 text-xs">{value}{unit}</p>
    </div>
  );

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'Overview':
        return (
          <div className="grid grid-cols-1 gap-4">
            {renderProgressBar('Server Health', serverData.server_health || 0, <FaServer className="text-gray-400" />)}
            {renderProgressBar('CPU Usage', serverData.cpu_usage || 0, <FaMicrochip className="text-gray-400" />)}
            {renderProgressBar('Upload Speed', serverData.upload_speed || 0, <FaUpload className="text-gray-400" />, 'Mbps')}
            {renderProgressBar('Download Speed', serverData.download_speed || 0, <FaDownload className="text-gray-400" />, 'Mbps')}
            <div className="flex items-center">
              <FaExclamationCircle className="text-gray-400" />
              <p className="text-gray-300 text-sm ml-2">Alarms</p>
              <p className="ml-auto text-gray-300 text-sm font-bold">{serverData.alarms || 0}</p>
            </div>
          </div>
        );
      case 'Details':
        return (
          <div className="grid grid-cols-1 gap-2 text-gray-300 text-sm">
            <div><strong>Server Type:</strong> {serverData.server_type || 'N/A'}</div>
            <div><strong>Cluster:</strong> {serverData.cluster_name || 'N/A'}</div>
            <div><strong>Location:</strong> {serverData.location || 'N/A'}</div>
            <div><strong>Last Maintenance:</strong> {serverData.last_maintenance || 'N/A'}</div>
            <div><strong>Next Maintenance:</strong> {serverData.next_maintenance || 'N/A'}</div>
          </div>
        );
      case 'Statistics':
        return (
          <div className="grid grid-cols-1 gap-2 text-gray-300 text-sm">
            <div><strong>Total Uptime:</strong> {serverData.total_uptime || 'N/A'}</div>
            <div><strong>Error Rate:</strong> {serverData.error_rate || 'N/A'}</div>
            <div><strong>Peak Load:</strong> {serverData.peak_load || 'N/A'}</div>
            <div><strong>Average Load:</strong> {serverData.average_load || 'N/A'}</div>
            <div><strong>Network Latency:</strong> {serverData.network_latency || 'N/A'}</div>
          </div>
        );
      default:
        return null;
    }
  };

  const chartData = data
    .filter((item) => item.cluster_name === selectedCluster && item.server_type === selectedServer)
    .map((item) => ({
      name: item.timestamp,
      value: item[selectedMetric],
    }));

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow flex flex-col h-full">
      <div className="flex items-center mb-5">
        <div className="h-12 w-12 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-white shadow-lg">
          <FaServer />
        </div>
        <div className="ml-4">
          <h2 className="font-bold text-2xl text-white">Server Performance</h2>
          <p className="text-gray-400 text-sm">{selectedCluster ? `${selectedCluster} - ${selectedServer}` : 'Select a Cluster and Server'}</p>
        </div>
      </div>
      <div className="flex justify-around mb-4 border-b border-gray-700 pb-2">
        {['Overview', 'Details', 'Statistics'].map((tab) => (
          <button
            key={tab}
            onClick={() => setSelectedTab(tab)}
            className={`text-sm pb-1 ${selectedTab === tab ? 'text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'} hover:text-white transition-colors`}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className="mb-4 flex justify-between items-center text-xs text-gray-400">
        <p>Last updated today at 12:14 am</p>
        <div className="flex items-center">
          <span className="mr-1">Auto refresh</span>
          <input type="checkbox" className="form-checkbox bg-gray-700 border-gray-600 text-blue-400 focus:ring-blue-500" />
        </div>
      </div>
      <div className="mb-6 flex flex-wrap gap-4">
        <div className="flex-1 min-w-40">
          <label className="block text-gray-400 mb-1 text-xs">Cluster</label>
          <select
            value={selectedCluster}
            onChange={(e) => {
              setSelectedCluster(e.target.value);
              setSelectedServer('');
            }}
            className="w-full p-2 bg-gray-700 rounded border border-gray-600 focus:outline-none focus:border-blue-400 text-xs text-gray-300"
          >
            <option value="">Select Cluster</option>
            {clusters.map((cluster, index) => (
              <option key={index} value={cluster}>
                {cluster}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-1 min-w-40">
          <label className="block text-gray-400 mb-1 text-xs">Server</label>
          <select
            value={selectedServer}
            onChange={(e) => setSelectedServer(e.target.value)}
            className="w-full p-2 bg-gray-700 rounded border border-gray-600 focus:outline-none focus:border-blue-400 text-xs text-gray-300"
            disabled={!selectedCluster}
          >
            <option value="">Select Server</option>
            {servers(selectedCluster).map((server, index) => (
              <option key={index} value={server}>
                {server}
              </option>
            ))}
          </select>
        </div>
      </div>
      {selectedCluster && selectedServer && (
        <div className="flex-grow overflow-auto">
          {renderTabContent()}
        </div>
      )}
      <div className="mt-6 bg-gray-900 p-4 rounded-lg shadow-lg mt-4">

        <label className="block text-gray-400 mb-1 text-xs"> Select Metric</label>
        <select
          value={selectedMetric}
          onChange={(e) => setSelectedMetric(e.target.value)}
          className="w-full p-2 bg-gray-700 rounded border border-gray-600 focus:outline-none focus:border-blue-400 text-xs text-gray-300"
        >
          <option value="cpu_usage">CPU Usage</option>
          <option value="server_health">Server Health</option>
          <option value="upload_speed">Upload Speed</option>
          <option value="download_speed">Download Speed</option>
        </select>
      </div>
      <div className="mt-4 bg-gray-900 p-4 rounded-lg shadow-lg mt-4">
        
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" stroke="#444" />
            <XAxis dataKey="name" stroke="#ddd" />
            <YAxis stroke="#ddd" />
            <Tooltip contentStyle={{ backgroundColor: '#1f2937', borderColor: '#4b5563', borderRadius: '10px', color: '#ffffff' }} />
            <Legend />
            <Line type="monotone" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SummaryCard;
