import React, { useState, useCallback, useMemo } from 'react';
import ApexCharts from 'react-apexcharts';

const TimelineAreaMonthChart = ({ data }) => {
    const [selectedDataKey, setSelectedDataKey] = useState('wait_time');

    const handleDataKeyChange = useCallback((key) => {
        setSelectedDataKey(key);
    }, []);

    const dataKeys = [
        { value: 'wait_time', label: 'Wait Time' },
        { value: 'handling_time', label: 'Handling Time' },
        { value: 'intervention_time', label: 'Intervention Time' }
    ];

    const groupedByMonthData = useMemo(() => {
        const grouped = {};
        data.forEach(call => {
            const month = new Date(call.date).toLocaleDateString('en-GB', { month: 'short', year: 'numeric' });
            if (!grouped[month]) {
                grouped[month] = [];
            }
            grouped[month].push(call[selectedDataKey]);
        });

        return Object.keys(grouped).map(month => {
            const values = grouped[month];
            const average = values.reduce((acc, val) => acc + val, 0) / values.length;
            return {
                x: month,
                y: parseFloat(average.toFixed(2)) // Format to two decimal places
            };
        });
    }, [data, selectedDataKey]);

    const series = [{
        name: dataKeys.find(key => key.value === selectedDataKey).label,
        data: groupedByMonthData
    }];

    const options = {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false,
                autoScaleYaxis: true
            },
            toolbar: {
                show: false
            },
            foreColor: '#E0E0E0',
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        grid: {
            borderColor: '#2E2E2E'
        },
        xaxis: {
            type: 'category',
            labels: {
                style: {
                    colors: '#E0E0E0'
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#E0E0E0'
                },
                formatter: function (val) {
                    return val.toFixed(2); // Format to two decimal places
                }
            }
        },
        tooltip: {
            x: {
                format: 'MMM yyyy'
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
            }
        },
        colors: ['#FF4560']
    };

    return (
        <div className="bg-gray-800 p-6 rounded-lg shadow-md mt-12">
            <div className="mb-4 flex justify-center">
                {dataKeys.map(key => (
                    <button
                        key={key.value}
                        onClick={() => handleDataKeyChange(key.value)}
                        className={`p-2 rounded-md mx-2 ${selectedDataKey === key.value ? 'bg-blue-600' : 'bg-gray-600'} text-gray-300 border-gray-500 focus:ring-indigo-500 focus:ring-opacity-50`}
                    >
                        {key.label}
                    </button>
                ))}
            </div>
            <ApexCharts options={options} series={series} type="area" height={250} />
        </div>
    );
};

export default TimelineAreaMonthChart;
