import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaChartLine, FaHotel, FaTachometerAlt, FaUsers } from 'react-icons/fa';
import Navbar from '../components/Navbar';
import './Landscape.css'; // Make sure to import the CSS for the typing animation

const LandingPage = () => {
    const navigate = useNavigate();

    const pages = [
        {
            path: "/mainV2",
            label: "Iot Dashboard",
            description: "Explore the main features of our application.",
            icon: <FaTachometerAlt />,
            images: [require('../img/ScreenShots/main_screenshot_1.png'), require('../img/ScreenShots/main_screenshot_2.png')]
        },
        {
            path: "/hotel_Financial_KPIs",
            label: "Hotel Dashboard",
            description: "Track financial KPIs with real-time analytics.",
            icon: <FaHotel />,
            images: [require('../img/ScreenShots/hotel_screenshot_1.png'), require('../img/ScreenShots/hotel_screenshot_2.png'), require('../img/ScreenShots/hotel_screenshot_3.png'), require('../img/ScreenShots/hotel_screenshot_4.png')]
        },
        {
            path: "/overview",
            label: "Al Ain Dashboard",
            description: "Monitor and manage operations with ease.",
            icon: <FaChartLine />,
            images: [require('../img/ScreenShots/alain_screenshot_1.png'), require('../img/ScreenShots/alain_screenshot_2.png'), require('../img/ScreenShots/alain_screenshot_3.png')]
        },
        {
            path: "/GuestsManagment",
            label: "Guest Management",
            description: "Efficiently manage guest interactions and services.",
            icon: <FaUsers />,
            images: [require('../img/ScreenShots/guestmanagment_screenshot_1.png')]
        },
        {
            path: "/store_sales",
            label: "Sales Management",
            description: "Efficiently manage sales interactions and services.",
            icon: <FaUsers />,
            images: [require('../img/ScreenShots/sales_screenshot_1.png')]
        },
        {
            path: "/Main_retailt",
            label: "Retails Management",
            description: "Efficiently manage Retail interactions and services.",
            icon: <FaUsers />,
            images: [require('../img/ScreenShots/retail_screenshot_1.png')]
        },
    ];

    return (
        <div className="bg-gradient-to-br from-gray-900 to-blue-800 text-white min-h-screen font-sans overflow-x-hidden">
        <Navbar navigate={navigate} />

            <div className="text-center pt-32 pb-5">
                <h1 className="text-4xl md:text-5xl font-bold mb-2 text-blue-500">
                    Welcome to <span className="text-purple-500">Magnetoo</span>
                </h1>
                <p className="text-lg md:text-xl text-gray-300 max-w-xl mx-auto">
                    Discover powerful tools to manage your business efficiently and effectively.
                </p>
            </div>

            <div className="text-center mb-12">
                <p className="text-2xl font-bold text-blue-400">
                    Analyze, Visualize, Release
                </p>
            </div>

            <div className="px-4 md:px-8 pb-12">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {pages.map((page) => (
                        <div
                            key={page.path}
                            onClick={() => navigate(page.path)}
                            className="cursor-pointer rounded-lg bg-gray-800 hover:bg-gray-700 shadow-md hover:shadow-lg transition-transform transform hover:scale-105 p-4 md:p-6"
                        >
                            <div className="relative h-40 md:h-48 lg:h-56 mb-4 overflow-hidden rounded-md">
                                {page.images && page.images.length > 0 ? (
                                    <Carousel images={page.images} />
                                ) : (
                                    <div className="w-full h-full bg-black" />
                                )}
                            </div>
                            <div className="flex items-center mb-2">
                                <div className="text-2xl text-blue-400 mr-2">
                                    {page.icon}
                                </div>
                                <h2 className="text-lg md:text-xl font-bold text-blue-400">{page.label}</h2>
                            </div>
                            <p className="text-gray-400 text-sm md:text-base">{page.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const Carousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const handlePrev = (e) => {
        e.stopPropagation();
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleNext = (e) => {
        e.stopPropagation();
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className="relative w-full h-full overflow-hidden rounded-md">
            <img
                src={images[currentIndex]}
                alt={`slide ${currentIndex}`}
                className="w-full h-full object-cover transition-opacity duration-500"
            />
            <FaArrowLeft
                onClick={handlePrev}
                className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white text-xl cursor-pointer z-10 bg-black bg-opacity-50 rounded-full p-2"
            />
            <FaArrowRight
                onClick={handleNext}
                className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white text-xl cursor-pointer z-10 bg-black bg-opacity-50 rounded-full p-2"
            />
        </div>
    );
};

export default LandingPage;
