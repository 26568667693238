import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiHome, FiGrid, FiBriefcase, FiLogOut, FiMenu } from 'react-icons/fi';
import aadcLogo from '../img/aadc_logo.png';

const HoverSidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();


  return (
    <div
      className={`fixed top-0 left-0 h-full bg-gradient-to-b from-blue-500 to-purple-300 text-white ${isOpen ? 'w-64' : 'w-16'} transition-all duration-300 ease-in-out overflow-hidden z-50 shadow-lg flex flex-col`}
    >
      <div className="flex flex-col items-center justify-center p-4">
        <img src={aadcLogo} alt="AADC Logo" className={`w-32 h-auto transition-transform duration-300 ${isOpen ? 'scale-100' : 'scale-0'}`} />
          
      </div>
      <ul className="mt-5 space-y-2 flex-grow p-0">
      <li className={`flex items-center p-2 rounded hover:bg-blue-600 transition duration-200 ${location.pathname === '/overview' ? 'bg-blue-600' : 'bg-blue-700'}`}>
        <Link to="/overview" className="flex items-center w-full text-white">
          <FiGrid className="text-2xl mr-4" />
          <span className={`transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>Overview</span>
        </Link>
      </li>
      <li className={`flex items-center p-2 rounded hover:bg-blue-600 transition duration-200 ${location.pathname === '/applications' ? 'bg-blue-600' : 'bg-blue-700'}`}>
        <Link to="/applications" className="flex items-left w-full text-white">
          <FiHome className="text-2xl mr-4" />
          <span className={`transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>Alarms</span>
        </Link>
      </li>
      <li className={`flex items-center p-2 rounded hover:bg-blue-600 transition duration-200 ${location.pathname === '/departments' ? 'bg-blue-600' : 'bg-blue-700'}`}>
        <Link to="/departments" className="flex items-center w-full text-white">
          <FiBriefcase className="text-2xl mr-4" />
          <span className={`transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>Departments</span>
        </Link>
      </li>
    </ul>
      <ul className="mb-5">
        <li className="flex items-center p-2 rounded hover:bg-blue-600 transition duration-200">
          <Link to="/main" className="flex items-center w-full text-white">
            <FiLogOut className="text-2xl ml-4 mr-6" />
            <span className={`transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>Exit</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default HoverSidebar;
