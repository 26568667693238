import React from 'react';

const Users = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Users</h1>
      {/* Add your users dashboard content here */}
    </div>
  );
};

export default Users;
