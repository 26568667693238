// IotaAreaChart.jsx
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

const IotaAreaChart = ({ data, metric, onMetricChange }) => {
  const lineData = {
    labels: data.map((_, index) => `Record ${index + 1}`),
    datasets: [
      {
        label: 'Server 1',
        data: data.map((record) => record[metric] || 0),
        fill: true,
        backgroundColor: 'rgba(34, 202, 236, 0.2)',
        borderColor: 'rgba(34, 202, 236, 1)',
        tension: 0.4,
      },
      {
        label: 'Server 2',
        data: data.map((record) => record[metric] || 0),
        fill: true,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        tension: 0.4,
      },
    ],
  };

  const lineOptions = {
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#ffffff',
        },
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: '#ffffff',
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: '#ffffff',
        },
      },
    },
  };

  return (
    <div className="mt-6">
      <label className="block text-gray-400 mb-1 text-xs">Select Metric</label>
      <select
        value={metric}
        onChange={(e) => onMetricChange(e.target.value)}
        className="w-full p-1 bg-gray-700 rounded border border-gray-600 focus:outline-none focus:border-blue-400 text-xs text-gray-100 mb-4"
      >
        <option value="server_health">Server Health</option>
        <option value="cpu_usage">CPU Usage</option>
        <option value="upload_speed">Upload Speed</option>
        <option value="download_speed">Download Speed</option>
        <option value="alarms">Alarms</option>
      </select>
      <Line data={lineData} options={lineOptions} />
    </div>
  );
};

export default IotaAreaChart;
