import React from 'react';
import ReactECharts from 'echarts-for-react';


const SimpleBarChart = ({ data }) => {
  // Define an array of colors for the bars
  const colors = ['#4D4DFF', '#FF4D4D', '#4DFF4D', '#FFD24D', '#4DD2FF'];

  const option = {
    xAxis: {
      type: 'category',
      data: Object.keys(data),
      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: 'white', // Change the label text color to white
        // You can add other styling options for the label text here
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: 'white', // Change the label text color to white
        // You can add other styling options for the label text here
      },
    },
    series: [
      {
        type: 'bar',
        data: Object.keys(data).map((key, index) => ({
          value: data[key],
          name: key,
          // Use the index to cycle through the colors array
          itemStyle: {
            color: colors[index % colors.length],
            barBorderRadius: [10, 10, 0, 0],
          },
        })),
        barWidth: '40%', // Adjust this value to control the width of the bars
      },
    ],
  };

  return <ReactECharts option={option} style={{ height: '400px', width: '100%' }} />;
};

export default SimpleBarChart;
