import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const LineSiteChart = ({ title, data, height }) => {
  if (!data || !data.labels || !data.datasets) {
    return <div>Data not available</div>;
  }

  const chartData = {
    labels: data.labels,
    datasets: data.datasets.map((dataset, index) => ({
      ...dataset,
      borderColor: ['#3498db', '#2ecc71', '#9b59b6', '#e74c3c'][index % 4], // line colors
      backgroundColor: 'rgba(0, 0, 0, 0)', // transparent background
      pointBackgroundColor: ['#3498db', '#2ecc71', '#9b59b6', '#e74c3c'][index % 4], // point colors
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: ['#3498db', '#2ecc71', '#9b59b6', '#e74c3c'][index % 4],
      pointRadius: 5,
      pointHoverRadius: 7,
    })),
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
     
    },
    scales: {
      x: {
        ticks: {
          color: '#7f8c8d',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#7f8c8d',
        },
        grid: {
          borderDash: [2],
          color: '#ecf0f1',
        },
      },
    },
    animation: {
      duration: 1000, // general animation time
      easing: 'easeOutBounce', // an easing function to make the animation more smooth
    },
    maintainAspectRatio: false, // allows you to control the aspect ratio
  };

  return (
    <div style={{ height: `${height}px` }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default LineSiteChart;
