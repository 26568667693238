import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

const Alarmlinechart = ({ data }) => {
  const colors = [
    ['#74b9ff', '#0984e3'],
    ['#55efc4', '#00b894'],
    ['#ffeaa7', '#fdcb6e'],
    ['#ff7675', '#d63031'],
    ['#fd79a8', '#e84393'],
  ];

  // Sort data by value
  const sortedData = Object.entries(data).sort((a, b) => b[1] - a[1]);

  const option = {
    grid: {
      top: '5%',    // Reduced top margin
      left: '3%',
      right: '4%',
      bottom: '5%', // Reduced bottom margin
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Slightly transparent dark tooltip
      borderColor: '#2f3640', // Subtle border
      borderWidth: 1,
      textStyle: {
        color: '#ffffff', // Light text for contrast
      },
      formatter: '{b}<br/>Count: {c}',
    },
    xAxis: {
      type: 'category',
      data: sortedData.map(([key]) => key),
      axisLine: {
        show: true,
        lineStyle: {
          color: '#7f8c8d', // Neutral gray for axis line
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: '#2c3e50', // Darker labels for contrast
        fontSize: 12,
        fontWeight: 'bold',
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(189, 195, 199, 0.2)', // Subtle split lines
        },
      },
      axisLabel: {
        color: '#2c3e50',
        fontSize: 12,
        fontWeight: 'bold',
      },
    },
    series: [
      {
        type: 'bar',
        data: sortedData.map(([key, value], index) => ({
          value: value,
          name: key,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: colors[index % colors.length][0] },
              { offset: 1, color: colors[index % colors.length][1] },
            ]),
            barBorderRadius: [10, 10, 0, 0], // Rounded corners for modern look
          },
        })),
        barWidth: '45%', // Increased bar width for better visibility
        label: {
          show: true,
          position: 'top',
          formatter: '{c}',
          color: '#2c3e50',
          fontWeight: 'bold',
        },
        animationDelay: (idx) => idx * 150, // Smooth staggered animation
        emphasis: {
          itemStyle: {
            shadowBlur: 20,
            shadowColor: 'rgba(0, 0, 0, 0.5)', // Soft shadow on hover
          },
        },
      },
    ],
    animationEasing: 'cubicOut', // Smooth easing function for modern feel
  };

  return <ReactECharts option={option} style={{ height: '288px', width: '100%' }} />;
};

export default Alarmlinechart;
