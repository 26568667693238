import React from 'react';
import ReactApexChart from 'react-apexcharts';

const ItPerformancebarchart = () => {
    const [options, setOptions] = React.useState({
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true
          },
          foreColor: '#fff',
          fontFamily: 'Helvetica, Arial, sans-serif',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          height: 192 // Adjusted height to match the div
        },
        responsive: [
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 180 // Slightly less for margin
              },
              legend: {
                position: 'bottom'
              }
            }
          },
          {
            breakpoint: 768,
            options: {
              chart: {
                height: 180 // Consistent with larger breakpoint
              },
              legend: {
                position: 'bottom'
              }
            }
          },
          {
            breakpoint: 480,
            options: {
              chart: {
                height: 180 // Consistent with other breakpoints
              },
              legend: {
                position: 'bottom',
                offsetX: -2,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            barWidth: '10%',
          
          }, barWidth: '40%',
        },
        xaxis: {
          type: 'category',
          categories: ['Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          labels: {
            style: {
              colors: '#fff',
              fontSize: '12px',
            }
          },
        },
        legend: {
          show: false,
          position: 'right',
          offsetY: 40
        },
        fill: {
          colors: ['#1565C0', '#42A5F5', '#BBDEFB']
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          borderColor: '#444',
          strokeDashArray: 4,
        },
      });

  const [series, setSeries] = React.useState([
    {
      name: 'Network Uptime',
      data: [99.5, 99.6, 99.7, 99.8, 99.9, 99.5, 99.7] // Fake data for network uptime
    },
    {
      name: 'Bandwidth Usage',
      data: [50, 60, 70, 80, 90, 75, 65] // Fake data for bandwidth usage
    },
    {
      name: 'Connected Devices',
      data: [20, 25, 30, 35, 28, 32, 27] // Fake data for connected devices
    }
  ]);

  return (
    <div style={{ flex: 1 }}>
      <div id="chart" style={{ height: '192px', width: '100%' }}>
        <ReactApexChart options={options} series={series} type="bar" />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ItPerformancebarchart;
