import { useNavigate } from "react-router";
import Navbar from "../../components/Navbar";
import { ShieldCheckIcon,CheckCircleIcon ,WifiIcon  } from "@heroicons/react/24/outline";
import ItPerformancebarchart from "../../charts/Elv_systems_charts/It_performance_bar_chart";
import CctvInfoPieChart from "../../charts/Elv_systems_charts/CCTV_info_piechart";
import Havacchart from "../../charts/Elv_systems_charts/Havacchart";
import { FiAlertCircle, FiShield, FiPower, FiDroplet } from 'react-icons/fi'; 
import { useState } from "react";

const data = [
    { date: '2022-01-01', temperature: 22, humidity: 50, energyConsumption: 200 },
    { date: '2022-01-02', temperature: 23, humidity: 45, energyConsumption: 210 },
    // Add more data
    { date: '2022-01-03', temperature: 21, humidity: 55, energyConsumption: 190 },
    { date: '2022-01-04', temperature: 20, humidity: 60, energyConsumption: 180 },
    // Add more data as needed
];
const ElvSystems = () => {
    const navigate = useNavigate();
    const [hoveredAlert, setHoveredAlert] = useState(null);

    // Function to handle hover
    const handleAlertHover = (alertType) => {
        setHoveredAlert(alertType);
    };

    // Function to handle mouse leave
    const handleAlertLeave = () => {
        setHoveredAlert(null);
    };


    
    return (
      <div className="bg-black min-h-screen">
        <Navbar navigate={navigate}/>
        <br />
        <div className="container mx-auto px-4 md:px-0">
  
          {/* Grid Layout */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {/* IT Environment Performance */}
            <div className="flex flex-col ml">
                
            <div className="flex flex-row ml">
            <WifiIcon className="h-8 w-6 text-gray-500" />
            <h2 className=" text-slate-400 ml-2 mb-3 tracking-wider font-semibold z-10 relative text-left text-sm mt-1">IT Environment Performance</h2>
            
            </div>
            
            <div className="bg-gray-900 p-1 rounded-lg h-80 flex flex-col">
            <div className="flex items-center bg-gray-800 p-4 rounded-lg h-16 mt-2">
    
    <div className="flex flex-1 justify-between">
        <div className="flex flex-col">
            <p className="text-white text-xs mb-3">Network Uptime</p>
            <div className="flex flex-row">
                <ShieldCheckIcon className="h-6 w-6 text-blue-500" />
                <p className="text-teal-700 text-lg text-left ml-1 ">98%</p>
            </div>
        </div>
        <div className="flex flex-col">
            <p className="text-white text-xs mb-3">Connected Devices</p>
            <div className="flex flex-row">
                <CheckCircleIcon className="h-6 w-6 text-blue-400 ml-2 mr-2" />
                <p className="text-teal-700 text-lg text-left ml-1 ">53</p>
            </div>
        </div>
        <div className="flex flex-col">
            <p className="text-white text-xs mb-3">Bandwidth Usage</p>
            <div className="flex flex-row">
                <ShieldCheckIcon className="h-6 w-6 text-blue-200" />
                <p className="text-teal-700 text-lg text-left ml-1 ">48 Mbps</p>
            </div>
        </div>
    </div>
</div>
            <div className="h-36  "> 
  <ItPerformancebarchart />
</div>

    
</div>

</div>

  
            {/* CCTV Performance */}
            <div className="flex flex-col ml">
                
                <h2 className=" text-slate-400 ml-2 mb-3 tracking-wider font-semibold z-10 relative text-left text-sm">CCTV Performance</h2>
    
                <div className="bg-gray-900 p-1 rounded-lg h-80 flex flex-col">
                <div className="flex items-center bg-gray-800 p-4 rounded-lg h-16 mt-2">
        
        <div className="flex flex-1 justify-between">
            <div className="flex flex-col">
                <p className="text-white text-xs mb-3">Online Cameras</p>
                <div className="flex flex-row">
                    <ShieldCheckIcon className="h-6 w-6 text-gray-500" />
                    <p className="text-teal-700 text-lg text-left ml-1 ">172</p>
                </div>
            </div>
            <div className="flex flex-col">
                <p className="text-white text-xs mb-3">Offline Cameras</p>
                <div className="flex flex-row">
                    <CheckCircleIcon className="h-6 w-6 text-gray-500 ml-2 mr-2" />
                    <p className="text-teal-700 text-lg text-left ml-1 ">2</p>
                </div>
            </div>
            <div className="flex flex-col">
                <p className="text-white text-xs mb-3">Total</p>
                <div className="flex flex-row">
                    <ShieldCheckIcon className="h-6 w-6 text-gray-500" />
                    <p className="text-teal-700 text-lg text-left ml-1 ">174</p>
                </div>
            </div>
        </div>
    </div>
                <div className="h-36 mt-2 "> 
      <CctvInfoPieChart />
    </div>
    
        
    </div>
    
    </div>
    <div className="flex flex-col ml">
                
                <h2 className=" text-slate-400 ml-2 mb-3 tracking-wider font-semibold z-10 relative text-left text-sm">Safety System Monitoring</h2>
    
                <div className="bg-gray-900 p-1 rounded-lg h-80 flex flex-col">
                <div className="flex items-center bg-gray-800 p-4 rounded-lg h-16 mt-2">
        
        <div className="flex flex-1 justify-between">
            <div className="flex flex-col">
                <p className="text-white text-xs mb-3">Network Uptime</p>
                <div className="flex flex-row">
                    <ShieldCheckIcon className="h-6 w-6 text-gray-500" />
                    <p className="text-teal-700 text-lg text-left ml-1 ">98%</p>
                </div>
            </div>
            <div className="flex flex-col">
                <p className="text-white text-xs mb-3">Connected Devices</p>
                <div className="flex flex-row">
                    <CheckCircleIcon className="h-6 w-6 text-gray-500 ml-2 mr-2" />
                    <p className="text-teal-700 text-lg text-left ml-1 ">53</p>
                </div>
            </div>
            <div className="flex flex-col">
                <p className="text-white text-xs mb-3">Bandwidth Usage</p>
                <div className="flex flex-row">
                    <ShieldCheckIcon className="h-6 w-6 text-gray-500" />
                    <p className="text-teal-700 text-lg text-left ml-1 ">48 Mbps</p>
                </div>
            </div>
        </div>
    </div>
                <div className="h-36  "> 
      <ItPerformancebarchart />
    </div>
    
        
    </div>
    
    </div>
  
            {/* HVAC Monitoring */}
            <div className="flex flex-col ml">
                
                <h2 className=" text-slate-400 ml-2 mb-3 tracking-wider font-semibold z-10 relative text-left text-sm">HVAC Monitoring</h2>
    
                <div className="bg-gray-900 p-1 rounded-lg h-80 flex flex-col">
                <div className="flex items-center bg-gray-800 p-4 rounded-lg h-16 mt-2">
        
        <div className="flex flex-1 justify-between">
            <div className="flex flex-col">
                <p className="text-white text-xs mb-3">Temperature(°C)</p>
                <div className="flex flex-row">
                    <ShieldCheckIcon className="h-6 w-6 text-gray-500" />
                    <p className="text-teal-700 text-lg text-left ml-1  ">47</p>
                </div>
            </div>
            <div className="flex flex-col">
                <p className="text-white text-xs mb-3">Humidity(%)</p>
                <div className="flex flex-row">
                    <CheckCircleIcon className="h-6 w-6 text-gray-500 ml-2 mr-2" />
                    <p className="text-teal-700 text-lg text-left ml-1 ">21%</p>
                </div>
            </div>
            <div className="flex flex-col">
                <p className="text-white text-xs mb-3">Energy (kwh)</p>
                <div className="flex flex-row">
                    <ShieldCheckIcon className="h-6 w-6 text-gray-500" />
                    <p className="text-teal-700 text-lg text-left ml-1 ">200</p>
                </div>
            </div>
        </div>
    </div>
                <div className="h-44 mt-8"> 
      <Havacchart data={data}/>
    </div>
    
        
    </div>
    
    </div>
  
            {/* Safety System Monitoring */}
            
            <div className="bg-gray-900 p-4 rounded-lg mb-2 md:col-span-1 lg:col-span-2">
            <h2 className="text-lg font-semibold mb-2 text-white">System-wise Alarms</h2>
            {/* Placeholder for Chart */}
          </div>

          {/* Alarms Summary */}
          <div className="bg-gray-900 p-4 rounded-lg mb-2 md:col-span-1 lg:col-span-2 shadow-md">
    <h2 className="text-slate-400 ml-2 mb-3 tracking-wider font-semibold z-10 relative text-left text-sm">Alarms Summary</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
        {/* Fire Alarm */}
        <div className="bg-red-500 rounded-lg p-2 cursor-pointer group" onMouseEnter={() => handleAlertHover('fire')} onMouseLeave={handleAlertLeave}>
            <div className="flex items-center justify-between">
                <div>
                    <p className="text-white font-medium">Fire Alarm</p>
                    <p className="text-white text-sm group-hover:text-gray-200 transition duration-200">Room 205</p>
                    <p className="text-white text-xs group-hover:text-gray-200 transition duration-200">Feb 22, 2023</p>
                </div>
                <FiAlertCircle className="text-white text-2xl" />
            </div>
        </div>
        {/* Intrusion Detection */}
        <div className="bg-yellow-500 rounded-lg p-2 cursor-pointer group" onMouseEnter={() => handleAlertHover('intrusion')} onMouseLeave={handleAlertLeave}>
            <div className="flex items-center justify-between">
                <div>
                    <p className="text-white font-medium">Intrusion</p>
                    <p className="text-white text-sm group-hover:text-gray-200 transition duration-200">Staff Only Area</p>
                    <p className="text-white text-xs group-hover:text-gray-200 transition duration-200">Feb 21, 2023</p>
                </div>
                <FiShield className="text-white text-2xl" />
            </div>
        </div>
        {/* Power Failure */}
        <div className="bg-blue-500 rounded-lg p-2 cursor-pointer group" onMouseEnter={() => handleAlertHover('power')} onMouseLeave={handleAlertLeave}>
            <div className="flex items-center justify-between">
                <div>
                    <p className="text-white font-medium">Power Failure</p>
                    <p className="text-white text-sm group-hover:text-gray-200 transition duration-200">Kitchen</p>
                    <p className="text-white text-xs group-hover:text-gray-200 transition duration-200">Feb 20, 2023</p>
                </div>
                <FiPower className="text-white text-2xl" />
            </div>
        </div>
        {/* Water Leakage */}
        <div className="bg-teal-500 rounded-lg p-2 cursor-pointer group" onMouseEnter={() => handleAlertHover('water')} onMouseLeave={handleAlertLeave}>
            <div className="flex items-center justify-between">
                <div>
                    <p className="text-white font-medium">Water Leakage</p>
                    <p className="text-white text-sm group-hover:text-gray-200 transition duration-200">Bathroom, Room 302</p>
                    <p className="text-white text-xs group-hover:text-gray-200 transition duration-200">Feb 19, 2023</p>
                </div>
                <FiDroplet className="text-white text-2xl" />
            </div>
        </div>
    </div>
    {/* Show alert description on hover */}
    {hoveredAlert && (
        <div className="absolute bottom-0 left-0 bg-gray-800 p-2 rounded text-white mt-2">
            {hoveredAlert === 'fire' && <p>Fire alarm triggered in Room 205. Please evacuate immediately.</p>}
            {hoveredAlert === 'intrusion' && <p>Intrusion detected in Staff Only Area. Security alerted.</p>}
            {hoveredAlert === 'power' && <p>Power failure detected in the Kitchen. Backup generators activated.</p>}
            {hoveredAlert === 'water' && <p>Water leakage detected in the Bathroom of Room 302. Maintenance alerted.</p>}
        </div>
    )}
</div>

 
  
            {/* Subcontractor KPI */}
          
  
            {/* System-wise Alarms */}
         
          </div>
        </div>
      </div>
    );
  }
  
  export default ElvSystems;
  