import React, { useEffect, useState } from 'react';
import './styles.css'; // Import custom CSS for the title shape
import { useNavigate } from "react-router-dom";
import bgImage from '../../img/Dashboard_background.jpg'; 

import GaugeChart from 'react-gauge-chart';
import ReactPlayer from 'react-player';

import DigitalClock from '../../components/Clock';

import { ArrowPathIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import MudLineChartComponent from '../../charts/armaco/Mud_line_chart';
import Navbar from '../../components/Navbar';

const DrillingDashboard = () => {
  const [wob, setWob] = useState(0);
  const [hook,setHook] = useState(0);
  const [tdRotateSpeed,settdRotateSpeed] = useState(0);
  const [spp,setSPP] = useState(0);
  const [pumpPr,setpumpPR] = useState(0);
  const [cdepth,setCdepth] = useState(0);
  const [ddepth,setDdepth] = useState(0);
  const [topdriverpm,setTopdriverpm] = useState(0);
  const [topdrivetorque,setTopdrivetprque] = useState(0);
  const [tdtorqueact,setTdtorqueact] = useState(0);
  const [isTDDrillModeOn, setTDDrillModeOn] = useState(false);
  const [isTDSpinModeOn, setTDSpinModeOn] = useState(false);
  const [isGENSetOn, setGENSetOn] = useState(false);
  const [isIBOPOn, setIBOPOn] = useState(false);
  const navigate = useNavigate();
  const [isGENSeAlarmtOn, setIsGENSetAlarmOn] = useState(false);
  const toggleTDDrillMode = () => {
    setTDDrillModeOn(!isTDDrillModeOn);
  };
  const toggleTDSpinModeOn = () => {
    setTDSpinModeOn(!isTDSpinModeOn);
  };
  const toggleIBOPOn = () => {
    setIBOPOn(!isIBOPOn);
  };
  const toggleGenSet = () => {
    setGENSetOn(!isGENSetOn);
  };
  const toggleGenSetAlarm=() => {
    setIsGENSetAlarmOn(!isGENSeAlarmtOn)
  }
  
  
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://magnetoo.fr/drilling-data');
        const data = await response.json();
        if (data.length > 0) {
          setWob(data[0].wob);
          setHook(data[0].hook_load);
          setSPP(data[0].standpipe_pressure);
          setpumpPR(data[0].pump_pressure);
          setCdepth(data[0].drill_depth);
          setDdepth(data[0].design_depth);
          setTopdriverpm(data[0].top_drive_rpm);
          setTopdrivetprque(data[0].top_drive_torque);
          settdRotateSpeed(data[0].top_drive_rotate_speed);
          setTdtorqueact(data[0].td_torque_act);
        }
      } catch (error) {
        console.error('Error fetching drilling data:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 3000); // Fetch data every 3 seconds
    return () => clearInterval(interval);
  }, []);


return (
  <div className="h-fit bg-black p-4" style={{
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center' /* Move the image to the right and vertically center it */
  }}>
 <Navbar navigate={navigate} />
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-7 grid-rows-12 gap-4">
      
      <div className="relative col-span-1 sm:col-span-2 lg:col-span-2 bg-gray-900 rounded-lg h-40 border-2 border-cyan-400 shadow-lg shadow-cyan-500/50 transition-all duration-300 ease-in-out hover:shadow-cyan-300/50">
        <div className="absolute top-0 left-0 right-0 h-8 flex items-center custom-title-shape">
          <span className="text-white text-xs font-bold pl-4">BASE</span>
        </div>
        <div className="absolute inset-0 opacity-10 bg-gradient-to-r from-cyan-500 via-gray-500 to-cyan-500 rounded-lg"></div>
        <div className="relative z-10 text-white text-center mt-12">
          <div className='flex flex-col gap-4 mr-14 ml-14 border-cyan-400'>
              <div className='bg-slate-700  h-10  '>
              <div className='flex flex-row items-center'>
  <p className='text-left ml-4 font-bold mt-2 text-sm'>Current Depth :</p>
  <div className='bg-slate-800 ml-6 mt-2 bg-gradient-to-r from-cyan-500 via-gray-800 to-cyan-500 ordinal slashed-zero tabular-nums' style={{ width: '130px', height: '25px' }}>
{cdepth} m
</div>

</div>

              </div>
              <div className='bg-slate-700  h-10  border-cyan-400'>
              <div className='flex flex-row '>
                     <p className='text-left ml-4 font-bold mt-2  text-sm'> Design Depth :</p>
                     <div className='bg-slate-800 ml-6 mt-2 bg-gradient-to-r from-cyan-500 via-green-800 to-cyan-500 ' style={{ width: '130px', height: '25px' }}>
{ddepth} m
</div>
                  
                     </div>

              </div>

          </div>
        </div>
      </div>
      <div className="relative col-span-1 sm:col-span-2 lg:col-span-2 lg:col-start-6 bg-gray-900 rounded-lg h-40 border-2 border-cyan-400 shadow-lg shadow-cyan-500/50 transition-all duration-300 ease-in-out hover:shadow-cyan-300/50">
        <div className="absolute top-0 left-0 right-0 h-8 flex items-center custom-title-shape">
          <span className="text-white text-xs font-bold pl-4">Date</span>
         
        </div>
        <div className="absolute inset-0 opacity-10 bg-gradient-to-r from-cyan-500 via-gray-500 to-cyan-500 rounded-lg"></div>
        <div className="relative z-10 text-white text-center mt-12">
        <DigitalClock/>
    
        </div>
      </div>
      
      <div className="relative col-span-1 sm:col-span-2 lg:col-span-2 lg:row-span-2 lg:row-start-2 bg-gray-900 rounded-lg border-2 border-cyan-400 shadow-lg shadow-cyan-500/50 transition-all duration-300 ease-in-out hover:shadow-cyan-300/50">
<div className="absolute top-0 left-0 right-0 h-8 flex items-center custom-title-shape bg-gradient-to-r from-cyan-500 via-cyan-700 to-cyan-900 rounded-t-lg">
  <span className="text-white text-xs font-bold pl-4">TOP RDV</span>
</div>
<div className="absolute inset-0 opacity-10 bg-gradient-to-r from-cyan-500 via-gray-500 to-cyan-500 rounded-lg"></div>
<div className="relative z-10 text-white text-center mt-12">
  <div className="flex flex-col">
    <div className="flex flex-col sm:flex-row mt-8 space-y-4 sm:space-y-0 sm:space-x-4">
      <div className="w-full sm:w-1/2 flex justify-center items-center">
        <div className="flex flex-col items-center">
          <GaugeChart 
            id="topdriverpm-gauge" 
            nrOfLevels={30} 
            percent={spp / 15} 
            textColor="#FFFFFF" 
            colors={['#00ff00', '#ffcc00', '#ff0000']} 
            arcPadding={0.02} 
            arcWidth={0.3} 
            needleColor="#FFFFFF" 
            needleBaseColor="#000000" 
            animate={true} 
            hideText={true} 
          />
          <span className="text-xs font-bold text-white">{topdriverpm} Rpm</span>
          <span className="text-xs font-bold text-white">Top Drive RPM</span>
        </div>
      </div>
      <div className="w-full sm:w-1/2 flex justify-center items-center">
        <div className="flex flex-col items-center">
          <GaugeChart 
            id="topdrivetorque-gauge" 
            nrOfLevels={30} 
            percent={topdrivetorque / 120} 
            textColor="#FFFFFF" 
            colors={['#00FFFF', '#7FFFD4', '#76EE00', '#FFD700', '#FF4500', '#ff0000']} 
            arcPadding={0.02} 
            arcWidth={0.3} 
            needleColor="#FFFFFF" 
            needleBaseColor="#000000" 
            animate={true} 
            hideText={true} 
          />
          <span className="text-xs font-bold text-white">{topdrivetorque} Kn.m</span>
          <span className="text-xs font-bold text-white">Top Drive Torque</span>
        </div>
      </div>
    </div>
    <div className="flex flex-col sm:flex-row mt-8 space-y-4 sm:space-y-0 sm:space-x-4">
      <div className="w-full sm:w-1/2 flex justify-center items-center">
        <div className="flex flex-col items-center">
          <div className="bg-blue-500 h-20 w-20 mb-8 mt-8 rounded-full flex justify-center items-center">
            <LockClosedIcon className="h-12 w-12 text-white" />
          </div>
          <span className="text-xs font-bold text-white">Lock Status</span>
        </div>
      </div>
      <div className="w-full sm:w-1/2 flex justify-center items-center">
        <div className="flex flex-col items-center">
          <div className="bg-blue-500 h-20 w-20 mb-8 mt-8 rounded-full flex justify-center items-center">
            <ArrowPathIcon className="h-12 w-12 text-white" />
          </div>
          <span className="text-xs font-bold text-white">Rotation Path</span>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


   <div className="relative col-span-1 sm:col-span-2 lg:col-span-2 lg:row-span-2 lg:col-start-1 lg:row-start-4 bg-gray-900 rounded-lg border-2 border-cyan-400 shadow-lg shadow-cyan-500/50 transition-all duration-300 ease-in-out hover:shadow-cyan-300/50">
  <div className="absolute top-0 left-0 right-0 h-8 flex items-center custom-title-shape bg-cyan-600 rounded-t-lg">
      <span className="text-white text-xs font-bold pl-4">MUD PUMP</span>
  </div>
  <div className="absolute inset-0 opacity-10 bg-gradient-to-r from-cyan-500 via-gray-500 to-cyan-500 rounded-lg"></div>
  
  <div className="relative top-8 flex flex-wrap justify-center mt-10">
      <div className="flex flex-col items-center m-4 p-4 bg-gray-800 rounded-lg shadow-lg">
          <button className="bg-red-500 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out shadow-lg shadow-red-500/50 border-2 border-red-700">OFF</button>
          <span className="text-white text-xs font-bold mt-2">Pump 1</span>
          <span className="text-white text-sm mt-3">Status: Inactive</span>
          <span className="text-white text-sm mt-3">Pressure: Low</span>
      </div>
      <div className="flex flex-col items-center m-4 p-4 bg-gray-800 rounded-lg shadow-lg">
          <button className="bg-red-500 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out shadow-lg shadow-red-500/50 border-2 border-red-700">OFF</button>
          <span className="text-white text-xs font-bold mt-2">Pump 2</span>
          <span className="text-white text-sm mt-3">Status: Inactive</span>
          <span className="text-white text-sm mt-3">Pressure: Low</span>
      </div>
      <div className="flex flex-col items-center m-4 p-4 bg-gray-800 rounded-lg shadow-lg">
          <button className="bg-green-500 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out shadow-lg shadow-green-500/50 border-2 border-green-700">ON</button>
          <span className="text-white text-xs font-bold mt-2">Pump 3</span>
          <span className="text-white text-sm mt-3">Status: Active</span>
          <span className="text-white text-sm mt-3">Pressure: High</span>
      </div>
  </div>
  
  <div className="relative bottom-10 flex flex-wrap justify-center items-center mt-8">
      <div className='flex flex-col items-center w-full sm:w-1/2 p-4'>
          <GaugeChart 
              id="topdriverpm-gauge" 
              nrOfLevels={30} // More levels for smoother gradient
              percent={spp / 15} // Assuming the maximum WOB is 100 kg
              textColor="#FFFFFF" // White text color for better contrast
              colors={['#00FFFF', '#7FFFD4', '#76EE00', '#FFD700', '#FF4500']} // Smooth gradient from green to yellow to red
              arcPadding={0.02} // Minimal padding between arcs for a continuous look
              arcWidth={0.15} // Thinner arc for a modern look
              needleColor="#00b3b3" // Cyan needle for a striking contrast
              needleBaseColor="#002b36" // Dark cyan base for the needle for better integration
              animate={true} // Enable animation
              hideText={false} // Show percentage text
              textSize={0.25} // Larger text for better readability
              style={{
                  width: '100%',
                  height: 'auto',
                  margin: '0 auto', // Center the chart
              }}
              cornerRadius={3} // Rounded corners for the arcs
          />
          <span className="text-xs font-bold text-white mt-2">{topdriverpm} Rpm</span>
          <span className="text-xs font-bold text-white">Top Drive RPM</span>
      </div>
      <div className='flex flex-col items-center w-full sm:w-1/2 p-4'>
          <GaugeChart 
              id="second-gauge" 
              nrOfLevels={30} 
              percent={spp / 15} // Adjust based on the second gauge value
              textColor="#FFFFFF" // White text color for better contrast
              colors={['#00ff00', '#ffcc00', '#ff0000']} // Green to yellow to red
              arcPadding={0.02} // Padding between arcs
              arcWidth={0.15} // Thinner arc for a modern look
              needleColor="#FFFFFF" // White needle for better contrast
              needleBaseColor="#000000" // Black base for the needle
              animate={true} // Enable animation
              hideText={false} // Show percentage text
              textSize={0.25} // Larger text for better readability
              style={{
                  width: '100%',
                  height: 'auto',
                  margin: '0 auto', // Center the chart
              }}
              cornerRadius={3} // Rounded corners for the arcs
          />
          <span className="text-xs font-bold text-white mt-2">{spp} Rpm</span>
          <span className="text-xs font-bold text-white">Secondary Gauge</span>
      </div>
  </div>
</div>



<div className="relative col-span-1 sm:col-span-2 lg:col-span-2 lg:row-span-2 lg:col-start-6 lg:row-start-4 bg-gray-900 rounded-lg border-2 border-cyan-400 shadow-lg shadow-cyan-500/50 transition-all duration-300 ease-in-out hover:shadow-cyan-300/50">
<div className="absolute top-0 left-0 right-0 h-8 flex items-center custom-title-shape bg-gradient-to-r from-cyan-500 via-gray-500 to-cyan-500 rounded-t-lg">
  <span className="text-white text-xs font-bold pl-4">BOP AUTO SPACE OUT</span>
</div>
<div className="absolute inset-0 opacity-10 bg-gradient-to-r from-cyan-500 via-gray-500 to-cyan-500 rounded-lg"></div>
<div className="relative z-10 text-white mt-12 p-4">
  <div className="bg-gray-800 rounded-lg p-3 mb-3">
    <div className="flex justify-between items-center">
      <span className="text-sm">Operation</span>
      <span className="text-sm">Drilling</span>
    </div>
  </div>
  <div className="bg-gray-800 rounded-lg p-3 mb-3">
    <div className="flex justify-between items-center">
      <span className="text-sm">Influx Alarm</span>
      <span className="text-sm text-red-500">Yes</span>
    </div>
  </div>
  <div className="bg-gray-800 rounded-lg p-3 mb-3">
    <div className="flex justify-between items-center">
      <span className="text-sm">Depth</span>
      <span className="text-lg font-bold">18,500 <span className="text-xs">ft</span></span>
    </div>
  </div>
  <div className="bg-gray-800 rounded-lg p-3 mb-3">
    <div className="flex justify-between items-center">
      <span className="text-sm">Recommendation</span>
      <span className="text-sm text-red-500">Close Blind Ram</span>
    </div>
  </div>
  <div className="bg-gray-800 rounded-lg p-3 mb-3">
    <div className="flex justify-between items-center">
      <span className="text-sm">BOP Configuration</span>
      <span className="text-lg font-bold">13 5/8 <span className="text-xs">in</span></span>
    </div>
  </div>
  <div className="bg-gray-800 rounded-lg p-3 mt-4">
    <div className="flex justify-between items-center">
      <span className="text-sm">Auto Control</span>
      <span className="flex items-center">
        <span className="text-sm mr-2">ON</span>
        <div className="w-6 h-6 bg-green-500 rounded-full flex items-center justify-center">
          <span className="w-4 h-4 bg-white rounded-full"></span>
        </div>
      </span>
    </div>
  </div>
</div>
</div>

      <div className="relative col-span-1 sm:col-span-2 lg:col-span-2 lg:row-span-2 lg:col-start-6 lg:row-start-2 bg-gray-900 rounded-lg border-2 border-cyan-400 shadow-lg shadow-cyan-500/50 transition-all duration-300 ease-in-out hover:shadow-cyan-300/50">
<div className="absolute top-0 left-0 right-0 h-8 flex items-center custom-title-shape">
  <span className="text-white text-xs font-bold pl-4">STATUS INFORMATION</span>
</div>
<div className="absolute inset-0 opacity-10 bg-gradient-to-r from-cyan-500 via-gray-500 to-cyan-500 rounded-lg"></div>
<div className="relative z-10 text-white text-center mt-12 grid grid-cols-1 gap-4 p-4 
                sm:grid-cols-2 sm:grid-rows-2 
                md:grid-cols-2 md:grid-rows-2 
                lg:grid-cols-2 lg:grid-rows-2">
<div className="flex flex-col items-center">
<div className="w-60 h-36 relative flex justify-center items-center">
<GaugeChart 
    id="wob-gauge" 
    nrOfLevels={30} 
    percent={wob / 100} // Assuming the maximum WOB is 100 kg
    textColor="#FFFFFF" // White text color for better contrast
    colors={['#00ff00', '#ffcc00', '#ff0000']} // Green to yellow to red
    arcPadding={0.02} // Padding between arcs
    arcWidth={0.3} // Width of the arc
    needleColor="#FFFFFF" // White needle for better contrast
    needleBaseColor="#000000" // Black base for the needle
    animate={true} // Enable animation
    hideText={true} // Hide the default percentage text
  />
</div>
<span className="text-xs font-bold text-white">{wob}</span>
<span className="text-xs font-bold text-white">WOB</span>
</div>

  <div className="flex flex-col items-center">
    <div className="w-60 h-36 flex justify-center items-center">
    <GaugeChart 
    id="spp-gauge" 
    nrOfLevels={30} 
    percent={spp / 15} // Assuming the maximum WOB is 100 kg
    textColor="#FFFFFF" // White text color for better contrast
    colors={['#00ff00', '#ffcc00', '#ff0000']} // Green to yellow to red
    arcPadding={0.02} // Padding between arcs
    arcWidth={0.3} // Width of the arc
    needleColor="#FFFFFF" // White needle for better contrast
    needleBaseColor="#000000" // Black base for the needle
    animate={true} // Enable animation
    hideText={true} // Hide the default percentage text
  />
    </div>
    <span className="text-xs font-bold text-white">{spp}</span>
    <span className="text-xs font-bold text-white">Stand Pipe Pressure</span>
  </div>
  <div className="flex flex-col items-center">
    <div className="w-60 h-36 flex justify-center items-center">
    <GaugeChart 
    id="pp-gauge" 
    nrOfLevels={30} 
    percent={pumpPr /2800} // Assuming the maximum WOB is 100 kg
    textColor="#FFFFFF" // White text color for better contrast
    colors={['#00ff00', '#ffcc00', '#ff0000']} // Green to yellow to red
    arcPadding={0.02} // Padding between arcs
    arcWidth={0.3} // Width of the arc
    needleColor="#FFFFFF" // White needle for better contrast
    needleBaseColor="#000000" // Black base for the needle
    animate={true} // Enable animation
    hideText={true} // Hide the default percentage text
  />
    </div>
    <span className="text-xs font-bold text-white"> {pumpPr}</span>
    <span className="text-xs font-bold text-white">Pump Press</span>
  </div>
  <div className="flex flex-col items-center">
    <div className="w-60 h-36 flex justify-center items-center">
    <GaugeChart 
id="hook-gauge" 
nrOfLevels={30} 
percent={hook / 120} // Assuming the maximum hook load is 120 kN
textColor="#FFF" // White text color for better contrast
colors={['#00ff00', '#ffcc00', '#ff0000']} // Green to yellow to red
arcPadding={0.02} // Padding between arcs
arcWidth={0.3} // Width of the arc
needleColor="#FFF" // White needle for better contrast
needleBaseColor="#000" // Black base for the needle
animate={true} // Enable animation
hideText={true} // Show the percentage text

/>

    </div>
    <span className="text-xs font-bold text-white">{hook} Kn</span>
    <span className="text-xs font-bold text-white">Hook Load</span>
  </div>
</div>
</div>

<div className="relative col-span-1 sm:col-span-2 lg:col-span-3 lg:row-span-3 lg:row-start-6 bg-gray-900 rounded-lg border-2 border-cyan-400 shadow-lg shadow-cyan-500/50 transition-all duration-300 ease-in-out hover:shadow-cyan-300/50">
<div className="absolute top-0 left-0 right-0 h-8 flex items-center custom-title-shape">
  <span className="text-white text-xs font-bold pl-4">TOP DRIVE</span>
</div>
<div className="absolute inset-0 opacity-10 bg-gradient-to-r from-cyan-500 via-gray-500 to-cyan-500 rounded-lg"></div>
<div className="relative z-10 text-white text-center mt-12">
  
  <div className="flex flex-col h-full">

  <div className="bg-gray-700 text-white p-4 h-96 mb-6 rounded-lg ml-2 mr-2 overflow-hidden">
  <p className='text-xs text-left'>Cam:67196</p>
<ReactPlayer
  url={"https://vimeo.com/952829218?share=copy"}
  width="580px"
  height="100%"
  playing={true}
  loop={true}
  controls={false}
  muted={true}
/>
</div>

<div className="bg-gray-700 text-white p-4 h-96 mb-6 rounded-lg ml-2 mr-2 overflow-hidden ">
<div className='flex flex-row h-1/2 gap-2 rounded-lg'>
<div
        className={`w-2/6 mb-2 rounded-lg flex flex-col justify-between p-2 cursor-pointer transition-transform transform hover:scale-105 ${
          isTDDrillModeOn ? 'bg-gradient-to-r from-green-400 to-green-600 shadow-lg' : 'bg-gradient-to-r from-red-400 to-red-600 shadow-lg'
        }`}
        onClick={toggleTDDrillMode}
      >
        <p className='font-semibold text-xl'>TD drill Mode</p>
        <div className="flex-grow flex items-center justify-center">
          <p className='font-semibold text-3xl animate-pulse'>{isTDDrillModeOn ? 'ON' : 'OFF'}</p>
        </div>
      </div>
      <div
        className={`w-2/6 mb-2 rounded-lg flex flex-col justify-between p-2 cursor-pointer transition-transform transform hover:scale-105 ${
          isTDSpinModeOn ? 'bg-gradient-to-r from-green-400 to-green-600 shadow-lg' : 'bg-gradient-to-r from-red-400 to-red-600 shadow-lg'
        }`}
        onClick={toggleTDSpinModeOn}
      >
        <p className='font-semibold text-xl'>TD Spin Mode</p>
        <div className="flex-grow flex items-center justify-center">
          <p className='font-semibold text-3xl animate-pulse'>{isTDSpinModeOn ? 'ON' : 'OFF'}</p>
        </div>
      </div>
  <div className='bg-gray-800 w-2/6 mb-2 rounded-lg mb-2 rounded-lg flex flex-col justify-between p-2'>
     <p className='font-semibold text-xl '> TD Rotate Speed</p>
     <div className="flex-grow flex items-center justify-center">
          <p className='font-semibold text-3xl animate-pulse'>{tdRotateSpeed}   <p className='font-semibold text-sm text-teal-500'>Rpm</p></p>
        </div>

  </div>
  
</div>
<div className='flex flex-row h-1/2 gap-2'>
<div className='bg-gray-800 w-2/6 mb-2 rounded-lg mb-2 rounded-lg flex flex-col justify-between p-2'>
     <p className='font-semibold text-xl '> TD Torque ACT</p>
     <div className="flex-grow flex items-center justify-center">
          <p className='font-semibold text-3xl animate-pulse'>{tdtorqueact}   <p className='font-semibold text-sm text-teal-500'>kN.m</p></p>
        </div>

  </div>
  <div className='bg-gray-800 w-2/6 flex flex-col gap-1'>
  <div className='bg-dark-green h-1/3 flex items-center justify-center '>
      <p className='neon-text'>TD CW</p>
  </div>
  <div className='bg-selected h-1/3 flex items-center justify-center '>
      <p className='neon-text'>TD CCW</p>
  </div>


<div className='bg-red-800 h-1/3 flex items-center justify-center '>
<p className='neon-text'>TD STOP</p>

</div>
      
  </div>
  <div className='bg-gray-800 w-2/6 flex flex-col gap-1'>
       <div className='bg-blue-800 h-1/4  '>
       <p className='font-semibold text-xl mt-1'> IBOP</p>

      </div>
      <div className='bg-red-800 h-3/4 rounded-lg '>
      <div className="flex-grow flex items-center justify-center">
          <p className='font-semibold text-3xl animate-pulse mt-10'>OFF</p>
        </div>
        
        

</div>
   
  </div>
  
</div>
</div>

</div>

</div>
</div>

<div className="relative col-span-1 sm:col-span-2 lg:col-span-3 lg:row-span-4 lg:col-start-1 lg:row-start-9 bg-gradient-to-r from-gray-900 to-black rounded-lg border-2 border-cyan-400 shadow-lg shadow-cyan-500/50 transition-all duration-300 ease-in-out hover:shadow-cyan-300/50 h-fit">
  <div className="absolute top-0 left-0 right-0 h-10 flex items-center justify-between px-4 custom-title-shape bg-cyan-700 rounded-t-lg shadow-md">
    <span className="text-white text-lg font-bold">GEN SET</span>
  </div>
  <div className="absolute inset-0 opacity-10 bg-gradient-to-r from-cyan-500 via-gray-500 to-cyan-500 rounded-lg"></div>
  <div className="relative z-10 text-white text-center mt-14">
    <div className="flex flex-col h-full">
      <div className="bg-gray-800 text-white p-4 h-96 mb-6 rounded-lg ml-2 mr-2 overflow-hidden relative shadow-inner">
        <p className='text-xs text-left'>Cam:67196</p>
        <ReactPlayer
          url={"https://vimeo.com/953883572?share=copy"}
          width="100%"
          height="100%"
          playing={true}
          loop={true}
          controls={false}
          muted={true}
          className="rounded-lg"
        />
      </div>

      <div className="bg-gray-900 text-white p-4 h-96 mb-8 rounded-lg ml-2 mr-2 overflow-hidden shadow-xl transform transition-all duration-500 hover:shadow-2xl hover:scale-105">
<div className='flex flex-col gap-4'>
  <div className='flex flex-wrap justify-between'>
    <div className={`w-full md:w-1/2 mb-2 rounded-lg flex flex-col justify-between p-4 transition-transform transform hover:scale-105 shadow-md ${
      isGENSetOn ? 'bg-gradient-to-r from-green-400 to-green-600 hover:from-green-600 hover:to-green-800' : 'bg-gradient-to-r from-red-400 to-red-600 hover:from-red-600 hover:to-red-800'
    }`} onClick={toggleGenSet}>
      <p className='font-semibold text-lg'>Gen Set Run</p>
      <div className="flex-grow flex items-center justify-center">
        <p className='font-semibold text-2xl animate-pulse'>{isGENSetOn ? 'ON' : 'OFF'}</p>
      </div>
    </div>
    <div className={`w-full md:w-1/2 mb-2 rounded-lg flex flex-col justify-between p-4 transition-transform transform hover:scale-105 shadow-md ${
      isGENSeAlarmtOn ? 'bg-gradient-to-r from-red-400 to-red-600 hover:from-red-600 hover:to-red-800' : 'bg-gradient-to-r from-gray-600 to-gray-800 hover:from-gray-800 hover:to-gray-900'
    }`} onClick={toggleGenSetAlarm}>
      <p className='font-semibold text-lg'>Gen Set Alarm</p>
      <div className="flex-grow flex items-center justify-center">
        <p className='font-semibold text-2xl animate-pulse'>{isGENSeAlarmtOn ? 'ON' : 'OFF'}</p>
      </div>
    </div>
  </div>
  <div className='flex flex-wrap justify-between'>
    <div className='w-full md:w-1/2 bg-gradient-to-r from-blue-700 to-blue-900 p-4 rounded-lg transition-transform transform hover:scale-105 shadow-md'>
      <p className='font-semibold text-lg'>Gen Set Frequency</p>
      <p className='text-2xl'>150 Hz</p>
    </div>
    <div className='w-full md:w-1/2 bg-gradient-to-r from-purple-700 to-purple-900 p-4 rounded-lg transition-transform transform hover:scale-105 shadow-md'>
      <p className='font-semibold text-lg'>Gen Set Rate</p>
      <p className='text-2xl'>250 R/m</p>
    </div>
  </div>
  <div className='flex flex-wrap justify-between mt-4'>
    <div className='w-full md:w-1/2 flex flex-col'>
      <p className='font-semibold text-lg text-left'>Current</p>
      <div className='flex gap-2'>
        <div className='relative bg-gray-800 h-16 w-1/3 rounded-md shadow-md'>
          <div className="absolute bottom-0 h-3/4 w-full bg-yellow-500 transition-all duration-500"></div>
          <p className="absolute bottom-0 left-0 w-full text-center text-xs text-black font-bold">U</p>
        </div>
        <div className='relative bg-gray-800 h-16 w-1/3 rounded-md shadow-md'>
          <div className="absolute bottom-0 h-2/3 w-full bg-green-500 transition-all duration-500"></div>
          <p className="absolute bottom-0 left-0 w-full text-center text-xs text-black font-bold">V</p>
        </div>
        <div className='relative bg-gray-800 h-16 w-1/3 rounded-md shadow-md'>
          <div className="absolute bottom-0 h-1/2 w-full bg-blue-500 transition-all duration-500"></div>
          <p className="absolute bottom-0 left-0 w-full text-center text-xs text-black font-bold">W</p>
        </div>
      </div>
    </div>
    <div className='w-full md:w-1/2 flex flex-col'>
      <p className='font-semibold text-lg text-left'>Voltage</p>
      <div className='flex gap-2'>
        <div className='relative bg-gray-800 h-16 w-1/3 rounded-md shadow-md'>
          <div className="absolute bottom-0 h-3/4 w-full bg-yellow-500 transition-all duration-500"></div>
          <p className="absolute bottom-0 left-0 w-full text-center text-xs text-black font-bold">U</p>
        </div>
        <div className='relative bg-gray-800 h-16 w-1/3 rounded-md shadow-md'>
          <div className="absolute bottom-0 h-2/3 w-full bg-green-500 transition-all duration-500"></div>
          <p className="absolute bottom-0 left-0 w-full text-center text-xs text-black font-bold">V</p>
        </div>
        <div className='relative bg-gray-800 h-16 w-1/3 rounded-md shadow-md'>
          <div className="absolute bottom-0 h-1/2 w-full bg-blue-500 transition-all duration-500"></div>
          <p className="absolute bottom-0 left-0 w-full text-center text-xs text-black font-bold">W</p>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

    </div>
  </div>
</div>


      <div className="relative col-span-1 sm:col-span-2 lg:col-span-3 lg:row-span-3 lg:col-start-5 lg:row-start-6 bg-gray-900 rounded-lg border-2 border-cyan-400 shadow-lg shadow-cyan-500/50 transition-all duration-300 ease-in-out hover:shadow-cyan-300/50 ">
        <div className="absolute top-0 left-0 right-0 h-8 flex items-center custom-title-shape">
          <span className="text-white text-xs font-bold pl-4">MUD PUMP</span>
        </div>
        <div className="absolute inset-0 opacity-10 bg-gradient-to-r from-cyan-500 via-gray-500 to-cyan-500 rounded-lg"></div>
        <div className="relative z-10 text-white text-center mt-12">
        <MudLineChartComponent/>
        </div>
      </div>
      <div className="relative col-span-1 sm:col-span-2 lg:col-span-3 lg:row-span-4 lg:col-start-5 lg:row-start-9 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-lg border-2 border-cyan-400 shadow-lg shadow-cyan-500/50 transition-all duration-300 ease-in-out hover:shadow-cyan-300/50 h-fit">
<div className="absolute top-0 left-0 right-0 h-8 flex items-center custom-title-shape bg-cyan-500 rounded-t-lg">
  <span className="text-white text-xs font-bold pl-4">DRILLING FLOOR</span>
</div>
<div className="absolute inset-0 bg-gradient-to-r from-cyan-500 via-gray-500 to-cyan-500 rounded-lg opacity-10"></div>
<div className="relative z-10 text-white text-center mt-12">
  <div className="relative z-10 text-white text-center mt-12">
    <div className="flex flex-col h-full">
      <div className="bg-gray-700 text-white p-4 h-96 mb-6 rounded-lg ml-2 mr-2 overflow-hidden shadow-inner shadow-cyan-700">
        <p className="text-xs text-left">Cam:67196</p>
        <ReactPlayer
          url={"https://vimeo.com/952830108?share=copy"}
          width="580px"
          height="100%"
          playing={true}
          loop={true}
          controls={false}
          muted={true}
        />
      </div>

      <div className="flex flex-col gap-4 mx-4 mb-1">
<div className="flex items-center justify-between bg-gradient-to-r from-gray-800 to-cyan-700 p-2 rounded-lg transform transition duration-300 hover:scale-105 hover:bg-cyan-800 shadow-lg relative overflow-hidden mb-4">
  <span className="text-white font-bold text-lg z-10">Drilling Station H2S</span>
  <span className="text-red-500 text-2xl z-10">!</span>
  <div className="absolute inset-0 bg-gradient-to-r from-transparent to-cyan-400 opacity-10 z-0"></div>
</div>
<div className="flex items-center justify-between bg-gradient-to-r from-gray-800 to-cyan-700 p-4 rounded-lg transform transition duration-300 hover:scale-105 hover:bg-cyan-800 shadow-lg relative overflow-hidden mb-3">
  <span className="text-white font-bold text-lg z-10">Drilling Station Gas</span>
  <span className="text-red-500 text-2xl z-10">!</span>
  <div className="absolute inset-0 bg-gradient-to-r from-transparent to-cyan-400 opacity-10 z-0"></div>
</div>
<div className="flex items-center justify-between bg-gradient-to-r from-gray-800 to-cyan-700 p-4 rounded-lg transform transition duration-300 hover:scale-105 hover:bg-cyan-800 shadow-lg relative overflow-hidden mb-3">
  <span className="text-white font-bold text-lg z-10">Influx Alarm</span>
  <span className="bg-red-600 text-white px-3 py-1 rounded-full text-lg shadow z-10">OFF</span>
  <div className="absolute inset-0 bg-gradient-to-r from-transparent to-red-500 opacity-10 z-0"></div>
</div>
<div className="flex items-center justify-between bg-gradient-to-r from-gray-800 to-cyan-700 p-4 rounded-lg transform transition duration-300 hover:scale-105 hover:bg-cyan-800 shadow-lg relative overflow-hidden mb-3">
  <span className="text-white font-bold text-lg z-10">Recommendation</span>
  <span className="text-red-500 font-bold text-lg z-10">Close Blind Ram</span>
  <div className="absolute inset-0 bg-gradient-to-r from-transparent to-cyan-400 opacity-10 z-0"></div>
</div>
<div className="flex items-center justify-between bg-gradient-to-r from-gray-800 to-cyan-700 p-4 rounded-lg transform transition duration-300 hover:scale-105 hover:bg-cyan-800 shadow-lg relative overflow-hidden mb-3">
  <span className="text-white font-bold text-lg z-10">BOP Configuration</span>
  <span className="text-white text-lg z-10">13 5/8 in</span>
  <div className="absolute inset-0 bg-gradient-to-r from-transparent to-cyan-400 opacity-10 z-0"></div>
</div>
</div>

    </div>
  </div>
</div>
</div>

      
    </div>
  </div>
);
};

export default DrillingDashboard;
