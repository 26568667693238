import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import 'echarts/theme/macarons'; // Importing a theme

const TopItemsChart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const myChart = echarts.init(chartDom, 'macarons'); // Applying the Macarons theme

    const fakeData = [
      { store: 'Item A', sales: 120 },
      { store: 'Item B', sales: 200 },
      { store: 'Item C', sales: 150 },
      { store: 'Item D', sales: 80 },
      { store: 'Item E', sales: 70 }
    ];

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow' // Show shadow when hovering over bars
        }
      },
      grid: {
        top: 50,
        left: 80, // Adjusted left padding for labels
        right: 50,
        bottom: 50
      },
      xAxis: {
        type: 'value',
        show: false // Hide x-axis
      },
      yAxis: {
        type: 'category',
        data: fakeData.map(item => item.store),
        axisLabel: {
          interval: 0,
          color: '#ffffff' // Move textStyle properties here
        }
      },
      series: [
        {
          data: fakeData.map(item => item.sales),
          type: 'bar',
          barWidth: 20, // Set bar width
          label: {
            show: true,
            position: 'right', // Show labels on the right side of bars
            color: '#ffffff' // Label color
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#f46f4e' },
                { offset: 0.7, color: '#f49e4e' },
                { offset: 1, color: '#f4e24e' }
              ]
            )
          },
          emphasis: {
            itemStyle: {
              color: '#70a8ea' // Highlighted bar color
            }
          }
        }
      ],
      legend: {
        show: true,
        data: ['Sales'], // Add this line
        textStyle: {
          color: '#ffffff' // Legend text color
        }
      }
    };

    myChart.setOption(option);

    // Resize chart when window size changes
    window.addEventListener('resize', () => {
      myChart.resize();
    });

    // Clean up
    return () => {
      myChart.dispose();
    };
  }, []);

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

export default TopItemsChart;
