import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

const MonthlySalesChart = ({ containerWidth, containerHeight }) => {
  const chartRef = useRef(null);
  const myChart = useRef(null);
  const [year, setYear] = useState(new Date().getFullYear());

  const data = year === new Date().getFullYear()
    ? [120, 200, 150, 80, 70, 110, 130, 150, 200, 180, 190, 210] // current year data
    : [100, 150, 120, 90, 80, 100, 110, 130, 140, 150, 160, 170]; // last year data

  useEffect(() => {
    myChart.current = echarts.init(chartRef.current, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });

    const option = {
      title: {
        text: `${year} Sales`,
        left: 'center',
        textStyle: {
          color: '#FFD700', // bright yellow
          fontSize: 16,
          fontWeight: 'bold'
        }
      },
      legend: {
        itemWidth: 10, // set legend item width
        itemHeight: 10, // set legend item height
        textStyle: {
          color: '#FFD700' // bright yellow
        },
        data: ['Sales']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        axisLine: {
          lineStyle: {
            color: '#FFA07A' // light coral
          }
        },
        axisLabel: {
          color: '#FFA07A' // light coral
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#FFA07A' // light coral
          }
        },
        axisLabel: {
          color: '#FFA07A' // light coral
        },
        splitLine: {
          lineStyle: {
            color: '#FFE4B5' // light tropical color for split lines
          }
        }
      },
      series: [
        {
          data,
          type: 'bar',
          barWidth: '60%',
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#FF4500' }, // orange-red
                { offset: 1, color: '#FFD700' }  // bright yellow
              ]
            ),
            borderRadius: [5, 5, 0, 0] // rounded top corners
          }
        }
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        textStyle: {
          color: '#FFD700' // bright yellow
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderColor: '#FFD700',
        borderWidth: 1
      }
    };

    myChart.current.setOption(option);

    const resizeChart = () => {
      myChart.current.resize({
        width: chartRef.current.clientWidth,
        height: chartRef.current.clientHeight
      });
    };

    window.addEventListener('resize', resizeChart);

    // Initial resize to fit container
    resizeChart();

    return () => {
      window.removeEventListener('resize', resizeChart);
    };
  }, [year]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ textAlign: 'center', padding: '10px 0' }}>
        <button
          style={{ margin: '5px', padding: '5px 10px', backgroundColor: '#FF4500', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
          onClick={() => setYear(year - 1)}>
          Last Year
        </button>
        <button
          style={{ margin: '5px', padding: '5px 10px', backgroundColor: '#FF4500', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
          onClick={() => setYear(new Date().getFullYear())}>
          This Year
        </button>
      </div>
      <div ref={chartRef} style={{ width: '100%', height: '80%' }}></div>
    </div>
  );
};

export default MonthlySalesChart;
