import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  GridComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

const HotelRoomManagementAreachart = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [selectedDataset, setSelectedDataset] = useState('dataset1');
  let fontSize = 14;

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  if (windowSize.width <= 768) {
    fontSize = 12;
  }

  if (windowSize.width <= 576) {
    fontSize = 10;
  }

  if (windowSize.width <= 425) {
    fontSize = 8;
  }

  useEffect(() => {
    const resizeChart = () => {
      if (chartInstance.current) {
        chartInstance.current.resize();
      }
    };

    window.addEventListener('resize', resizeChart);

    return () => {
      window.removeEventListener('resize', resizeChart);
    };
  }, []);

  useEffect(() => {
    if (!chartInstance.current) {
      chartInstance.current = echarts.init(chartRef.current);
    }

    const seriesData = [
      {
        name: 'Occupied Rooms',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(128, 255, 165)',
            },
            {
              offset: 1,
              color: 'rgb(1, 191, 236)',
            },
          ]),
        },
        emphasis: {
          focus: 'series',
        },
        data: [150, 170, 120, 180, 130, 200, 160], // replace with actual occupied rooms data
      },
      {
        name: 'Cleaned Rooms',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(255, 191, 165)',
            },
            {
              offset: 1,
              color: 'rgb(236, 1, 118)',
            },
          ]),
        },
        emphasis: {
          focus: 'series',
        },
        data: [120, 140, 90, 150, 100, 180, 130], // replace with actual cleaned rooms data
      },
      {
        name: 'Maintenance Requests',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(138, 127, 255)',
            },
            {
              offset: 1,
              color: 'rgb(61, 53, 236)',
            },
          ]),
        },
        emphasis: {
          focus: 'series',
        },
        data: [20, 30, 15, 25, 10, 40, 20], // replace with actual maintenance requests data
      },
    ];

    const option = {
      title: {
        text: 'Hotel Room Occupancy and Maintenance',
        textStyle: {
          color: '#fff',
          fontSize: fontSize,
        },
        left: '30',
        top: '20',
      },
      color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#fff',
          },
        },
      },
      legend: {
        data: ['Occupied Rooms', 'Cleaned Rooms', 'Maintenance Requests'],
        top: '40',
        right: '5%',
        color: '#FFF',
        textStyle: {
          color: '#C0C0C0',
          fontSize: fontSize - 2,
        },
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '15%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLabel: {
            textStyle: {
              color: '#999', // Make the xAxis labels white
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          inverse: false,
          axisLabel: {
            textStyle: {
              fontFamily: 'Arial, sans-serif',
              fontSize: 14,
              color: '#999',
            },
          },
          axisPointer: {
            type: 'shadow',
          },
          scale: true,
        },
      ],
      dataZoom: [
        {
          type: 'slider',
          show: true,
          xAxisIndex: [0],
          start: 0,
          end: 100,
        },
      ],
      series: seriesData,
    };

    chartInstance.current.setOption(option);

    return () => {
      // Cleanup
    };
  }, []);

  const handleFilterChange = (e) => {
    setSelectedDataset(e.target.value);
    // Here you can update the chart data based on the selected dataset
    // For simplicity, I'm leaving this part as a placeholder
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div ref={chartRef} style={{ width: '100%', height: '90%' }} />
     
    </div>
  );
};

export default HotelRoomManagementAreachart;
